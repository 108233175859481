var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("multiselect", {
    attrs: {
      label: "name",
      "track-by": "id",
      placeholder: "Type to search",
      "custom-label": _vm.nameWithReference,
      options: _vm.options,
      multiple: false,
      searchable: true,
      loading: _vm.isLoading
    },
    on: { "search-change": _vm.searchCustomer },
    model: {
      value: _vm.customer,
      callback: function($$v) {
        _vm.customer = $$v
      },
      expression: "customer"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }