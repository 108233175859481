var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attribute-quoting-prices" },
    [
      _vm._l(_vm.attributeList, function(attribute) {
        return _c(
          "div",
          { key: attribute.id, staticClass: "bg-white p-4 mb-3" },
          [
            _c("h1", [_vm._v(_vm._s(attribute.name))]),
            _vm._v(" "),
            _c("attribute-option-prices", {
              attrs: { attribute: attribute.id }
            })
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.save.apply(null, arguments)
            }
          }
        },
        [_vm._v("Save")]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }