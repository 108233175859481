var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "a",
        {
          staticClass: "btn btn-primary",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.show = true
            }
          }
        },
        [_vm._v("Add Size Breakpoint")]
      ),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { show: _vm.show }, on: { close: _vm.closeModal } },
        [_c("size-breakpoint-form", { on: { saved: _vm.closeModal } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }