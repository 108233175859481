var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.name === "approval-tick"
    ? _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 40 40"
          }
        },
        [
          _c("circle", {
            attrs: { cx: "20", cy: "20", r: "20", fill: "#85be00" }
          }),
          _c("path", {
            attrs: {
              d:
                "M17.758 24.754c.11.164.273.22.492.22.19 0 .355-.055.492-.22l8.066-8.04c.11-.11.19-.273.19-.492 0-.192-.082-.355-.19-.492l-1.012-.984c-.137-.137-.3-.22-.492-.22s-.355.082-.492.22L18.25 21.31l-3.062-3.062c-.164-.137-.328-.22-.52-.22s-.355.082-.465.22l-1.012.984c-.137.137-.19.3-.19.492 0 .22.055.383.19.492l4.566 4.54z",
              fill: "#fff"
            }
          })
        ]
      )
    : _vm.name === "not-approved"
    ? _c(
        "svg",
        {
          attrs: {
            xmlns: "http://www.w3.org/2000/svg",
            fill: "none",
            viewBox: "0 0 40 40"
          }
        },
        [
          _c("circle", {
            attrs: { cx: "20", cy: "20", r: "20", fill: "#e4ead6" }
          })
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }