<template>
    <div class="product-edit">
        <slot></slot>
    </div>
</template>

<script>
    import ProductModule from '../../store/modules/product';

    export default {
        name: 'ProductEdit',

        props: {
            product: {
                type: Object,
                required: true
            }
        },

        created() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.product)) {
                store.registerModule('product', ProductModule);
            }

            this.$store.commit('product/setProductData', this.product);
        }
    }
</script>
