<template>
    <div class="checkbox-field" @click="toggle()">
        <input v-if="isSelected" type="hidden" :name="name" :value="value">
        <div class="checker">
            <i :class="['fas', iconStyle]"></i>
        </div>
        <p class="label">
            <slot></slot>
        </p>
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            default: false,
        },
        isSelected: {
            type: Boolean,
            default: false,
        },
        icon: {
            type: String,
            default: 'check',
        }
    },

    computed: {
        iconStyle() {
            if  (this.isSelected) {
                return 'fa-' + this.icon
            }

            return ''
        },
    },

    methods: {
        /**
         * Toggle checkbox.
         */
        toggle() {
            this.$emit('input', !this.isSelected);
        },
    },
}
</script>
