<template>
    <div class="attribute-quoting-prices">
        <div
            v-for="attribute in attributeList"
            :key="attribute.id"
            class="bg-white p-4 mb-3"
        >
            <h1>{{ attribute.name }}</h1>
            <attribute-option-prices
                :attribute="attribute.id"
            ></attribute-option-prices>
        </div>

        <button type="button" class="btn btn-primary" @click.prevent="save">Save</button>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'AttributeQuotingPrices',

        props: ['product'],

        created() {
            this.$store.dispatch('products/attributeQuotePrices/loadPrices', this.product);
        },

        computed: {
            ...mapGetters('products/attributeQuotePrices',[
                'attributeList'
            ])
        },

        methods: {
            save() {
                this.$store.dispatch('products/attributeQuotePrices/save').then(response => {
                    window.location.reload();
                });
            }
        }
    }
</script>
