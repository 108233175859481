var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", [_vm._v("Created On: " + _vm._s(_vm.quote.created_at))]),
    _vm._v(" "),
    _c("div", [_vm._v("Margin: " + _vm._s(_vm.quote.margin))]),
    _vm._v(" "),
    _c("div", [
      _vm._v("Total: £" + _vm._s(_vm._f("toPounds")(_vm.quote.total)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }