<template>
    <div>
        <table class="table">
            <thead>
            <tr>
                <th class="border-0">Name</th>
                <th class="border-0">Description</th>
                <th class="border-0">Price</th>
                <th class="border-0">Actions</th>
            </tr>
            </thead>
            <tbody>
                <template v-if="sampleTypeList.length">
                    <product-sample-type-item
                        v-for="sampleType in sampleTypeList"
                        :key="sampleType.id"
                        :sample-type="sampleType">
                    </product-sample-type-item>
                </template>
                <tr v-else>
                    <td>No sample types attached.</td>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <div class="col-12 text-right">
            <add-sample-type-option
                addButtonClass="btn btn-primary"
                addButtonText="Add Approval Option">
            </add-sample-type-option>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import SampleTypesModule from '../../../store/modules/product/sample-types';

    export default {
        name: "ProductApprovals",

        props: {
            product: {
                type: String
            }
        },

        data() {
            return {
                show: false,
            };
        },

        computed: {
            ...mapGetters('product/sampleTypes',[
                'sampleTypeList'
            ])
        },

        beforeCreate() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.product.sampleTypes)) {
                store.registerModule(['product', 'sampleTypes'], SampleTypesModule);
            }

            this.$store.dispatch('product/sampleTypes/loadOptions');
        }
    }
</script>
