<template>
    <div class="product-pricing">
        <component
            :is="pricingComponent"
            :product="product"
        ></component>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default {
        name: 'ProductPricing',

        props: ['product'],

        computed: {
            pricingComponent() {
                return `${this.product}-pricing`;
            }
        }
    }
</script>
