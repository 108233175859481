import {filter, find, findIndex, intersection, groupBy} from "lodash";

class CartManager {
    constructor(cartOptions) {
        this.options = cartOptions;
    }

    single(option) {
        this.singleAttribute(option);
        this.filterOptions();
    }

    multiple(option) {
        let optionIndex = this.optionIndexById(option.id);
        if (optionIndex > -1) {
            this.options.splice(optionIndex, 1);
        } else {
            this.options.push(option);
        }

        this.filterOptions();
    }

    singleAttribute(option) {
        let existingOption = this.existingAttributeOption(option.attribute_id);

        if (!existingOption) {
            return this.options.push(option);
        }

        let existingOptionIndex = this.optionIndexById(existingOption.id);
        if (existingOption.id !== option.id) {
            return this.options.splice(existingOptionIndex, 1, option);
        }

        let optionIndex = this.optionIndexById(option.id);
        return this.options.splice(optionIndex, 1);
    }

    existingAttributeOption(attributeId) {
        return find(this.options, option => option.attribute_id === attributeId);
    }

    removeAttribute(attributeId) {
        let existingOption = this.existingAttributeOption(attributeId);
        let existingOptionIndex = this.optionIndexById(existingOption.id);
        return this.options.splice(existingOptionIndex, 1);
    }

    filterOptions() {
        let filters = ['removeIfParentMissing', 'removeRestricted'];
        filters.forEach(filter => {
            this[filter]();
        })
    }

    removeIfParentMissing() {
        this.options = this.options.filter((option) => {
            if (!option.parent_id) return true;

            return this.optionIndexById(option.parent_id) > -1;
        });
    }

    removeRestricted() {
        let optionIds = this.options.map(option => option.id);

        this.options = this.options.filter(option => {
            if (typeof option.restricted_by !== 'undefined') {
                return intersection(optionIds, option.restricted_by).length <= 0
            }
            return true;
        });
    }

    exportAttributes() {
        let attributes = groupBy(this.options, (option) => option.attribute_id);

        let obj = {};
        Object.entries(attributes).forEach(([attributeId, options]) => {
            let optionIds = options.map(option => option.id);
            obj[attributeId] = optionIds.length > 1 ? optionIds : optionIds[0];
        });

        return obj;
    }

    optionIndexById(optionId) {
        return findIndex(this.options, (cartOption) => cartOption.id === optionId);
    }
}

export default CartManager;
