<template>
    <portal to="modals" v-if="show">
        <div class="nd-modal-backdrop d-flex justify-content-center align-items-center" v-show="show" @click="dismiss">
            <div class="nd-modal bg-white p-4 rounded shadow-sm" :class="modalClasses" @click.stop>
                <slot></slot>
            </div>
        </div>
    </portal>
</template>

<script>
    export default {
        name: 'Modal',

        props: {
            show: {
                type: Boolean
            },
            width: {
                type: String,
                default: 'sm'
            }
        },

        computed: {
            modalClasses() {
                return [`nd-modal--${this.width}`];
            }
        },

        methods: {
            dismiss() {
                this.$emit('close');
            }
        },

        created() {
            const escapeHandler = e => {
                if (e.key == 'Escape' && this.show) {
                    this.dismiss();
                }
            }
            document.addEventListener('keydown', escapeHandler);
            this.$once('hook:destroyed', () => {
                document.removeEventListener('keydown', escapeHandler);
            });
        },

        watch: {
            show: {
                immediate: true,
                handler: show => {
                    if (show) {
                        document.body.style.setProperty('overflow', 'hidden');
                    } else {
                        document.body.style.removeProperty('overflow');
                    }
                }
            }
        }
    }
</script>
