var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.onSubmit)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "reject-artwork" },
                  [
                    _c("h3", { staticClass: "mb-3" }, [
                      _vm._v("Reject Artwork")
                    ]),
                    _vm._v(" "),
                    _vm.error
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(_vm._s(_vm.error))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ValidationProvider", {
                      attrs: { name: "reason", rule: "required" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "label",
                                    { staticClass: "control-label" },
                                    [_vm._v("Reason")]
                                  ),
                                  _vm._v(" "),
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.reason,
                                        expression: "reason"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    class: { "is-invalid": errors.length > 0 },
                                    attrs: { cols: "30", rows: "4" },
                                    domProps: { value: _vm.reason },
                                    on: {
                                      input: function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.reason = $event.target.value
                                      }
                                    }
                                  }),
                                  _vm._v(" "),
                                  errors.length
                                    ? _c(
                                        "div",
                                        { staticClass: "invalid-feedback" },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    : _vm._e()
                                ])
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    }),
                    _vm._v(" "),
                    _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          attrs: { type: "submit", disabled: _vm.submitting }
                        },
                        [_vm._v("Submit")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-default",
                          attrs: { type: "button", disabled: _vm.submitting },
                          on: { click: _vm.cancel }
                        },
                        [_vm._v("Cancel")]
                      )
                    ])
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }