var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th"),
            _vm._v(" "),
            _vm._l(_vm.sizes, function(size) {
              return _c("th", {
                key: size.id,
                domProps: { textContent: _vm._s(size.label) }
              })
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c(
          "tr",
          [
            _c("td", [_vm._v("Fixed Price")]),
            _vm._v(" "),
            _vm._l(_vm.sizes, function(size) {
              return _c(
                "td",
                { key: size.id },
                [
                  _c("option-fixed-price", {
                    attrs: { "price-id": size.option_price }
                  })
                ],
                1
              )
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "tr",
          [
            _c("td", [_vm._v("Quote Price")]),
            _vm._v(" "),
            _vm._l(_vm.sizes, function(size) {
              return _c(
                "td",
                { key: size.id },
                [
                  _c("option-quote-price", {
                    attrs: { "price-id": size.quote_price }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      ])
    ]),
    _vm._v(" "),
    _vm.hasChanges
      ? _c("div", { staticClass: "d-flex justify-content-end" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-link mr-3",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.cancelChanges.apply(null, arguments)
                }
              }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-primary",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.saveChanges.apply(null, arguments)
                }
              }
            },
            [_vm._v("Save")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }