<template>
    <span @click="updateSortBy" class="table-sort-header">
        <slot></slot>
        <svg width="7" height="9" viewBox="0 0 7 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M6.9282 3.75L3.4641 0L0 3.75H6.9282ZM6.9282 5.25L3.4641 9L0 5.25H6.9282Z" fill="#8A8C8F"/>
        </svg>
    </span>
</template>

<script>
    import queryString from 'query-string'

    export default {
        props: {
            attribute: {
                type: String,
                required: true,
            }
        },

        data() {
            return {
                sortDirection: null
            }
        },

        computed: {
            sortingBy() {
                return location.sortBy
            },
        },

        methods: {
            updateSortBy() {
                let url = location.href.split('?')[0]

                if (this.sortDirection === 'desc') {
                    this.sortDirection = 'asc'
                    location.href = url + '?sortBy=' + this.attribute + '.' + 'asc'
                    return
                }

                if (this.sortDirection === null) {
                    this.sortDirection = 'desc'
                    location.href = url + '?sortBy=' + this.attribute + '.' + 'desc'
                    return
                }

                location.href = url
            },
        },

        mounted() {
            let sortByAttribute = queryString.parse(location.search).sortBy ? queryString.parse(location.search).sortBy : ''

            if (sortByAttribute.startsWith(this.attribute + '.')) {
                this.sortDirection = sortByAttribute.split('.')[1]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .table-sort-header {
        cursor: pointer;

        img {
            vertical-align: baseline;
            margin-left: 4px;
        }
    }
</style>
