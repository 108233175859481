import customer from './customer';
import {normalize} from "normalizr";
import productSchema from "../../schema/products";
import OrderModuleLoader from "../../OrderModuleLoader";

const state = {
    products: {},
    selectedProduct: '',
    vatRate: 20,
}

const getters = {
    selectedProduct: (state) => {
        if (!state.selectedProduct) return;
        return state.products[state.selectedProduct];
    },
    productName: (state, getters) => {
        if (!getters.selectedProduct) return;
        return getters.selectedProduct.name;
    },
    productSlug: (state, getters) => {
        if (!getters.selectedProduct) return;
        return getters.selectedProduct.slug;
    },
    productCategory: (state, getters) => {
        if (!getters.selectedProduct) return;
        return getters.selectedProduct.product_category;
    },
    vatRate: state => {
        return state.vatRate;
    }
}

const mutations = {
    setProducts(state, products) {
        state.products = Object.assign({}, products);
    },
    setSelectedProduct(state, product) {
        state.selectedProduct = product;
    },
    setVatRate(state, vatRate) {
        state.vatRate = vatRate;
    },
}

const actions = {
    loadProducts({ commit }, productData) {
        let { entities: { products } } = normalize(productData, [productSchema]);

        commit('setProducts', products);
    },
    selectProduct({ commit, dispatch, getters }, product) {
        commit('setSelectedProduct', product);

        // register a new module only if doesn't exist
        if ((this.state && this.state.ordering.product && this.state.ordering.order)) {
            this.unregisterModule(['ordering', 'product']);
            this.unregisterModule(['ordering', 'order']);
        }

        this.registerModule(['ordering', 'product'], OrderModuleLoader.load(getters.productCategory, 'product'));
        this.registerModule(['ordering', 'order'], OrderModuleLoader.load(getters.productCategory, 'order'));

        return Promise.all([
            dispatch('ordering/order/resetData', null, { root: true }),
            dispatch('ordering/product/loadData', getters.productSlug, { root: true })
        ]);
    },
    setVatRate({ commit }, vatRate) {
        commit('setVatRate', vatRate);
    },
}

export default {
    namespaced: true,
    modules: {
        customer
    },
    state,
    getters,
    mutations,
    actions
}
