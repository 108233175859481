import {schema} from "normalizr";

const productQuantitySchema = new schema.Entity('productQuantities');
const sizeBreakpointSchema = new schema.Entity('sizeBreakpoints');

const priceSchema = new schema.Entity('prices',{
    product_quantity: productQuantitySchema,
    size_breakpoint: sizeBreakpointSchema
});

export default priceSchema;
