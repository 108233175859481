import Vue from 'vue';
import {normalize} from "normalizr";
import sampleTypeSchema from "../../schema/sample-types";

const state = {
    items: {}
};

const getters = {
    sampleTypeList: (state) => {
        return Object.values(state.items);
    }
};

const mutations = {
    setItems(state, items) {
        state.items = Object.assign({}, items);
    },
    saveItem(state, item) {
        Vue.set(state.items, item.id, item);
    },
    removeItem(state, approvalId) {
        Vue.delete(state.items, approvalId);
    }
};

const actions = {
    loadOptions({ commit, rootState }) {
        let productSlug = rootState.product.slug;

        return axios.get(route('api.products.sample-types.index', productSlug))
            .then(({ data }) => {
                let { entities: { sampleTypes } } = normalize(data.data, [sampleTypeSchema]);

                commit('setItems', sampleTypes);
            })
    },
    createSampleType({ commit, rootState }, data) {
        let slug = rootState.product.slug;

        // Adjust price
        data.price = data.price * 100;

        return axios.post(route('api.products.sample-types.store', [slug]), data)
            .then(({ data }) => {
                commit('saveItem', data.data);
            });
    },
    updateSampleType({ commit, rootState }, { id, ...data }) {
        let slug = rootState.product.slug;

        // Adjust price
        data.price = data.price * 100;

        return axios.put(route('api.products.sample-types.update', [slug, id]), data)
            .then(({ data }) => {
                commit('saveItem', data.data);
            });
    },
    deleteSampleType({ commit, rootState }, approvalId) {
        let slug = rootState.product.slug;

        return axios.delete(route('api.products.sample-types.destroy', [slug, approvalId]))
            .then(() => {
                commit('removeItem', approvalId);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
