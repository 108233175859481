var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("input", {
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.input }
      }),
      _vm._v(" "),
      _c("vue-ctk-date-time-picker", {
        attrs: {
          format: "YYYY-MM-DD HH:mm:ss",
          formatted: "DD/MM/YYYY HH:mm",
          "min-date": _vm.minDate,
          "max-date": _vm.maxDate,
          disabled: _vm.disabled
        },
        on: { input: _vm.dateSelected },
        model: {
          value: _vm.input,
          callback: function($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }