const state = {
    orderId: 0,
    reference: '',
    productSlug: '',
    quantity: 0,
    version: 0,
    options: [],
    meta: {}
};

const getters = {
    getOrderId: (state) => {
        return state.orderId;
    },
    getOrderReference: (state) => {
        return state.reference;
    },
    getVersion: (state) => {
        return state.version;
    },
    attributeOptionIdList: (state) => {
        return Object.values(state.options).map(item => item.attribute_option_id);
    }
};

const mutations = {
    setProductSlug(state, slug) {
        state.productSlug = slug;
    },
    setOrder(state, { id, reference, quantity, options, version, meta, product }) {
        state.orderId = id;
        state.reference = reference;
        state.quantity = quantity;
        state.version = version;
        state.options = options;
        state.meta = meta;
        state.productSlug = product.slug;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations
}
