var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-striped" }, [
    _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Supplier")]),
        _vm._v(" "),
        _c("th", [_vm._v("Quote")]),
        _vm._v(" "),
        _vm.showMargin ? _c("th", [_vm._v("Margin")]) : _vm._e(),
        _vm._v(" "),
        _c("th", [_vm._v("Total")]),
        _vm._v(" "),
        _c("th", [_vm._v("Status")]),
        _vm._v(" "),
        _c("th", [_vm._v("Submitted By")]),
        _vm._v(" "),
        _c("th")
      ])
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.quotes, function(quote) {
        return _c("supplier-quote-item", {
          key: quote.id,
          attrs: { "quote-id": quote.id, "show-margin": _vm.showMargin }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }