<template>
    <table class="table table-striped">
        <thead>
        <tr>
            <th></th>
            <th>Supplier</th>
            <th>Quote</th>
            <th v-if="showMargin">Margin</th>
            <th>Total</th>
            <th>Status</th>
            <th>Submitted By</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
            <supplier-quote-item
                v-for="quote in quotes"
                :key="quote.id"
                :quote-id="quote.id"
                :show-margin="showMargin"
            ></supplier-quote-item>
        </tbody>
    </table>
</template>

<script>
    export default {
        props: {
            showMargin: {
                type: Boolean,
                default: false
            },
            margin: {
                type: Number
            }
        },

        computed: {
            quotes() {
                return this.$store.getters['orders/quoting/supplierQuotes/getQuotes'];
            }
        }
    }
</script>
