<template>
    <div>
        <div class="alert alert-danger" v-if="!quote.selectable">
            We are unable to generate an internal quote, therefore you must select a supplier quote to proceed.
        </div>

        <table class="table table-striped">
            <thead>
                <tr>
                    <th></th>
                    <th>Detail</th>
                    <th>Subtotal</th>
                    <th>Margin</th>
                    <th>Total</th>
                </tr>
            </thead>
            <tbody>
            <tr v-if="hasQuote">
                <td>
                    <input
                        name="quote"
                        v-model="selectedQuote"
                        type="radio"
                        :value="quote.id"
                        @change="selectQuote"
                        :disabled="!quote.selectable"
                    />
                </td>
                <td>
                    <div class="d-flex">
                        <div class="mr-4">
                            <span class="font-weight-bold">Price Per Badge: £{{ quote.unit_cost | toPounds }}</span>
                        </div>
                        <div class="mr-4">
                            <span class="font-weight-bold">Quantity: {{ quantity }}</span>
                        </div>
                        <div>
                            <span class="font-weight-bold">Setup Cost: £{{ quote.setup_cost | toPounds }}</span>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div class="mr-2">
                            <span class="font-weight-bold">Stitch Count:</span> {{ quote.stitch_count }}
                        </div>
                        <div class="mr-2">
                            <span class="font-weight-bold">Trim Count:</span> {{ quote.trim_count }}
                        </div>
                        <div class="mr-2">
                            <span class="font-weight-bold">No. Colours:</span> {{ quote.num_colours }}
                        </div>
                    </div>
                </td>
                <td>£{{ quote.total | toPounds }}</td>
                <td>
                    <div class="input-group input-group-sm">
                        <input
                            v-model.number="customerMargin"
                            type="number"
                            class="form-control"
                        />
                    </div>
                </td>
                <td>£{{ subTotal | toPounds }}</td>
            </tr>
            <tr v-else>
                <td></td>
                <td>No current quote</td>
                <td></td>
                <td></td>
                <td>
                    <a href="#" @click.prevent="showForm = true">Create</a>
                </td>
            </tr>
            </tbody>
        </table>
        <modal :show="showForm" width="lg">
            <embroidery-badge-quote-form
                @cancel="showForm = false"
                @saved="showForm = false"
            ></embroidery-badge-quote-form>
        </modal>
    </div>
</template>

<script>
    import Vue from 'vue';
    import EmbroideryBadgeQuoteModule from '../../../../store/modules/orders/quoting/embroidery-badge';
    import currency from 'currency.js';

    export default {
        data() {
            return {
                showForm: false,
                customerMargin: ''
            }
        },

        computed: {
            quote() {
                return this.$store.state.orders.quoting.embroideryBadge.quote;
            },
            hasQuote() {
                return !! this.quote.id;
            },
            margin() {
                return this.$store.state.orders.quoting.margin;
            },
            selectedQuote: {
                get() {
                    return this.$store.state.orders.quoting.selectedQuote;
                },
                set(value) {
                    this.$store.commit('orders/quoting/setSelectedQuote', value);
                }
            },
            quantity() {
                return this.$store.state.orders.quantity;
            },
            subTotal() {
                return currency(this.quote.total).multiply(1 + (this.customerMargin / 100)).value;
            }
        },

        created() {
            const store = this.$store;

            if (!(store && store.state && store.state.orders.quoting.embroideryBadge)) {
                store.registerModule(['orders', 'quoting', 'embroideryBadge'], EmbroideryBadgeQuoteModule);
            }

            this.$store.dispatch('orders/quoting/embroideryBadge/loadData');
        },

        mounted() {
            this.customerMargin = this.margin;
        },

        methods: {
            selectQuote() {
                this.$store.commit('orders/quoting/setQuoteType', 'digitized-detail');
                this.setTotal();
            },
            setTotal() {
                this.$store.commit('orders/quoting/setUnitCost', this.quote.unit_cost);
                this.$store.commit('orders/quoting/setSetupCost', this.quote.setup_cost);
                this.$store.commit('orders/quoting/setMargin', this.customerMargin);
                this.$store.dispatch('orders/quoting/setSelectedQuoteTotal', this.subTotal);
            }
        },

        watch: {
            customerMargin(value) {
                if (this.selectedQuote === this.quote.id) {
                    this.setTotal();
                }
            }
        }
    }
</script>
