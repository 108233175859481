var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "stitch-price" }, [
    !_vm.editing
      ? _c(
          "div",
          {
            staticClass: "stitch-price-display",
            on: {
              click: function($event) {
                _vm.editing = true
              }
            }
          },
          [_vm._v(_vm._s(_vm.amount))]
        )
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newPrice,
              expression: "newPrice"
            }
          ],
          attrs: { type: "text", size: "5" },
          domProps: { value: _vm.newPrice },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.newPrice = $event.target.value
            }
          }
        })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }