import { extend } from 'vee-validate';
import { required, email, image } from 'vee-validate/dist/rules';
import SizeCalculator from "./support/SizeCalculator";

// No message specified.
extend('email', email);

// Override the default message.
extend('required', {
    ...required,
    message: 'This {_field_} is required'
});

extend('image',{
    ...image
});

// Width
extend('width', {
    params: ['height', 'unit', 'minSize', 'maxSize'],
    validate(value, { height, unit, minSize, maxSize }) {
        if (!height) return true;
        let size = SizeCalculator.calculate(value, height, unit);
        return size >= minSize && size <= maxSize;
    },
    message: 'Invalid Size'
});

// Height
extend('height', {
    params: ['width', 'unit', 'minSize', 'maxSize'],
    validate(value, { width, unit, minSize, maxSize }) {
        if (!width) return true;
        let size = SizeCalculator.calculate(width, value, unit);
        return size >= minSize && size <= maxSize;
    },
    message: 'Invalid Size'
});

// Generic rule that invalidates empty objects
extend('not_empty', {
    params: [],
    validate(value) {
        return Object.keys(value).length !== 0;
    },
    message: 'The {_field_} is required'
})
