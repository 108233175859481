<template>
    <span>
        <a href="#" class="btn btn-primary" @click.prevent="showModal">Add Delivery Option</a>
        <modal
            :show="show"
            @close="show = false"
        >
            <delivery-form
                @saved="show = false"
            ></delivery-form>
        </modal>
    </span>
</template>

<script>
    export default {
        name: 'AddDelivery',

        data() {
            return {
                show: false
            }
        },

        methods: {
            showModal() {
                this.show = true;
            }
        }
    }
</script>
