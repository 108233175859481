<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <ValidationProvider name="street address" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">Street Address</label>
                        <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :value="address.address_1"
                            @input="update('address_1', $event.target.value)"
                        >
                        <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider name="county" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">County</label>
                        <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :value="address.county"
                            @input="update('county', $event.target.value)"
                        >
                        <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-sm-6">
                <ValidationProvider name="town" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">Town/City</label>
                        <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :value="address.town"
                            @input="update('town', $event.target.value)"
                        >
                        <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>

                <ValidationProvider name="postcode" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">Postcode</label>
                        <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.length > 0 }"
                            :value="address.postcode"
                            @input="update('postcode', $event.target.value)"
                        >
                        <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        model: {
            prop: 'address'
        },

        components: {
            ValidationProvider
        },

        props: {
            address: {
                type: Object
            }
        },

        methods: {
            update(key, value) {
                this.$emit('input', { ...this.address, [key]: value });
            }
        }
    }
</script>
