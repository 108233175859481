import Vue from 'vue';
import { normalize, schema } from 'normalizr';

const stitchCount = new schema.Entity('stitch_count_breakpoint');
const stitchQuantity = new schema.Entity('stitch_quantity_breakpoint');

const priceSchema = new schema.Entity('prices',{
    stitch_count_breakpoint: stitchCount,
    stitch_quantity_breakpoint: stitchQuantity
});


const state = {
    productSlug: '',
    counts: {},
    quantities: {},
    prices: {},
    changed: {}
};

const getters = {
    getCounts: (state) => {
        return Object.values(state.counts);
    },
    getQuantities: (state) => {
        return Object.values(state.quantities);
    },
    getRowPrices: (state) => (rowId) => {
        return Object.values(state.prices).filter(price => price.stitch_count_breakpoint === rowId);
    },
    getPrice: (state) => (priceId) => {
        return state.prices[priceId];
    }
};

const mutations = {
    setProductSlug(state, productSlug) {
        state.productSlug = productSlug;
    },
    setStitchCounts(state, counts) {
        state.counts = Object.assign({}, counts);
    },
    setStitchQuantities(state, quantities) {
        state.quantities = Object.assign({}, quantities);
    },
    setPrices(state, prices) {
        state.prices = Object.assign({}, prices);
    },
    addChange(state, { id, price }) {
        Vue.set(state.changed, id, price);
    }
};

const actions = {
    async loadPrices({ commit }, productSlug) {
        commit('setProductSlug', productSlug);

        let { data } = await axios.get(`/products/${productSlug}/stitch-prices`);

        let { entities: { stitch_count_breakpoint, stitch_quantity_breakpoint, prices } } = normalize(data.data, [priceSchema]);

        commit('setStitchCounts', stitch_count_breakpoint);
        commit('setStitchQuantities', stitch_quantity_breakpoint);
        commit('setPrices', prices);
    },
    createRow({ state, commit }, value) {
        return axios.post(`/products/${state.productSlug}/stitch-counts`, {
            ...value
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
