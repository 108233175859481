var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "permissions-selection-list checkbox-selection-list" },
    [
      _vm._l(_vm.permissions, function(permission) {
        return [
          _c(
            "div",
            { key: permission.id, staticClass: "column" },
            [
              _c(
                "checkbox-field",
                {
                  attrs: {
                    name: "permissions[]",
                    value: permission.id,
                    "is-selected": _vm.isSelected(permission)
                  },
                  on: {
                    input: function(selected) {
                      return _vm.updateSelected(permission, selected)
                    }
                  }
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(permission.name) +
                      "\n            "
                  )
                ]
              )
            ],
            1
          )
        ]
      }),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }