<template>
    <td>
        <a href="#" @click.prevent="show = true">{{ sizeBreakpoint.label }}</a>
        <modal :show="show" @close="closeModal">
            <size-breakpoint-form
                :breakpoint="sizeBreakpoint"
                @saved="closeModal"
                @deleted="closeModal"
            ></size-breakpoint-form>
        </modal>
    </td>
</template>

<script>
    export default {
        name: 'EditSizeBreakpoint',

        props: {
            sizeId: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                show: false
            }
        },

        computed: {
            sizeBreakpoint() {
                return this.$store.getters['product/fixedPrices/fetchSizeBreakpoint'](this.sizeId);
            }
        },

        methods: {
            closeModal() {
                this.show = false;
            }
        }
    }
</script>
