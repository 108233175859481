<template>
    <div class="trim-prices bg-white p-4">
        <ul>
            <li v-for="trim in items"
                :key="trim.id"
            >
                <label class="control-label">{{ trim.label }}</label>
                <input type="text" :value="formattedPrice(trim.price)"/>
            </li>
        </ul>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'TrimPrices',

        props: ['product'],

        created() {
            this.$store.dispatch('products/trims/loadPrices', this.product);
        },

        computed: {
            ...mapState('products/trims',[
                'items'
            ])
        },

        methods: {
            formattedPrice(price) {
                return (price / 100).toFixed(2);
            }
        }
    }
</script>
