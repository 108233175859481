var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-dark p-3" }, [
    _c("h3", [_vm._v("Badge Details")]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _c("div", { staticClass: "col" }, [
        _c("div", [_vm._v("Width")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            _vm._s(parseFloat(_vm.details.width)) +
              " " +
              _vm._s(_vm.details.unit)
          )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c("div", [_vm._v("Height")]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            _vm._s(parseFloat(_vm.details.height)) +
              " " +
              _vm._s(_vm.details.unit)
          )
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mb-3" }, [
      _vm.details.num_colours
        ? _c("div", { staticClass: "col" }, [
            _c("div", [_vm._v("No. Colours")]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.details.num_colours))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.details.stitch_count
        ? _c("div", { staticClass: "col" }, [
            _c("div", [_vm._v("Stitch Count")]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.details.stitch_count))])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.details.trim_count
        ? _c("div", { staticClass: "col" }, [
            _c("div", [_vm._v("Trim Count")]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.details.trim_count))])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.details.notes
      ? _c("div", { staticClass: "row mb-3" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", [_vm._v("Notes")]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(_vm.details.notes))])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }