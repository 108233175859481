<template>
    <div class="stitch-price">
        <div v-if="!editing" class="stitch-price-display" @click="editing = true">{{ amount }}</div>
        <input v-else type="text" v-model="newPrice" size="5"/>
    </div>
</template>

<script>
    import currency from 'currency.js';

    export default {
        name: 'StitchPrice',

        props: ['priceId'],

        data() {
            return {
                editing: false
            }
        },

        computed: {
            price() {
                return this.$store.getters['products/stitches/getPrice'](this.priceId);
            },
            amount() {
                let price = this.price.price;
                return (price / 100).toFixed(2);
            },
            newPrice: {
                get() {
                    return this.amount;
                },
                set(value) {
                    this.$store.commit('products/stitches/addChange',{
                        id: this.priceId,
                        price: value
                    });
                }
            }
        },
    }
</script>
