<template>
    <span>
        <a href="#" class="btn btn-danger" @click.prevent="show = true" v-text="buttonText"></a>
        <modal :show="show" @close="show = false" width="md">
            <div class="alert alert-danger" v-if="errorMsg" v-cloak v-text="errorMsg"></div>

            <h3>Cancel Order</h3>
            <p>Are you sure you want to cancel this order?</p>
            <div class="form-group">
                <label class="control-label">Reason</label>
                <textarea v-model="message" class="form-control" rows="5"></textarea>
            </div>

            <div class="mt-3 d-flex justify-content-between">
                <button class="btn btn-danger mr-2" @click.prevent="submit" v-text="confirmButtonText" :disabled="loading"></button>
                <a href="#" class="btn btn--grey" @click.prevent="show = false">Cancel</a>
            </div>
        </modal>
    </span>
</template>

<script>
    import ValidationError from "../../errors/ValidationError";

    export default {
        props: {
            order: {
                required: true,
                type: Object,
            },
            buttonText: {
                type: String,
                default: 'Cancel Order'
            },
            confirmButtonText: {
                type: String,
                default: 'Cancel Order'
            }
        },

        data() {
            return {
                show: false,
                message: '',
                errorMsg: '',
                loading: false
            }
        },

        computed: {
            abandonUrl() {
                return route('api.orders.abandon', this.order.reference);
            }
        },

        methods: {
            async submit() {
                try {
                    this.errorMsg = '';
                    this.loading = true;

                    let result = await axios.post(this.abandonUrl, {
                        message: this.message
                    });

                    window.location.reload();
                } catch (e) {
                    this.loading = false;
                    if (e instanceof ValidationError) {
                        return this.errorMsg = e.first('message');
                    }
                    this.errorMsg = e.message;
                }
            }
        }
    }
</script>
