<template>
    <div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="control-label">Original Width</label>
                    <span class="form-control-plaintext">{{ originalWidth }} {{ originalUnit }}</span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="form-group">
                    <label class="control-label">Original Height</label>
                    <span class="form-control-plaintext">{{ originalHeight }} {{ originalUnit }}</span>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4">
                <ValidationProvider name="Unit" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">Unit</label>
                        <select v-model="unit" class="form-control">
                            <option value="mm">mm</option>
                            <option value="cm">cm</option>
                            <option value="inch">inch</option>
                        </select>
                        <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-sm-6 col-md-4">
                <ValidationProvider name="Width" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">Width</label>
                        <div class="input-group" :class="{ 'is-invalid': errors.length > 0 }">
                            <input class="form-control" type="number" v-model.number="width">
                            <span class="input-group-append">
                                <span class="input-group-text">{{ unit }}</span>
                            </span>
                        </div>
                        <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
            <div class="col-sm-6 col-md-4">
                <ValidationProvider name="Height" v-slot="{ errors }" rules="required">
                    <div class="form-group">
                        <label class="control-label">Height</label>
                        <div class="input-group" :class="{ 'is-invalid': errors.length > 0 }">
                            <input class="form-control" type="number" v-model.number="height">
                            <span class="input-group-append">
                                <span class="input-group-text">{{ unit }}</span>
                            </span>
                        </div>
                        <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <ValidationProvider name="number of colours" v-slot="{ errors }" :rules="colourRules">
                    <div class="form-group">
                        <label class="control-label">Number of Colours</label>
                        <input
                            v-model.number="numColours"
                            class="form-control"
                            type="number"
                            :class="{ 'is-invalid': errors.length > 0 }"
                        />
                        <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                    </div>
                </ValidationProvider>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label class="control-label">Note</label>
                    <textarea v-model="notes" cols="30" rows="3" class="form-control"></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            value: {
                type: Object
            },
            product: {
                type: String
            },
            coloursMax: {
                type: Number
            }
        },

        data() {
            return {
                originalWidth: this.value.width,
                originalHeight: this.value.height,
                originalUnit: this.value.unit
            }
        },

        computed: {
            colourRules() {
                return {
                    required: true,
                    max_value: this.coloursMax
                };
            },
            width: {
                get() {
                    return this.value.width;
                },
                set(value) {
                    return this.$emit('input', { ...this.value, width: value });
                }
            },
            height: {
                get() {
                    return this.value.height;
                },
                set(value) {
                    return this.$emit('input', { ...this.value, height: value });
                }
            },
            unit: {
                get() {
                    return this.value.unit;
                },
                set(value) {
                    return this.$emit('input', { ...this.value, unit: value });
                }
            },
            numColours: {
                get() {
                    return this.value.num_colours;
                },
                set(value) {
                    return this.$emit('input', { ...this.value, num_colours: value });
                }
            },
            notes: {
                get() {
                    return this.value.notes;
                },
                set(value) {
                    return this.$emit('input', { ...this.value, notes: value });
                }
            }
        }
    }
</script>
