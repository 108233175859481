<template>
    <tr>
        <td>
            <input
                name="quote"
                type="radio"
                v-model="selectedQuote"
                :value="quote.id"
                :disabled="!hasBeenSubmitted"
                @change="selectQuote"
            />
        </td>
        <td>
            {{ quote.company_name }}
            <div v-if="quote.has_submitted"><small>(Sample Lead Times: {{ quote.sample_lead_display}})</small></div>
            <div v-if="quote.has_submitted"><small>(Production Lead Times: {{ quote.production_lead_display}})</small></div>
        </td>
        <td>
            <div class="font-weight-bold">{{ currencySymbol }}{{ quote.total | toPounds }}</div>
            <div>({{ currencySymbol }}{{ quote.unit_cost | toPounds }} per Badge)</div>
        </td>
        <td v-if="showMargin">
            <div class="input-group input-group-sm">
                <input
                    v-model.number="customerMargin"
                    type="number"
                    class="form-control"
                />
            </div>
        </td>
        <td>{{ subTotal | toPounds }}</td>
        <td>{{ quote.status }}</td>
        <td>{{ quote.submitter_name }}</td>
        <td>
            <a href="#" @click.prevent="showForm = true">Edit</a>
            <modal :show="showForm" width="lg">
                <supplier-quote-form
                    :quote="quote"
                    @cancel="showForm = false"
                    @saved="showForm = false"
                ></supplier-quote-form>
            </modal>
        </td>
    </tr>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        props: {
            quoteId: {
                type: String,
                required: true
            },
            showMargin: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                showForm: false,
                customerMargin: ''
            }
        },

        computed: {
            quote() {
                return this.$store.getters['orders/quoting/supplierQuotes/getQuote'](this.quoteId);
            },
            margin() {
                return this.$store.state.orders.quoting.margin;
            },
            selectedQuote: {
                get() {
                    return this.$store.state.orders.quoting.selectedQuote;
                },
                set(value) {
                    this.$store.commit('orders/quoting/setSelectedQuote', value);
                }
            },
            hasBeenSubmitted() {
                return this.quote.has_submitted;
            },
            subTotal() {
                let margin = this.customerMargin || 0;
                return parseInt(this.quote.total.money.amount) * (1 + (margin / 100));
            },
            currencySymbol() {
                let currency = this.quote.currency;
                return (currency) ? currency.symbol : '';
            }
        },

        mounted() {
            this.customerMargin = this.margin;
        },

        methods: {
            selectQuote() {
                this.$store.commit('orders/quoting/setQuoteType', 'supplier-quote');
                this.setTotal();
            },
            setTotal() {
                this.$store.commit('orders/quoting/setUnitCost', parseInt(this.quote.unit_cost.money.amount));
                this.$store.commit('orders/quoting/setSetupCost', 0);
                this.$store.commit('orders/quoting/setMargin', this.customerMargin);
                this.$store.dispatch('orders/quoting/setSelectedQuoteTotal', this.subTotal);
            }
        },

        watch: {
            customerMargin(value) {
                if (this.selectedQuote === this.quoteId) {
                    this.setTotal();
                }
            }
        }
    }
</script>
