<script>
export default {
    data() {
        return {
            active: false
        }
    },

    methods: {
        check() { 
            this.active = !this.active; 
        }
    }
}
</script>