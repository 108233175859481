var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-attributes" }, [
    _c("h3", { staticClass: "mb-3" }, [_vm._v("Attributes")]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.attributeList, function(attribute) {
          return _c("tr", { key: attribute.id }, [
            _c("td", [
              _c("div", [_vm._v(_vm._s(attribute.name))]),
              _vm._v(" "),
              _c("div", [_c("small", [_vm._v(_vm._s(attribute.description))])])
            ]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(attribute.required))]),
            _vm._v(" "),
            _c("td", [_vm._v(_vm._s(attribute.step))]),
            _vm._v(" "),
            _c("td", [
              _c("a", { attrs: { href: _vm.editUrl(attribute.id) } }, [
                _vm._v("Edit")
              ])
            ])
          ])
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Required")]),
        _vm._v(" "),
        _c("th", [_vm._v("Step")]),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }