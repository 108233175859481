<template>
    <div class="customer-approval-amendments">
        <h3 class="mb-3">Request Amendment</h3>
    </div>
</template>

<script>
    export default {
        props: {
            order: {
                type: Object,
                required: true
            }
        }


    }
</script>
