var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-attribute" }, [
    _c("h3", [_vm._v(_vm._s(_vm.attribute.name))]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _c("tr"),
          _vm._l(_vm.options, function(option) {
            return _c("tr", { key: option.id }, [
              _c("td", [_vm._v(_vm._s(option.label))]),
              _vm._v(" "),
              _c("td")
            ])
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Name")]), _vm._v(" "), _c("th")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }