<template>
    <span>
        <a href="#"
           @click.prevent="show = true"
           class="btn btn-danger"
        >Delete</a>
        <modal
            :show="show"
            @close="show = false"
            width="md"
        >
            <form
                :action="url"
                method="POST"
                class="d-flex flex-column align-items-center w-100"
                enctype="multipart/form-data"
            >
                <input type="hidden" name="_token" :value="csrfToken">
                <input type="hidden" name="_method" value="DELETE">
                <div>Are your sure you want to delete this customer</div>

                <div class="mt-3 d-flex justify-content-between">
                    <button class="btn mr-2 btn btn-primary">Delete</button>
                    <a href="#" class="btn btn--grey" @click.prevent="show = false">Cancel</a>
                </div>
            </form>
        </modal>
    </span>
</template>

<script>
    export default {
        props: {
            customer: {
                type: String
            }
        },

        data() {
            return {
                csrfToken: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
                show: false
            }
        },

        computed: {
            url() {
                return route('customers.destroy', this.customer);
            }
        }
    }
</script>
