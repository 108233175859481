var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("portal", { attrs: { to: "modals" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show"
              }
            ],
            staticClass:
              "nd-modal-backdrop d-flex justify-content-center align-items-center",
            on: { click: _vm.dismiss }
          },
          [
            _c(
              "div",
              {
                staticClass: "nd-modal bg-white p-4 rounded shadow-sm",
                class: _vm.modalClasses,
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                  }
                }
              },
              [_vm._t("default")],
              2
            )
          ]
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }