export default class ValidationError extends Error {
    constructor(message) {
        super(message);
        this.name = 'ValidationError';
        this.errors = {};
    }

    setErrors(errors) {
        this.errors = errors;
    }

    all() {
        return this.errors;
    }

    messages() {
        return Object.entries(this.errors).map(([field, errors]) => errors[0]);
    }

    first(key) {
        return this.errors[key][0];
    }
}
