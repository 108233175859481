<template>
    <td class="fixed-price">
        <input class="fixed-price-input" :class="{ 'changed': hasChange }" type="number" v-model="newPrice"/>
    </td>
</template>

<script>
    import currency from 'currency.js';

    export default {
        name: 'FixedPrice',

        props: {
            quantityId: {
                type: Number,
                required: true,
            },
            sizeId: {
                type: Number,
                required: true
            }
        },

        computed: {
            price() {
                return this.$store.getters['product/fixedPrices/fetchPrice'](this.quantityId, this.sizeId);
            },
            priceValue() {
                return this.price.price.money.amount;
            },
            change() {
                return this.$store.getters['product/fixedPrices/getChange'](this.price.id);
            },
            hasChange() {
                return !! this.change;
            },
            newPrice: {
                get() {
                    return (this.change) ? this.change : currency(this.priceValue).divide(100).value;
                },
                set(value) {
                    this.$store.commit('product/fixedPrices/addChange',{
                        id: this.price.id,
                        price: value
                    });
                }
            }
        }
    }
</script>

<style>
    .fixed-price-input {
        --webkit-appearance: none;
        border: 0;
        cursor: pointer;
        width: 50%;
    }

    .fixed-price-input.changed {
        color: red;
    }
</style>
