var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var handleSubmit = ref.handleSubmit
            return [
              _c(
                "form",
                {
                  ref: "form",
                  staticClass: "d-flex flex-column w-100",
                  attrs: { method: "POST" },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return handleSubmit(_vm.onSubmit)
                    }
                  }
                },
                [
                  _c("h3", { staticClass: "mb-3" }, [
                    _vm._v("Add Supplier Quote Quantity")
                  ]),
                  _vm._v(" "),
                  _vm._t("default", function() {
                    return [
                      _c("ValidationProvider", {
                        attrs: { name: "Quantity", rules: "required" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                return [
                                  _c("div", { staticClass: "form-group" }, [
                                    _c(
                                      "label",
                                      { attrs: { for: "quantity" } },
                                      [_vm._v("Quantity")]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value: _vm.quantity,
                                          expression: "quantity",
                                          modifiers: { number: true }
                                        }
                                      ],
                                      staticClass: "form-control",
                                      class: {
                                        "is-invalid": errors.length > 0
                                      },
                                      attrs: {
                                        id: "quantity",
                                        type: "number",
                                        name: "quantity"
                                      },
                                      domProps: { value: _vm.quantity },
                                      on: {
                                        input: function($event) {
                                          if ($event.target.composing) {
                                            return
                                          }
                                          _vm.quantity = _vm._n(
                                            $event.target.value
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.$forceUpdate()
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    errors.length
                                      ? _c(
                                          "div",
                                          { staticClass: "invalid-feedback" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-3 d-flex justify-content-end" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn mr-2 btn btn-primary",
                          attrs: { type: "submit" }
                        },
                        [_vm._v("Save")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn--grey",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.$emit("close")
                            }
                          }
                        },
                        [_vm._v("\n                Cancel\n            ")]
                      )
                    ]
                  )
                ],
                2
              )
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }