const state = {
    productSlug: '',
    items: {}
};

const mutations = {
    setProductSlug(state, productSlug) {
        state.productSlug = productSlug;
    },
    setItems(state, items) {
        state.items = Object.assign({}, items);
    }
};

const actions = {
    async loadPrices({ commit }, productSlug) {
        commit('setProductSlug', productSlug);

        let { data } = await axios.get(`/products/${productSlug}/trim-prices`);

        commit('setItems', data);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
