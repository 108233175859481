import { find } from 'lodash';
import {normalize, schema} from "normalizr";
import attributeQuoteSchema from "../../../schema/attributeQuotePrice";

const existingQuoteSchema = new schema.Entity('existingQuote');
const stitchCountSchema = new schema.Entity('stitchCounts');
const colourPriceSchema = new schema.Entity('colourPrices');
const trimPriceSchema = new schema.Entity('trimPrices');

const state = {
    quote: {
        stitch_count: '',
        num_colours_id: '',
        trim_count: 0,
        margin: 0,
        setup_cost: 2000,
        total: 0,
        selectable: 1
    },
    stitchCounts: {},
    colourPrices: {},
    trimPrices: {},
    attributes: {},
    attributeOptions: {},
    quotePrices: {},
    stitchPrice: 0
};

const getters = {
    stitchCountsList: (state) => {
        return Object.values(state.stitchCounts);
    },
    colourPriceList: (state) => {
        return Object.values(state.colourPrices);
    },
    attributesList: (state) => {
        return Object.values(state.attributes);
    },
    attributeOptionsList: (state) => (attributeId) => {
        return state.attributes[attributeId].options.map(optionId => state.attributeOptions[optionId]);
    },
    orderedTrimPrices: (state) => {
        return Object.values(state.trimPrices).sort((a, b) => a.max - b.max);
    },
    trimPrice: (state, getters) => {
        let trimCount = state.quote.trim_count;

        let trimPrice = find(getters.orderedTrimPrices, (price) => price.max >= trimCount);

        if (typeof trimPrice === 'undefined') return 0;

        return trimPrice.price;
    },
    attributeOptionPrice: (state) => (option) => {
        if (option.quote_price) {
            let quotePrice = state.quotePrices[option.quote_price];
            return quotePrice.price;
        }

        return parseInt(option.fixed_price.money.amount);
    },
    attributeOptionsTotal: (state, getters) => {
        return Object.values(state.attributeOptions).reduce((total, option) => {
            return total + getters.attributeOptionPrice(option);
        }, 0);
    },
    colourPrice: (state, getters) => {
        if (!state.quote.num_colours_id) return 0;

        let colourPrice = state.colourPrices[state.quote.num_colours_id];

        return colourPrice.price;
    },
    badgeTotal: (state, getters, rootState) => {
        let quantity = rootState.orders.quantity;
        let attributeTotal = getters.attributeOptionsTotal;

        return (state.stitchPrice + getters.trimPrice + getters.colourPrice + attributeTotal) * quantity;
    },
    total: (state, getters, rootState) => {
        return (getters.badgeTotal + state.quote.setup_cost) * (1 + (state.quote.margin / 100));
    }
};

const mutations = {
    setStitchCounts(state, stitchCounts) {
        state.stitchCounts = Object.assign({}, state.stitchCounts, stitchCounts);
    },
    setColourPrices(state, colourPrices) {
        state.colourPrices = Object.assign({}, state.colourPrices, colourPrices);
    },
    setTrimPrices(state, trimPrices) {
        state.trimPrices = Object.assign({}, state.trimPrices, trimPrices);
    },
    setAttributes(state, attributes) {
        state.attributes = attributes;
    },
    setAttributeOptions(state, options) {
        state.attributeOptions = Object.assign({}, state.attributeOptions, options);
    },
    setQuotePrices(state, quotePrices) {
        state.quotePrices = Object.assign({}, state.quotePrices, quotePrices);
    },
    setQuote(state, quote) {
        state.quote = Object.assign({}, state.quote, quote);
    },
    setStitchPrice(state, stitchPrice) {
        state.stitchPrice = stitchPrice;
    }
};

const actions = {
    async loadData({ commit, state, rootState, rootGetters }) {
        let productSlug = rootState.orders.productSlug;
        let orderReference = rootState.orders.reference;
        let size = rootState.orders.meta.size;

        let attributeOptionIds = rootGetters['orders/attributeOptionIdList'];

        let [existingQuoteData, stitchData, colourOptions, trimData, attributePrices] = await Promise.all([
            axios.get( route('api.orders.embroidery-badge-quotes.index', { order: orderReference} ) ),
            axios.get( route('products.stitch-counts.index', { product: productSlug }) ),
            axios.get( route('products.colour-prices.index', { product: productSlug }) ),
            axios.get( route('products.trim-prices.index', { product: productSlug })),
            axios.get( route('api.orders.attribute-quote-prices.index', { order: orderReference }), {
                params: {
                    size: size,
                    options: attributeOptionIds
                }
            }),
        ]);

        let { entities: { stitchCounts } } = normalize(stitchData.data, [stitchCountSchema]);
        let { entities: { colourPrices } } = normalize(colourOptions.data,[colourPriceSchema]);
        let { entities: { trimPrices } } = normalize(trimData.data, [trimPriceSchema]);
        let { entities: { attributes, attributeOptions, quotePrice }} = normalize(attributePrices.data, [attributeQuoteSchema]);

        commit('setQuote', existingQuoteData.data.data);
        commit('setStitchCounts', stitchCounts);
        commit('setColourPrices', colourPrices);
        commit('setTrimPrices', trimPrices);
        commit('setAttributes', attributes || {});
        commit('setAttributeOptions', attributeOptions || {});
        commit('setQuotePrices', quotePrice);
    },
    async setStitchCount({ commit, rootState }, stitchCount) {
        commit('setQuote', { stitch_count_breakpoint_id: stitchCount });

        let quantity = rootState.orders.quantity;
        let productSlug = rootState.orders.productSlug;

        let { data } = await axios.get( route('products.base-quote-price', { product: productSlug }) ,{
            params: {
                quantity: quantity,
                stitch_count: stitchCount
            }
        });

        commit('setStitchPrice', data.price);
    },
    saveQuote({ commit, getters, rootState }) {
        let orderReference = rootState.orders.reference;

        let quoteData = Object.assign({}, state.quote, { total: getters.total });

        return axios.post( route('api.orders.embroidery-badge-quotes.store', { order: orderReference }), quoteData)
            .then(({data}) => {
                commit('setQuote', data.data);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
