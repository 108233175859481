<template>
    <div class="quote-attribute-options flex-1">
        <div
            v-for="option in options"
            :key="option.id"
        >
            <input type="number" class="form-control" readonly :value="quotePrice(option)">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'QuoteAttributeOptions',

        props: {
            attributeId: {
                type: Number,
                required: true
            }
        },

        computed: {
            options() {
                return this.$store.getters['orders/quoting/embroideryBadge/attributeOptionsList'](this.attributeId);
            }
        },

        methods: {
            quotePrice(option) {
                let quotePrice = this.$store.state.orders.quoting.embroideryBadge.quotePrices[option.quote_price];

                let price = (quotePrice) ? quotePrice.price : parseInt(option.fixed_price.money.amount);

                return (price / 100).toFixed(2);
            }
        }
    }
</script>
