<template>
    <svg v-if="name === 'approval-tick'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40"><circle cx="20" cy="20" r="20" fill="#85be00"/><path d="M17.758 24.754c.11.164.273.22.492.22.19 0 .355-.055.492-.22l8.066-8.04c.11-.11.19-.273.19-.492 0-.192-.082-.355-.19-.492l-1.012-.984c-.137-.137-.3-.22-.492-.22s-.355.082-.492.22L18.25 21.31l-3.062-3.062c-.164-.137-.328-.22-.52-.22s-.355.082-.465.22l-1.012.984c-.137.137-.19.3-.19.492 0 .22.055.383.19.492l4.566 4.54z" fill="#fff"/></svg>
    <svg v-else-if="name === 'not-approved'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40"><circle cx="20" cy="20" r="20" fill="#e4ead6"/></svg>
</template>

<script>
    export default {
        props: {
            name: String,
        },
    }
</script>
