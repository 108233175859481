var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("a", {
        class: _vm.buttonClass,
        attrs: { href: "#" },
        domProps: { textContent: _vm._s(_vm.buttonText) },
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.show = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { show: _vm.show, width: _vm.width },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "d-flex flex-column align-items-center w-100",
              attrs: {
                action: _vm.url,
                method: _vm.methodName,
                enctype: "multipart/form-data"
              }
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "_token" },
                domProps: { value: _vm.csrfToken }
              }),
              _vm._v(" "),
              _vm.isRestfulMethod
                ? _c("input", {
                    attrs: { type: "hidden", name: "_method" },
                    domProps: { value: _vm.method }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm._t("default", function() {
                return [
                  _c("div", { staticClass: "text-center" }, [
                    _vm._v("Are your sure you want to delete this record")
                  ])
                ]
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3 d-flex justify-content-between" },
                [
                  _vm.confirmButtonText
                    ? _c("button", {
                        staticClass: "btn mr-2",
                        class: _vm.confirmButtonClass,
                        domProps: { textContent: _vm._s(_vm.confirmButtonText) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("a", {
                    staticClass: "btn btn--grey",
                    attrs: { href: "#" },
                    domProps: { textContent: _vm._s(_vm.cancelButtonText) },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.show = false
                      }
                    }
                  })
                ]
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }