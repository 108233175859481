import parentProduct from '../product';

const state = Object.assign({}, parentProduct.state, {});

const getters = Object.assign({}, parentProduct.getters, {});

const mutations = Object.assign({}, parentProduct.mutations, {});

const actions = Object.assign({}, parentProduct.actions, {});

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
