<template>
    <div class="tabs">
        <ul class="nav nav-tabs">
            <li v-for="tab in tabs" class="nav-item">
                <a @click.prevent="selectTab(tab)" class="nav-link" :class="{ 'active': tab.isActive }">{{ tab.name }}</a>
            </li>
        </ul>

        <div class="tabs-details">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tabs',

        data() {
            return {
                tabs: []
            }
        },

        created() {
            this.tabs = this.$children;
        },

        methods: {
            selectTab(selectedTab) {
                this.tabs.forEach(tab => {
                    tab.isActive = (tab.name == selectedTab.name);
                });
            }
        }
    }
</script>
