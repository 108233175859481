<template>
    <div class="embroidered-badge-pricing">
        <tabs>
            <tab name="Stitch Prices" :selected="true">
                <stitch-matrix :product="product"></stitch-matrix>
            </tab>
            <tab name="Attribute Prices">
                <attribute-quoting-prices :product="product"></attribute-quoting-prices>
            </tab>
            <tab name="Trim Prices">
                <trim-prices :product="product"></trim-prices>
            </tab>
        </tabs>
    </div>
</template>

<script>
    export default {
        name: 'EmbroideredBadgesPricing',

        props: ['product']
    }
</script>
