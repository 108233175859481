import stitches from "./stitches";
import attributeQuotePrices from "./attributeQuotePrices";
import trims from './trims';

export default {
    namespaced: true,
    modules: {
        stitches,
        trims,
        attributeQuotePrices
    }
}
