var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "checkbox-field",
      on: {
        click: function($event) {
          return _vm.toggle()
        }
      }
    },
    [
      _vm.isSelected
        ? _c("input", {
            attrs: { type: "hidden", name: _vm.name },
            domProps: { value: _vm.value }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "checker" }, [
        _c("i", { class: ["fas", _vm.iconStyle] })
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "label" }, [_vm._t("default")], 2)
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }