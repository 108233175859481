import Vue from 'vue';
import {normalize} from 'normalizr';
import deliverySchema from '../../schema/deliveries';

const state = {
    items: {}
};

const getters = {
    deliveryOptions(state) {
        return Object.values(state.items);
    },
    priceDisplay: (state) => optionId => {
        let option = state.items[optionId];

        let priceLabel = (option.percentage_price) ? `${option.percentage_price}%` : `${option.fixed_price.formatted}`;

        if (option.base_delivery_id) {
            let parent = state.items[option.base_delivery_id];
            priceLabel += ` onto ${parent.name}`;
        }

        return `+ ${priceLabel}`;
    },
    optionType: (state) => optionId => {
        let option = state.items[optionId];

        return (option.percentage_price) ? `percentage` : `fixed`;
    },
    optionValue: (state) => optionId => {
        let option = state.items[optionId];

        return (option.percentage_price) ? option.percentage_price : parseFloat(option.fixed_price.amount) / 100;
    },
    getParentOptions: (state, getters) => optionId => {
        return getters.deliveryOptions.filter(option => option.id !== optionId);
    }
};

const mutations = {
    setOptions(state, options) {
        state.items = Object.assign({}, options);
    },
    addOption(state, option) {
        Vue.set(state.items, option.id, option);
    },
    updateOption(state, option) {
        Vue.set(state.items, option.id, option);
    },
    removeOption(state, optionId) {
        Vue.delete(state.items, optionId);
    }
};

const actions = {
    loadOptions({ commit, rootState }) {
        let slug = rootState.product.slug;

        return axios.get(route('api.products.deliveries.index', slug))
            .then(({ data }) => {
                let { entities: { deliveries } } = normalize(data.data, [deliverySchema]);

                commit('setOptions', deliveries);
            });
    },
    createOption({ commit, rootState}, { name, description, value, type, base_delivery_id }) {
        let slug = rootState.product.slug;

        let attributes = {
            name,
            description,
            percentage_price: (type === 'percentage') ? value : 0,
            fixed_price: (type === 'percentage') ? 0 : (parseInt(value) * 100),
            base_delivery_id: base_delivery_id || null
        };

        return axios.post(route('api.products.deliveries.store', [slug]), attributes)
            .then(({ data }) => {
                commit('addOption', data.data);
            });
    },
    updateOption({ commit, rootState }, { id, name, description, value, type, base_delivery_id, active }) {
        let slug = rootState.product.slug;

        let attributes = {
            name,
            description,
            percentage_price: (type === 'percentage') ? value : 0,
            fixed_price: (type === 'percentage') ? 0 : (parseInt(value) * 100),
            base_delivery_id: base_delivery_id || null,
            active
        };

        return axios.put(route('api.products.deliveries.update', [slug, id]), attributes)
            .then(({ data }) => {
                commit('updateOption', data.data);
            });
    },
    deleteOption({ commit, rootState }, optionId) {
        let slug = rootState.product.slug;

        return axios.delete(route('api.products.deliveries.destroy', [slug, optionId]))
            .then(({ data }) => {
                commit('removeOption', optionId);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
