var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "a",
        {
          staticClass: "btn btn-danger",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.show = true
            }
          }
        },
        [_vm._v("Delete")]
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { show: _vm.show, width: "md" },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        [
          _c(
            "form",
            {
              staticClass: "d-flex flex-column align-items-center w-100",
              attrs: {
                action: _vm.url,
                method: "POST",
                enctype: "multipart/form-data"
              }
            },
            [
              _c("input", {
                attrs: { type: "hidden", name: "_token" },
                domProps: { value: _vm.csrfToken }
              }),
              _vm._v(" "),
              _c("input", {
                attrs: { type: "hidden", name: "_method", value: "DELETE" }
              }),
              _vm._v(" "),
              _c("div", [
                _vm._v("Are your sure you want to delete this customer")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3 d-flex justify-content-between" },
                [
                  _c("button", { staticClass: "btn mr-2 btn btn-primary" }, [
                    _vm._v("Delete")
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--grey",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.show = false
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ]
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }