<template>
    <div class="quote-attributes">
        <div class="d-flex align-items-center mb-3"
             v-for="attribute in attributesList"
             :key="attribute.id"
        >
            <label class="quote-label mb-0 pr-5">{{ attribute.name }}</label>
            <quote-attribute-options
                :attribute-id="attribute.id"
            ></quote-attribute-options>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'QuoteAttributes',

        computed: {
            ...mapGetters('orders/quoting/embroideryBadge',[
                'attributesList'
            ])
        }
    }
</script>
