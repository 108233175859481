<template>
    <div class="product-delivery">
        <h3 class="mb-3">Delivery Options</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Note</th>
                    <th>Cost</th>
                    <th>Active</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <delivery-item
                    v-for="option in deliveryOptions"
                    :key="option.id"
                    :option="option"
                ></delivery-item>
            </tbody>
        </table>
        <div class="clearfix">
            <add-delivery class="float-right"></add-delivery>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import DeliveryModule from '../../../store/modules/product/delivery';

    export default {
        name: 'ProductDelivery',

        computed: {
            ...mapGetters('product/delivery',[
                'deliveryOptions'
            ])
        },

        beforeCreate() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.product.delivery)) {
                store.registerModule(['product', 'delivery'], DeliveryModule);
            }

            this.$store.dispatch('product/delivery/loadOptions');
        }
    }
</script>
