var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("td", { staticClass: "fixed-price" }, [
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.newPrice,
          expression: "newPrice"
        }
      ],
      staticClass: "fixed-price-input",
      class: { changed: _vm.hasChange },
      attrs: { type: "number" },
      domProps: { value: _vm.newPrice },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.newPrice = $event.target.value
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }