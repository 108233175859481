<template>
    <ValidationObserver v-slot="{ handleSubmit }" slim>
        <form @submit.prevent="handleSubmit(onSubmit)">
            <h3 class="mb-3">Reject Artwork</h3>

            <div v-if="error" class="alert alert-danger">{{ error }}</div>

            <ValidationProvider name="status" v-slot="{ errors }" rules="required">
                <div class="form-group">
                    <label class="control-label">Return to Status</label>
                    <select
                        v-model="status"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    >
                        <option v-for="(status, index) in statuses"
                                :key="index"
                                :value="status"
                                v-text="status"
                        ></option>
                    </select>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>

            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="notify" value="1" id="notify-digitizer">
                <label class="form-check-label" for="notify-digitizer">
                    Notify Digitizer
                </label>
            </div>

            <ValidationProvider name="reason" v-slot="{ errors }" rules="required">
                <div class="form-group">
                    <label class="control-label">Reason</label>
                    <textarea
                        v-model="reason"
                        cols="30"
                        rows="3"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    ></textarea>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>

            <div>
                <button type="submit" class="btn btn-primary" :disabled="submitting">Submit</button>
                <button type="button" class="btn btn-default" @click.prevent="cancel" :disabled="submitting">Cancel</button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationObserver,
            ValidationProvider
        },

        props: {
            order: {
                type: Object
            },
            statuses: {
                type: Array
            }
        },

        data() {
            return {
                reason: '',
                notify: 0,
                status: '',
                error: '',
                submitting: false
            }
        },

        created() {
            this.status = this.statuses[0];
        },

        methods: {
            async onSubmit() {
                this.error = '';

                let rejected = confirm('Are you sure you want to reject this artwork');
                if (rejected) {
                    try {
                        this.submitting = true;

                        let result = await axios.post(route('api.orders.qc-reject-digitized.store', this.order.reference), {
                            status: this.status,
                            reason: this.reason,
                            notify: this.notify
                        });

                        this.submitting = false;
                        location.reload();
                    } catch (e) {
                        this.submitting = false;
                        this.error = 'We were not able to process this request';
                    }
                }
            },
            cancel() {
                this.$emit('cancel');
            }
        }
    }
</script>
