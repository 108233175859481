var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "embroidered-badge-pricing" },
    [
      _c(
        "tabs",
        [
          _c(
            "tab",
            { attrs: { name: "Stitch Prices", selected: true } },
            [_c("stitch-matrix", { attrs: { product: _vm.product } })],
            1
          ),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { name: "Attribute Prices" } },
            [
              _c("attribute-quoting-prices", {
                attrs: { product: _vm.product }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "tab",
            { attrs: { name: "Trim Prices" } },
            [_c("trim-prices", { attrs: { product: _vm.product } })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }