var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mb-5" }, [
    _c(
      "div",
      { staticClass: "col-sm-8" },
      [
        _c("ValidationObserver", { ref: "form" }, [
          _c(
            "div",
            { staticClass: "bg-white p-4" },
            [
              _vm.validationErrors.length > 0
                ? _c(
                    "div",
                    { staticClass: "alert alert-danger" },
                    _vm._l(_vm.validationErrors, function(error, index) {
                      return _c("div", {
                        key: index,
                        domProps: { textContent: _vm._s(error) }
                      })
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "order-form-subtitle" }, [
                _vm._v("Customer")
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("ValidationProvider", {
                    attrs: { name: "customer", rules: _vm.notEmpty },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("customer-select", {
                              class: { "is-invalid": errors.length > 0 },
                              attrs: { viewDetails: _vm.viewDetails },
                              model: {
                                value: _vm.customer,
                                callback: function($$v) {
                                  _vm.customer = $$v
                                },
                                expression: "customer"
                              }
                            }),
                            _vm._v(" "),
                            errors.length
                              ? _c(
                                  "span",
                                  { staticClass: "invalid-feedback" },
                                  [_vm._v(_vm._s(errors[0]))]
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _vm._v(" "),
              _vm.viewDetails
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("h3", [_vm._v("Billing Details")]),
                        _vm._v(" "),
                        _c("billing-details", {
                          model: {
                            value: _vm.billingDetails,
                            callback: function($$v) {
                              _vm.billingDetails = $$v
                            },
                            expression: "billingDetails"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("h3", [_vm._v("Shipping Details")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-check mb-3" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.sameAsBilling,
                                expression: "sameAsBilling"
                              }
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox", id: "sameAsBilling" },
                            domProps: {
                              value: true,
                              checked: Array.isArray(_vm.sameAsBilling)
                                ? _vm._i(_vm.sameAsBilling, true) > -1
                                : _vm.sameAsBilling
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.sameAsBilling,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = true,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.sameAsBilling = $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.sameAsBilling = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.sameAsBilling = $$c
                                }
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "form-check-label",
                              attrs: { for: "sameAsBilling" }
                            },
                            [
                              _vm._v(
                                "\n                                Same As Billing Address\n                            "
                              )
                            ]
                          )
                        ]),
                        _vm._v(" "),
                        !_vm.sameAsBilling
                          ? _c("shipping-details", {
                              model: {
                                value: _vm.shippingDetails,
                                callback: function($$v) {
                                  _vm.shippingDetails = $$v
                                },
                                expression: "shippingDetails"
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "order-form-subtitle" }, [
                _vm._v("Product")
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.selectedProduct,
                      expression: "selectedProduct"
                    }
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedProduct = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    }
                  }
                },
                [
                  _c("option", { attrs: { value: "" } }, [
                    _vm._v("- Select Product -")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.products, function(product) {
                    return _c("option", {
                      key: product.id,
                      domProps: {
                        value: product.id,
                        textContent: _vm._s(product.name)
                      }
                    })
                  })
                ],
                2
              ),
              _vm._v(" "),
              _vm.optionsForm
                ? _c(_vm.optionsForm, { tag: "component", staticClass: "mt-4" })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-4" }, [
                _c("input", {
                  staticClass:
                    "rounded p-2 px-4 text-white bg-black disabled:opacity-50",
                  class: {
                    "cursor-wait": _vm.submitting,
                    "cursor-pointer": !_vm.submitting
                  },
                  attrs: {
                    disabled: _vm.submitting,
                    type: "button",
                    value: "Submit"
                  },
                  on: { click: _vm.onSubmit }
                })
              ])
            ],
            1
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-4" }, [
      _c("div", { staticClass: "sticky-top" }, [
        _c(
          "div",
          { staticClass: "bg-white p-3" },
          [
            _c("order-summary", {
              attrs: { "options-loaded": _vm.optionsLoaded }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }