<template>
    <div class="product-attribute">
        <h3>{{ attribute.name }}</h3>
        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <tr
                        v-for="option in options"
                        :key="option.id"
                    >
                        <td>{{ option.label }}</td>
                        <td></td>
                    </tr>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: 'AttributeTable',

    props: {
        attributeId: {
            type: Number,
            required: true
        }
    },

    computed: {
        attribute() {
            return this.$store.getters['product/attributes/getAttribute'](this.attributeId);
        },
        options() {
            return this.$store.getters['product/attributes/optionsList'](this.attributeId);
        }
    }
}
</script>