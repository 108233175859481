<template>
    <tr>
        <td>
           <span v-if="editing">
                <input type="number" class="form-control" v-model.number="quantity">
            </span>
            <p v-else>{{ item.quantity }}</p>
        </td>
        <td>
            <span v-if="editing">
                <button type="button" class="btn btn-link" @click.prevent="cancel">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="save">Save</button>
            </span>
            <span v-else>
                <a href="#" @click.prevent="editing = !editing">Edit</a>
                <a href="#" @click.prevent="deleteItem">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "ProductQuoteQuantitiesItem",

        props: {
            item:{
                type: Object,
                required: true,
            }
        },

        computed: {
            modified() {
                return this.item.quantity != this.quantity;
            }
        },

        data() {
            return {
                editing: false,
                quantity: this.item.quantity,
            };
        },

        methods: {
            cancel() {
                this.quantity = this.item.quantity;
                this.editing = false;
            },
            async save() {
                let response = await this.$store.dispatch('product/quoteQuantities/update',{
                    id: this.item.id,
                    quantity: this.quantity
                });

                this.editing = false;

                this.$emit('saved');
            },
            async deleteItem() {
                let response = await this.$store.dispatch('product/quoteQuantities/deleteItem', this.item.id);

                this.$emit('deleted');
            }
        }
    }
</script>
