<template>
    <div>
        <h3>Order Summary</h3>
        <div class="summary-product-name">{{ productName }}</div>

        <template v-if="optionsLoaded">
            <div class="summary-section">
                <div class="summary-section-title">Customise Options</div>


                <div v-if="!!dimensions" class="summary-item">
                    <div class="summary-item-label">Size</div>
                    <div class="summary-item-options">
                        <div class="summary-value-label">{{ dimensions }}</div>
                    </div>
                </div>

                <template
                    v-for="(options, attributeId) in customiseOptions"
                >
                    <selected-options-summary
                        :key="attributeId"
                        :attribute-id="attributeId"
                        :options="options"
                    ></selected-options-summary>
                </template>

                <div class="summary-item">
                    <div class="summary-item-label">Quantity</div>
                    <div class="summary-item-options">
                        <div class="summary-value-label">{{ quantity }}</div>
                    </div>
                </div>
            </div>

            <div class="summary-section" v-if="hasSpecialOptions">
                <div class="summary-section-title">Special Options</div>

                <template
                    v-for="(options, attributeId) in specialOptions"
                >
                    <selected-options-summary
                        :key="attributeId"
                        :attribute-id="attributeId"
                        :options="options"
                    ></selected-options-summary>
                </template>
            </div>

            <div class="summary-section" v-if="hasSpecialOptions">
                <div class="summary-section-title">Artwork</div>
                <div class="summary-item">
                    <div class="summary-item-label">Design Name</div>
                    <div class="summary-item-options">
                        <div class="summary-value-label">{{ designName }}</div>
                    </div>
                </div>
                <div class="summary-artwork">
                    <div class="summary-item-label">Arwork</div>
                    <image-preview :file="artwork"></image-preview>
                </div>
            </div>

            <div class="summary-section" v-if="deliveryId">
                <div class="summary-section-title">Turnaround</div>
                <div class="summary-item">
                    <div class="summary-item-label">Delivery</div>
                    <div class="summary-item-options">
                        <div class="summary-value-label">{{ deliveryName }}</div>
                    </div>
                </div>
            </div>

            <div class="summary-item" v-if="vatable">
                <div class="summary-item-label">Vat {{ vatRate }}%</div>
                <div class="summary-item-options">
                    <div class="summary-value-label"></div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { isEmpty } from 'lodash';

    export default {
        props: {
            optionsLoaded: {
                type: Boolean,
                default: false
            }
        },

        computed: {
            ...mapGetters('ordering',[
                'productName',
                'vatRate'
            ]),
            ...mapState('ordering/order', [
                'quantity',
                'artwork',
                'designName',
                'attributes',
                'deliveryId',
                'vatable',
            ]),
            ...mapState('ordering/customer', [
                'vatable'
            ]),
            dimensions() {
                if (typeof this.$store.getters['ordering/order/getDimensionValues'] !== 'undefined') {
                    let { width, height, unit } = this.$store.getters['ordering/order/getDimensionValues'];
                    if (width && height) {
                        return `${width}${unit} x ${height}${unit}`;
                    }
                }
                return '';
            },
            customiseOptions() {
                return this.filterOptionsByStep(1);
            },
            specialOptions() {
                return this.filterOptionsByStep(2);
            },
            hasSpecialOptions() {
                return ! isEmpty(this.specialOptions);
            },
            deliveryName() {
                let deliveryOption = this.$store.getters['ordering/product/getDeliveryOption'](this.deliveryId);
                return deliveryOption.name;
            }
        },

        methods: {
            filterOptionsByStep(step) {
                return Object.keys(this.attributes)
                    .filter(attributeId => {
                        let attribute = this.$store.getters['ordering/product/getAttribute'](attributeId);
                        return attribute.step === step;
                    })
                    .reduce((obj, key) => {
                        obj[key] = this.attributes[key];
                        return obj;
                    }, {});
            }
        }
    }
</script>
