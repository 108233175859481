var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stitch-matrix bg-white p-4" },
    [
      _c("table", { staticClass: "table table-striped" }, [
        _c("thead", [
          _c(
            "tr",
            [
              _c("th"),
              _vm._v(" "),
              _vm._l(_vm.quantities, function(quantity) {
                return _c("th", { key: quantity.id }, [
                  _vm._v(_vm._s(quantity.quantity))
                ])
              })
            ],
            2
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          _vm._l(_vm.counts, function(count) {
            return _c(
              "tr",
              { key: count.id },
              [
                _c("td", [_vm._v(_vm._s(count.value) + " ")]),
                _vm._v(" "),
                _vm._l(_vm.prices(count.id), function(price) {
                  return _c(
                    "td",
                    { key: price.id },
                    [_c("stitch-price", { attrs: { priceId: price.id } })],
                    1
                  )
                })
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c("add-row", { attrs: { label: "Add Stitch Count" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }