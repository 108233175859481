<template>
    <ValidationObserver v-slot="{ handleSubmit }">
        <form ref="form" method="POST" class="d-flex flex-column w-100" @submit.prevent="handleSubmit(onSubmit)">

            <h3 class="mb-3">Add Sample Type</h3>

            <slot v-cloak>
                <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="name">Name</label>
                        <input id="name" type="text" name="name" v-model="name" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                        <div class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <div class="form-group">
                    <label for="description">Description</label>
                    <textarea id="description" name="description" v-model="description" class="form-control"></textarea>
                </div>
                <div class="form-group">
                    <label for="price">Price</label>
                    <input id="price" type="text" name="price" v-model="price" class="form-control">
                </div>
            </slot>

            <div class="mt-3 d-flex justify-content-end">
                <button class="btn mr-2 btn btn-primary"  type="submit">Save</button>
                <button
                    class="btn btn--grey"
                    type="button"
                    @click="$emit('close')">
                    Cancel
                </button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        name: "ProductSampleTypeForm",

        components: {
            ValidationObserver,
            ValidationProvider
        },

        data() {
            return {
                name: '',
                description: '',
                price: null,
            }
        },

        methods: {
            async onSubmit() {
                let response = this.$store.dispatch('product/sampleTypes/createSampleType',{
                    name: this.name,
                    description: this.description,
                    price: this.price
                });

                this.$emit('saved');
            }
        }
    }
</script>
