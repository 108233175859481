var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.quote.selectable
        ? _c("div", { staticClass: "alert alert-danger" }, [
            _vm._v(
              "\n        We are unable to generate an internal quote, therefore you must select a supplier quote to proceed.\n    "
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("table", { staticClass: "table table-striped" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("tbody", [
          _vm.hasQuote
            ? _c("tr", [
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedQuote,
                        expression: "selectedQuote"
                      }
                    ],
                    attrs: {
                      name: "quote",
                      type: "radio",
                      disabled: !_vm.quote.selectable
                    },
                    domProps: {
                      value: _vm.quote.id,
                      checked: _vm._q(_vm.selectedQuote, _vm.quote.id)
                    },
                    on: {
                      change: [
                        function($event) {
                          _vm.selectedQuote = _vm.quote.id
                        },
                        _vm.selectQuote
                      ]
                    }
                  })
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "mr-4" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "Price Per Badge: £" +
                            _vm._s(_vm._f("toPounds")(_vm.quote.unit_cost))
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mr-4" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Quantity: " + _vm._s(_vm.quantity))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "Setup Cost: £" +
                            _vm._s(_vm._f("toPounds")(_vm.quote.setup_cost))
                        )
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "mr-2" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Stitch Count:")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.quote.stitch_count) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mr-2" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("Trim Count:")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.quote.trim_count) +
                          "\n                    "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mr-2" }, [
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v("No. Colours:")
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.quote.num_colours) +
                          "\n                    "
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("£" + _vm._s(_vm._f("toPounds")(_vm.quote.total)))
                ]),
                _vm._v(" "),
                _c("td", [
                  _c("div", { staticClass: "input-group input-group-sm" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.customerMargin,
                          expression: "customerMargin",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { type: "number" },
                      domProps: { value: _vm.customerMargin },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.customerMargin = _vm._n($event.target.value)
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("td", [
                  _vm._v("£" + _vm._s(_vm._f("toPounds")(_vm.subTotal)))
                ])
              ])
            : _c("tr", [
                _c("td"),
                _vm._v(" "),
                _c("td", [_vm._v("No current quote")]),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td"),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showForm = true
                        }
                      }
                    },
                    [_vm._v("Create")]
                  )
                ])
              ])
        ])
      ]),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { show: _vm.showForm, width: "lg" } },
        [
          _c("embroidery-badge-quote-form", {
            on: {
              cancel: function($event) {
                _vm.showForm = false
              },
              saved: function($event) {
                _vm.showForm = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _vm._v(" "),
        _c("th", [_vm._v("Detail")]),
        _vm._v(" "),
        _c("th", [_vm._v("Subtotal")]),
        _vm._v(" "),
        _c("th", [_vm._v("Margin")]),
        _vm._v(" "),
        _c("th", [_vm._v("Total")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }