var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-delivery" }, [
    _c("h3", { staticClass: "mb-3" }, [_vm._v("Delivery Options")]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.deliveryOptions, function(option) {
          return _c("delivery-item", {
            key: option.id,
            attrs: { option: option }
          })
        }),
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "clearfix" },
      [_c("add-delivery", { staticClass: "float-right" })],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Note")]),
        _vm._v(" "),
        _c("th", [_vm._v("Cost")]),
        _vm._v(" "),
        _c("th", [_vm._v("Active")]),
        _vm._v(" "),
        _c("th", [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }