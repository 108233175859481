<template>
    <div>
        <input type="hidden" :name="name" :value="input"/>
        <vue-ctk-date-time-picker
            v-model="input"
            format="YYYY-MM-DD HH:mm:ss"
            formatted="DD/MM/YYYY HH:mm"
            @input="dateSelected"
            :min-date="minDate"
            :max-date="maxDate"
            :disabled="disabled">
        </vue-ctk-date-time-picker>
    </div>
</template>

<script>
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

export default {
    name: 'DateTimePicker',

    components: {
        "vue-ctk-date-time-picker": VueCtkDateTimePicker
    },

    props: {
        name: null,
        value: null,
        disabled: {
            type: Boolean,
            required: false,
        },
        minDate: {
            type: String,
            required: false,
        },
        maxDate: {
            type: String,
            required: false,
        }
    },

    data() {
        return {
            input: this.value
        }
    },

    methods: {
        dateSelected(value) {
            this.$emit('date-selected', value)
        }
    },

    watch: {
        disabled(newVal, oldVal) {
            if (newVal === true) {
                this.input = null
            }
        }
    }
}
</script>
