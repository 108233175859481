var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", { ref: "form", attrs: { slim: "" } }, [
    _c("div", { staticClass: "supplier-quote-form" }, [
      _c("h3", [_vm._v("Edit Quote")]),
      _vm._v(" "),
      _vm.formError
        ? _c("div", {
            staticClass: "alert alert-danger",
            domProps: { textContent: _vm._s(_vm.formError) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.validationErrors.length > 0
        ? _c(
            "div",
            { staticClass: "alert alert-danger" },
            _vm._l(_vm.validationErrors, function(validError, index) {
              return _c("li", {
                key: index,
                domProps: { textContent: _vm._s(validError) }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c("div", [
            _c("div", { staticClass: "text-darker text-uppercase" }, [
              _vm._v("Quote reference no.")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.supplier_reference,
                    expression: "supplier_reference"
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", name: "supplier_reference" },
                domProps: { value: _vm.supplier_reference },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.supplier_reference = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "text-darker text-uppercase" }, [
              _vm._v("Sample Lead Days")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("span", [_vm._v("Min")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.sample.min,
                          expression: "sample.min",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control mr-2",
                      attrs: { type: "number" },
                      domProps: { value: _vm.sample.min },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.sample,
                            "min",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Days")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("span", [_vm._v("Max")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.sample.max,
                          expression: "sample.max",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control mr-2",
                      attrs: { type: "number" },
                      domProps: { value: _vm.sample.max },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.sample,
                            "max",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Days")])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", {}, [
            _c("div", { staticClass: "text-darker text-uppercase" }, [
              _vm._v("Production Lead Days")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("span", [_vm._v("Min")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.production.min,
                          expression: "production.min",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control mr-2",
                      attrs: { type: "number" },
                      domProps: { value: _vm.production.min },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.production,
                            "min",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Days")])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-6" }, [
                  _c("span", [_vm._v("Max")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.production.max,
                          expression: "production.max",
                          modifiers: { number: true }
                        }
                      ],
                      staticClass: "form-control mr-2",
                      attrs: { type: "number" },
                      domProps: { value: _vm.production.max },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.production,
                            "max",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", [_vm._v("Days")])
                  ])
                ])
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("p", [_vm._v("PRICES")]),
          _vm._v(" "),
          _vm.hasQuoteQuantities
            ? _c(
                "div",
                _vm._l(_vm.quoteQuantities, function(quoteQuantity) {
                  return _c(
                    "div",
                    { key: quoteQuantity.id, staticClass: "mb-2" },
                    [
                      _c("ValidationProvider", {
                        attrs: {
                          name: "Price for " + quoteQuantity.quantity,
                          rules: "required",
                          tag: "div"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var errors = ref.errors
                                var validate = ref.validate
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "row justify-content-between align-items-center"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-uppercase col-5" },
                                        [_vm._v(_vm._s(quoteQuantity.quantity))]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "input-group col-7" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "input-group-prepend"
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "input-group-text"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(_vm.currencySymbol)
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "number",
                                              step: "0.01"
                                            },
                                            domProps: {
                                              value:
                                                _vm.prices[
                                                  quoteQuantity.quantity
                                                ]
                                            },
                                            on: {
                                              input: function($event) {
                                                return _vm.setPrice(
                                                  quoteQuantity.quantity,
                                                  $event.target.value
                                                )
                                              },
                                              change: validate
                                            }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "invalid-feedback d-block" },
                                    [_vm._v(_vm._s(errors[0]))]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                [
                  _c("ValidationProvider", {
                    staticClass: "mb-2",
                    attrs: { name: "Quantity", rules: "required", tag: "div" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          var validate = ref.validate
                          return [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row justify-content-between align-items-center"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "text-uppercase col-5" },
                                  [_vm._v(_vm._s(_vm.quantity))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "input-group col-7" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "input-group-prepend" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "input-group-text" },
                                          [_vm._v(_vm._s(_vm.currencySymbol))]
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("input", {
                                      staticClass: "form-control",
                                      attrs: { type: "number", step: "0.01" },
                                      domProps: {
                                        value: _vm.prices[_vm.quantity]
                                      },
                                      on: {
                                        input: function($event) {
                                          return _vm.setPrice(
                                            _vm.quantity,
                                            $event.target.value
                                          )
                                        },
                                        change: validate
                                      }
                                    })
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "invalid-feedback d-block" },
                              [_vm._v(_vm._s(errors[0]))]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "mb-2" }, [
            _c("div", { staticClass: "text-darker text-uppercase" }, [
              _vm._v("Notes")
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-4" }, [
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.note,
                    expression: "note"
                  }
                ],
                staticClass: "form-control",
                attrs: { cols: "30", rows: "3" },
                domProps: { value: _vm.note },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.note = $event.target.value
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "form-check" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.submitQuote,
                    expression: "submitQuote"
                  }
                ],
                staticClass: "form-check-input",
                attrs: { type: "checkbox", id: "submitQuote" },
                domProps: {
                  checked: Array.isArray(_vm.submitQuote)
                    ? _vm._i(_vm.submitQuote, null) > -1
                    : _vm.submitQuote
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.submitQuote,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.submitQuote = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.submitQuote = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.submitQuote = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "form-check-label",
                  attrs: { for: "submitQuote" }
                },
                [
                  _vm._v(
                    "\n                            Submit as final quote\n                        "
                  )
                ]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }, [
        _c("div", { staticClass: "d-flex justify-content-between" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light",
              attrs: { type: "button" },
              on: { click: _vm.cancel }
            },
            [_vm._v("Cancel")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: { type: "button" },
              on: { click: _vm.save }
            },
            [_vm._v("Save")]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }