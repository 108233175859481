<template>
    <div>
        <ValidationProvider
            v-for="(label, name) in files"
            :key="name"
            :name="label"
            v-slot="{ errors }"
            rules="required"
        >
            <div class="mb-3">
                <label>{{ label }}</label>
                <file-upload
                    :value="currentFile(name)"
                    @input="(value) => selectFile(value, name)"
                    type="image"
                    :class="{ 'is-invalid': errors.length > 0 }"
                ></file-upload>
                <div class="invalid-feedback" v-if="errors">{{ errors[0] }}</div>
            </div>
        </ValidationProvider>
    </div>
</template>

<script>
    import { pickBy } from 'lodash';
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            value: {
                type: Object,
                required: true
            },
            files: {
                type: Object,
                required: true
            }
        },

        methods: {
            currentFile(name) {
                return this.value[name] || '';
            },
            selectFile(file, type) {
                if (!file) {
                    return this.$emit('input', pickBy(this.value, (value, key) => key !== type));
                }

                this.$emit('input', { ...this.value, [type]: file });
            }
        }
    }
</script>
