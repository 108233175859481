<template>
    <div class="bg-dark p-3">
        <h3>Badge Details</h3>
        <div class="row mb-3">
            <div class="col">
                <div>Width</div>
                <div>{{ parseFloat(details.width) }} {{ details.unit }}</div>
            </div>
            <div class="col">
                <div>Height</div>
                <div>{{ parseFloat(details.height) }} {{ details.unit }}</div>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col" v-if="details.num_colours">
                <div>No. Colours</div>
                <div>{{ details.num_colours }}</div>
            </div>
            <div class="col" v-if="details.stitch_count">
                <div>Stitch Count</div>
                <div>{{ details.stitch_count }}</div>
            </div>
            <div class="col" v-if="details.trim_count">
                <div>Trim Count</div>
                <div>{{ details.trim_count }}</div>
            </div>
        </div>
        <div class="row mb-3" v-if="details.notes">
            <div class="col">
                <div>Notes</div>
                <div>{{ details.notes }}</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            details: {
                type: Object,
                required: true
            }
        }
    }
</script>
