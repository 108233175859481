<script>
    export default {
        props: {
            order: {
                type: Object,
                required: true
            }
        },

        render() {
            return this.$scopedSlots.default();
        },

        created() {
            this.$store.commit('orders/setOrder', this.order);
        }
    }
</script>
