import {schema} from "normalizr";

const fixedPriceSchema = new schema.Entity('fixedPrice');
const quotePriceSchema = new schema.Entity('quotePrice');

const attributeSizeBreakpointSchema = new schema.Entity('sizeBreakpoints',{
    option_price:  fixedPriceSchema,
    quote_price: quotePriceSchema
});

export default attributeSizeBreakpointSchema;
