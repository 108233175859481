<template>
    <div class="option-restrictions">
        <table class="table">
            <tbody>
                <restricted-option
                    v-for="(attribute, index) in restrictedAttributes"
                    :key="index"
                    :index="index"
                    :attribute="attribute"
                ></restricted-option>
            </tbody>
            <tfoot v-if="hasAttributes">
                <tr>
                    <td colspan="3">
                        <button type="button" class="btn btn-primary" @click="addAttribute">
                            <i class="fas fas-plus"></i> Add New
                        </button>
                    </td>
                </tr>
            </tfoot>
        </table>

        <div class="clearfix" v-if="restrictedAttributes.length">
            <button class="float-right btn btn-primary" @click.prevent="saveRestrictions">Save</button>
        </div>
    </div>
</template>

<script>
    import AttributeOptionsModule from '../../../store/modules/attribute-options';

    export default {
        props: {
            option: {
                type: Number
            },
            product: {
                type: String
            },
            restrictions: {
                type: Object
            }
        },

        created() {
            const store = this.$store;

            if (!(store && store.state && store.state.attributeOptions)) {
                store.registerModule('attributeOptions', AttributeOptionsModule);
            }

            this.$store.commit('attributeOptions/setCurrentOption', this.option);
            this.$store.dispatch('attributeOptions/initializeRestrictions', this.restrictions);
            this.$store.dispatch('attributeOptions/loadAttributes', this.product);
        },

        computed: {
            restrictedAttributes() {
                return this.$store.getters['attributeOptions/restrictedAttributes'];
            },
            hasAttributes() {
                let attributes = this.$store.getters['attributeOptions/attributes']();
                return attributes.length > 0;
            }
        },

        methods: {
            addAttribute() {
                this.$store.dispatch('attributeOptions/addNewAttribute');
            },
            async saveRestrictions() {
                try {
                    let result = await this.$store.dispatch('attributeOptions/saveRestrictions');
                } catch(e) {

                }
            }
        }
    }
</script>
