import currency from 'currency.js';

export default class CurrencyFormatter {
    constructor(value) {
        this.value = value;
    }

    format(formatSettings = { symbol: "£", precision: 2 }) {
        let poundValue = currency(this.value).divide(100);
        if (poundValue.intValue === 0) {
            return 'Free';
        }
        return currency(poundValue, formatSettings).format(true);
    }
}
