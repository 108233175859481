import {filter, intersection} from 'lodash';

export default {
    cleanup(options) {
        let withoutParents = this.removeIfParentMissing(options);
        let withoutRestricted = this.removeRestricted(withoutParents);

        let optionData = {};
        withoutRestricted.forEach(option => {
            optionData[option.attribute_id] = option.id;
        });
        return optionData;
    },
    removeIfParentMissing(options) {
        let optionIds = options.map(option => option.id);

        // Check if an option in the cart should have a parent
        // If the parent isnt present then remove
        return filter(options, (option) => {
            if (!option.parent_id) return true;

            return optionIds.indexOf(option.parent_id) > -1;
        });
    },
    removeRestricted(options) {
        let optionIds = options.map(option => option.id);

        return filter(options, option => {
            if (typeof option.restricted_by !== 'undefined') {
                return intersection(optionIds, option.restricted_by).length <= 0
            }
            return true;
        });
    }
}
