<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form ref="form" method="POST" class="d-flex flex-column w-100" @submit.prevent="handleSubmit(onSubmit)">

            <h3 class="mb-3">Add Supplier Quote Quantity</h3>

            <slot v-cloak>
                <ValidationProvider name="Quantity" rules="required" v-slot="{ errors }">
                    <div class="form-group">
                        <label for="quantity">Quantity</label>
                        <input id="quantity" type="number" class="form-control" name="quantity" v-model.number="quantity" :class="{ 'is-invalid': errors.length > 0 }">
                        <div class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>
            </slot>

            <div class="mt-3 d-flex justify-content-end">
                <button class="btn mr-2 btn btn-primary"  type="submit">Save</button>
                <button
                    class="btn btn--grey"
                    type="button"
                    @click="$emit('close')">
                    Cancel
                </button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        name: "ProductQuoteQuantityForm",

        components: {
            ValidationObserver,
            ValidationProvider
        },

        data() {
            return {
                quantity: '',
            }
        },

        methods: {
            async onSubmit() {
                let response = await this.$store.dispatch('product/quoteQuantities/create',{
                    quantity: this.quantity
                });

                this.$emit('saved');
            }
        }
    }
</script>
