import Vue from 'vue';
import {normalize} from "normalizr";
import attributeSchema from '../../schema/attributes';

const state = {
    activeOption: '',
    attributes: {},
    options: {},
    restrictedAttributes: [],
    restrictedOptions: {}
}

const getters = {
    attributes: state => currentAttribute => {
        return Object.values(state.attributes)
            .filter(attribute => attribute.options.indexOf(state.activeOption) === -1)
            .filter(attribute => {
                if (attribute.id === currentAttribute) return true;

                return state.restrictedAttributes.indexOf(attribute.id) === -1;
            });
    },
    options: state => {
        return Object.values(state.options);
    },
    attributeOptions: state => attributeId => {
        return Object.values(state.options).filter(option => option.attribute_id === attributeId);
    },
    restrictedAttributes: state => {
        return state.restrictedAttributes;
    },
    restrictedAttributeAtIndex: state => index => {
        return state.restrictedAttributes[index];
    },
    restrictedOptions: state => attributeId => {
        return state.restrictedOptions[attributeId] || [];
    }
}

const mutations = {
    setCurrentOption(state, option) {
        state.activeOption = option;
    },
    initializeRestrictedAttributes(state, attributes) {
        state.restrictedAttributes = attributes;
    },
    initializeRestrictedOptions(state, options) {
        state.restrictedOptions = Object.assign({}, options);
    },
    setAttributes(state, attributes) {
        state.attributes = Object.assign({}, attributes);
    },
    setOptions(state, options) {
        state.options = Object.assign({}, options);
    },
    setRestrictedAttribute(state, { index, attribute }) {
        state.restrictedAttributes.splice(index, 1, attribute);
    },
    resetRestrictedOptions(state, attributeId) {
        Vue.delete(state.restrictedOptions, attributeId);
    },
    setRestrictedOptions(state, { attribute, options }) {
        Vue.set(state.restrictedOptions, attribute, options);
    },
    addRestrictedAttribute(state, attribute) {
        state.restrictedAttributes.push(attribute);
    },
    removeRestrictedAttribute(state, index) {
        state.restrictedAttributes.splice(index, 1);
    }
}

const actions = {
    initializeRestrictions({ commit }, restrictions) {
        commit('initializeRestrictedOptions', restrictions);
        commit('initializeRestrictedAttributes', Object.keys(restrictions).map(attributeId => parseInt(attributeId)));
    },
    async loadAttributes({ commit }, productSlug) {
        let { data: { data: attributeData } } = await axios.get(route('api.products.attributes.index', productSlug));

        let { entities: { attributes, attributeOptions } } = normalize(attributeData, [attributeSchema]);

        commit('setAttributes', attributes);
        commit('setOptions', attributeOptions);
    },
    setRestrictedAttribute({ commit, getters }, { index, attribute }) {
        let oldAttribute = getters.restrictedAttributeAtIndex(index);
        commit('setRestrictedAttribute', { index, attribute });
        commit('resetRestrictedOptions', oldAttribute);
    },
    addNewAttribute({ commit, getters }) {
        let attribute = getters.attributes()[0];
        commit('addRestrictedAttribute', attribute.id);
    },
    removeAttribute({ commit }, { index, attribute }) {
        commit('removeRestrictedAttribute', index);
        commit('resetRestrictedOptions', attribute);
    },
    saveRestrictions({ commit, state }) {
        let options = [];
        Object.values(state.restrictedOptions).forEach(restrictedOptions => {
            options = [...options, ...restrictedOptions];
        });

        return axios.post(route('api.attribute-options.restrictions.store', state.activeOption), {
            options: options
        }).then(function (response) {
            //@TODO: Make this either LiveWire or have a proper flash and not an alert...
            alert('Saved.');
        });
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
