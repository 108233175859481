import BadgeProduct from './modules/ordering/badge/product';
import LanyardProduct from './modules/ordering/lanyard/product';
import BadgeOrder from './modules/ordering/badge/order';
import LanyardOrder from './modules/ordering/lanyard/order';

export default {
    productModules: {
        'badge': { product: BadgeProduct, order: BadgeOrder },
        'lanyard': { product: LanyardProduct, order: LanyardOrder }
    },
    load(productCategory, module) {
        return this.productModules[productCategory][module];
    }
}
