var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "payment-form" } }, [
    _c("div", { ref: "card", staticClass: "field empty" }),
    _vm._v(" "),
    _vm.errors ? _c("div", [_vm._v(_vm._s(_vm.errors))]) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }