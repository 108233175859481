import { pick, flatten, values } from 'lodash';

export default class FormDataBuilder {
    constructor() {
        this.formData = new FormData();
        this.customerFields = ['firstName', 'lastName', 'phoneNumber', 'email'];
    }

    add(formDataKey, data) {
        if (typeof data === 'object') {
            for(let [key, value] of Object.entries(data)) {
                this.formData.append(`${formDataKey}[${key}]`, value);
            }
        } else {
            this.formData.append(formDataKey, data);
        }

        return this;
    }

    /**
     * @deprecated
     * use artwork function instead
     */
    file(file, fieldName = 'artwork') {
        this.formData.append(fieldName, file);
        return this;
    }

    artwork(artworkFile) {
        this.add('artwork', JSON.stringify(artworkFile));

        return this;
    }

    supportingFiles(supportingFiles) {
        this.add('supporting_files', JSON.stringify(supportingFiles));

        return this;
    }

    attributes(attributes) {
        let optionIds = flatten(values(attributes));

        // Add the attributes
        Object.keys(optionIds).forEach((optionId, index) => {
            let option = optionIds[optionId];
            this.formData.append(`options[${index}]`, option);
        });

        return this;
    }

    nonVatable() {
        this.formData.append(`without_vat`, true);
    }

    debug() {
        for (var pair of this.formData.entries()) {
            console.log(pair[0]+ ', ' + pair[1]);
        }
    }

    debugValues() {
        for (var value of this.formData.values()) {
            console.log(value);
        }
    }
}
