var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    attrs: { slim: "" },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.onSubmit)
                  }
                }
              },
              [
                _c("h3", { staticClass: "mb-3" }, [_vm._v("Reject Artwork")]),
                _vm._v(" "),
                _vm.error
                  ? _c("div", { staticClass: "alert alert-danger" }, [
                      _vm._v(_vm._s(_vm.error))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: { name: "status", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Return to Status")
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.status,
                                      expression: "status"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  class: { "is-invalid": errors.length > 0 },
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.status = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    }
                                  }
                                },
                                _vm._l(_vm.statuses, function(status, index) {
                                  return _c("option", {
                                    key: index,
                                    domProps: {
                                      value: status,
                                      textContent: _vm._s(status)
                                    }
                                  })
                                }),
                                0
                              ),
                              _vm._v(" "),
                              errors.length
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(errors[0]))]
                                  )
                                : _vm._e()
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "form-check" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.notify,
                        expression: "notify"
                      }
                    ],
                    staticClass: "form-check-input",
                    attrs: {
                      type: "checkbox",
                      value: "1",
                      id: "notify-digitizer"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.notify)
                        ? _vm._i(_vm.notify, "1") > -1
                        : _vm.notify
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.notify,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = "1",
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.notify = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.notify = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.notify = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "form-check-label",
                      attrs: { for: "notify-digitizer" }
                    },
                    [_vm._v("\n                Notify Digitizer\n            ")]
                  )
                ]),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: { name: "reason", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", { staticClass: "control-label" }, [
                                _vm._v("Reason")
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.reason,
                                    expression: "reason"
                                  }
                                ],
                                staticClass: "form-control",
                                class: { "is-invalid": errors.length > 0 },
                                attrs: { cols: "30", rows: "3" },
                                domProps: { value: _vm.reason },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.reason = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              errors.length
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [_vm._v(_vm._s(errors[0]))]
                                  )
                                : _vm._e()
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit", disabled: _vm.submitting }
                    },
                    [_vm._v("Submit")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button", disabled: _vm.submitting },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.cancel.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  )
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }