var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("ValidationProvider", {
          attrs: { name: "Courier", rules: "required" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                var validate = ref.validate
                return [
                  _c("div", { staticClass: "text-darker text-uppercase" }, [
                    _vm._v("Courier Name")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4" }, [
                    _c("input", {
                      staticClass: "form-control",
                      class: { "is-invalid": errors.length > 0 },
                      attrs: { type: "text", name: "courier_name" },
                      domProps: { value: _vm.value.courier_name },
                      on: {
                        change: validate,
                        input: function($event) {
                          return _vm.update("courier_name", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    errors.length
                      ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      : _vm._e()
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("ValidationProvider", {
          attrs: { name: "Tracking Number", rules: "required" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                var validate = ref.validate
                return [
                  _c("div", { staticClass: "text-darker text-uppercase" }, [
                    _vm._v("Courier Tracking Number")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4" }, [
                    _c("input", {
                      staticClass: "form-control",
                      class: { "is-invalid": errors.length > 0 },
                      attrs: { type: "text", name: "reference" },
                      domProps: { value: _vm.value.reference },
                      on: {
                        change: validate,
                        input: function($event) {
                          return _vm.update("reference", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    errors.length
                      ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      : _vm._e()
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("ValidationProvider", {
          attrs: { name: "Tracking Url", rules: "required" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                var validate = ref.validate
                return [
                  _c("div", { staticClass: "text-darker text-uppercase" }, [
                    _vm._v("Courier Tracking Url")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "mb-4" }, [
                    _c("input", {
                      staticClass: "form-control",
                      class: { "is-invalid": errors.length > 0 },
                      attrs: { type: "text", name: "url" },
                      domProps: { value: _vm.value.url },
                      on: {
                        change: validate,
                        input: function($event) {
                          return _vm.update("url", $event.target.value)
                        }
                      }
                    }),
                    _vm._v(" "),
                    errors.length
                      ? _c("div", { staticClass: "invalid-feedback d-block" }, [
                          _vm._v(_vm._s(errors[0]))
                        ])
                      : _vm._e()
                  ])
                ]
              }
            }
          ])
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-6" },
      [
        _c("ValidationProvider", {
          attrs: { name: "Delivery Date", rules: "required" },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                var validate = ref.validate
                return [
                  _c("div", { staticClass: "text-darker text-uppercase" }, [
                    _vm._v("Delivery Date")
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("date-time-picker", {
                        attrs: {
                          id: "delivery_date",
                          name: "delivery_date",
                          value: _vm.value.delivery_date
                        },
                        on: {
                          "date-selected": function(value) {
                            validate()
                            _vm.update("delivery_date", value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors.length
                        ? _c(
                            "div",
                            { staticClass: "invalid-feedback d-block" },
                            [_vm._v(_vm._s(errors[0]))]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ])
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }