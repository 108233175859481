var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "summary-item" }, [
    _c("div", { staticClass: "summary-item-label" }, [
      _vm._v(_vm._s(_vm.attribute.name))
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "summary-item-options" },
      _vm._l(_vm.selectedOptions, function(option) {
        return _c(
          "div",
          { key: option.id, staticClass: "summary-item-value" },
          [
            _c("div", { staticClass: "summary-value-label" }, [
              _vm._v(_vm._s(option.label))
            ])
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }