<template>
    <div class="attribute-option-price">
        <input type="number" :value="formattedPrice" @input="setPrice($event.target.value)">
    </div>
</template>

<script>
    export default {
        name: 'AttributeOptionPrice',

        props: ['sizeBreakpoint', 'attributeOption'],

        computed: {
            price() {
                return this.$store.getters['products/attributeQuotePrices/fetchPrice'](this.attributeOption, this.sizeBreakpoint);;
            },
            formattedPrice() {
                return (this.price) ? (this.price.price / 100).toFixed(2) : 0;
            }
        },

        methods: {
            setPrice(value) {
                this.$store.commit('products/attributeQuotePrices/updatePrice',{
                    attribute_option_id: this.attributeOption,
                    size_breakpoint_id: this.sizeBreakpoint,
                    price: parseFloat(value)
                });
            }
        }
    }
</script>
