var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.selectedQuote,
            expression: "selectedQuote"
          }
        ],
        attrs: {
          name: "quote",
          type: "radio",
          disabled: !_vm.hasBeenSubmitted
        },
        domProps: {
          value: _vm.quote.id,
          checked: _vm._q(_vm.selectedQuote, _vm.quote.id)
        },
        on: {
          change: [
            function($event) {
              _vm.selectedQuote = _vm.quote.id
            },
            _vm.selectQuote
          ]
        }
      })
    ]),
    _vm._v(" "),
    _c("td", [
      _vm._v("\n        " + _vm._s(_vm.quote.company_name) + "\n        "),
      _vm.quote.has_submitted
        ? _c("div", [
            _c("small", [
              _vm._v(
                "(Sample Lead Times: " +
                  _vm._s(_vm.quote.sample_lead_display) +
                  ")"
              )
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.quote.has_submitted
        ? _c("div", [
            _c("small", [
              _vm._v(
                "(Production Lead Times: " +
                  _vm._s(_vm.quote.production_lead_display) +
                  ")"
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("td", [
      _c("div", { staticClass: "font-weight-bold" }, [
        _vm._v(
          _vm._s(_vm.currencySymbol) +
            _vm._s(_vm._f("toPounds")(_vm.quote.total))
        )
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "(" +
            _vm._s(_vm.currencySymbol) +
            _vm._s(_vm._f("toPounds")(_vm.quote.unit_cost)) +
            " per Badge)"
        )
      ])
    ]),
    _vm._v(" "),
    _vm.showMargin
      ? _c("td", [
          _c("div", { staticClass: "input-group input-group-sm" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.customerMargin,
                  expression: "customerMargin",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "number" },
              domProps: { value: _vm.customerMargin },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.customerMargin = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm._f("toPounds")(_vm.subTotal)))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.quote.status))]),
    _vm._v(" "),
    _c("td", [_vm._v(_vm._s(_vm.quote.submitter_name))]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.showForm = true
              }
            }
          },
          [_vm._v("Edit")]
        ),
        _vm._v(" "),
        _c(
          "modal",
          { attrs: { show: _vm.showForm, width: "lg" } },
          [
            _c("supplier-quote-form", {
              attrs: { quote: _vm.quote },
              on: {
                cancel: function($event) {
                  _vm.showForm = false
                },
                saved: function($event) {
                  _vm.showForm = false
                }
              }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }