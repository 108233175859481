var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedAttribute,
              expression: "selectedAttribute"
            }
          ],
          staticClass: "form-control",
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedAttribute = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        _vm._l(_vm.attributes, function(attribute) {
          return _c("option", {
            key: attribute.id,
            domProps: {
              value: attribute.id,
              textContent: _vm._s(attribute.name)
            }
          })
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c(
      "td",
      _vm._l(_vm.attributeOptions, function(option) {
        return _c("div", { staticClass: "form-check" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedOptions,
                expression: "selectedOptions"
              }
            ],
            staticClass: "form-check-input",
            attrs: { type: "checkbox", id: "attributeOption" + option.id },
            domProps: {
              value: option.id,
              checked: Array.isArray(_vm.selectedOptions)
                ? _vm._i(_vm.selectedOptions, option.id) > -1
                : _vm.selectedOptions
            },
            on: {
              change: function($event) {
                var $$a = _vm.selectedOptions,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = option.id,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.selectedOptions = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.selectedOptions = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.selectedOptions = $$c
                }
              }
            }
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "attributeOption" + option.id }
            },
            [_vm._v(_vm._s(option.label))]
          )
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("td", [
      _c(
        "a",
        {
          staticClass: "btn btn-danger",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.removeAttribute.apply(null, arguments)
            }
          }
        },
        [_vm._v("Remove")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }