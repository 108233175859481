<template>
    <div class="file-upload">
        <div class="file-upload__field d-flex justify-content-between">
            <input ref="file" type="file" @change="handleImage">
            <button v-if="value" type="button" class="btn btn-link" @click.prevent="reset"><i class="fas fa-times"></i></button>
        </div>
        <div class="file-upload__preview" v-if="showPreview">
            <img class="img-fluid" :src="imageData">
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            type: {
                type: String,
                default: 'file'
            },
            value: false
        },

        data() {
            return {
                isImage: false,
                fileType: '',
                imageData: ''
            }
        },

        computed: {
            showPreview() {
                if (this.type !== 'image') return false;

                return this.isImage && this.imageData.length > 0;
            }
        },

        methods: {
            handleImage(event) {
                let input = event.target;

                if (input.files && input.files[0]) {
                    let [file] = input.files;

                    this.getFileHeader(file);
                    this.renderPreview(file);

                    this.$emit('input', file);
                } else {
                   this.reset();
                }
            },
            getFileHeader(file) {
                let fileReader = new FileReader();
                fileReader.onloadend = (e) => {
                    let arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                    let header = "";
                    for (var i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16);
                    }
                    this.mimeType(header);
                };
                fileReader.readAsArrayBuffer(file);
            },
            renderPreview(file) {
                let reader = new FileReader();
                reader.onloadend = (e) => {
                    this.imageData = e.target.result;
                }
                reader.readAsDataURL(file);
            },
            mimeType(headerString) {
                switch (headerString) {
                    case "89504e47":
                        this.fileType = "image/png";
                        this.isImage = true;
                        break;
                    case "47494638":
                        this.fileType = "image/gif";
                        this.isImage = true;
                        break;
                    case "ffd8ffe0":
                    case "ffd8ffe1":
                    case "ffd8ffe2":
                        this.fileType = "image/jpeg";
                        this.isImage = true;
                        break;
                    default:
                        this.fileType = "unknown";
                        this.isImage = false;
                        break;
                }
            },
            reset() {
                this.$refs.file.value = '';
                this.isImage = false;
                this.fileType = '';
                this.imageData = '';
                this.$emit('input', null);
            }
        }
    }
</script>
