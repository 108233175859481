var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "table table-striped" }, [
    _c("thead", [
      _c(
        "tr",
        [
          _c("th"),
          _vm._v(" "),
          _vm._l(_vm.sizeBreakpoints, function(breakpoint) {
            return _c("th", { key: breakpoint.id }, [
              _vm._v(
                "\n                " +
                  _vm._s(breakpoint.label) +
                  "\n            "
              )
            ])
          })
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.options, function(option) {
        return _c(
          "tr",
          { key: option.id },
          [
            _c("td", [_vm._v(_vm._s(option.label))]),
            _vm._v(" "),
            _vm._l(_vm.sizeBreakpoints, function(breakpoint) {
              return _c(
                "td",
                { key: breakpoint.id },
                [
                  _c("attribute-option-price", {
                    attrs: {
                      "attribute-option": option.id,
                      "size-breakpoint": breakpoint.id
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }