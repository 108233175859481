var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "customer-notes" }, [
    _c(
      "div",
      { ref: "notesContainer", staticClass: "customer-notes-list" },
      _vm._l(_vm.notes, function(note) {
        return _c(
          "div",
          { key: note.id, staticClass: "customer-note mb-3 pb-3" },
          [
            _c("div", { staticClass: "note mb-2 text-darker" }, [
              _vm._v(_vm._s(note.body))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-between" }, [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(note.created_by))
              ]),
              _vm._v(" "),
              _c("div", [_vm._v(_vm._s(note.created_at))])
            ])
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "customer-notes-form mt-5" }, [
      _vm.validationErrors.length > 0
        ? _c(
            "div",
            { staticClass: "alert alert-danger" },
            _vm._l(_vm.validationErrors, function(error, index) {
              return _c("div", {
                key: index,
                domProps: { textContent: _vm._s(error) }
              })
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [_vm._v("Add Note")]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.body,
            expression: "body"
          }
        ],
        staticClass: "form-control mb-2",
        attrs: { rows: "5" },
        domProps: { value: _vm.body },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.body = $event.target.value
          }
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-end" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.submitting },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              }
            }
          },
          [_vm._v("Add Note")]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }