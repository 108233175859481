var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm.quantitiesList.length
            ? _vm._l(_vm.quantitiesList, function(quantity) {
                return _c("product-quote-quantities-item", {
                  key: quantity.id,
                  attrs: { item: quantity }
                })
              })
            : _c("tr", [
                _c("td", [_vm._v("No quote quantities attached.")]),
                _vm._v(" "),
                _c("td")
              ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 text-right" },
      [
        _c("add-product-quote-quantity", {
          attrs: {
            addButtonClass: "btn btn-primary",
            addButtonText: "Add Quantity"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "border-0" }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("th", { staticClass: "border-0" }, [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }