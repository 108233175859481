<template>
    <div>
        <button class="btn" :class="addButtonClass" v-text="addButtonText" @click="show = true"></button>

        <modal :show="show" @close="show = false">
            <product-quote-quantity-form
                @close="show = false"
                @saved="show = false"
            >
            </product-quote-quantity-form>
        </modal>
    </div>
</template>

<script>
    export default {
        name: 'AddProductQuoteQuantity',

        props: {
            addButtonClass: {
                type: String,
                default: 'btn btn-primary'
            },
            addButtonText: {
                type: String,
                default: 'Add Quantity'
            }
        },

        data() {
            return {
                show: false,
            };
        },
    }
</script>
