var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "file-upload" }, [
    _c(
      "div",
      { staticClass: "file-upload__field d-flex justify-content-between" },
      [
        _c("input", {
          ref: "file",
          attrs: { type: "file" },
          on: { change: _vm.handleImage }
        }),
        _vm._v(" "),
        _vm.value
          ? _c(
              "button",
              {
                staticClass: "btn btn-link",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.reset.apply(null, arguments)
                  }
                }
              },
              [_c("i", { staticClass: "fas fa-times" })]
            )
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _vm.showPreview
      ? _c("div", { staticClass: "file-upload__preview" }, [
          _c("img", { staticClass: "img-fluid", attrs: { src: _vm.imageData } })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }