<template>
    <div class="product-base-prices">
        <div class="flex flex-row items-center mb-2">
            <h3 class="flex-grow">Fixed Prices</h3>
            <div v-if="this.quoteCsvMedia"
                 class="flex flex-column items-center"
            >
                <a
                    class="btn btn-success p-2 m-2"
                    :href="downloadMediaUrl(this.quoteCsvMedia)"
                >
                    Download Quotes Data CSV
                </a>
                <span>
                    Generated at {{ this.quoteCsvMedia.created_at | formatDate }}
                </span>
            </div>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <edit-quantity-breakpoint
                        v-for="quantity in quantities"
                        :key="quantity.id"
                        :quantity-id="quantity.id"
                    ></edit-quantity-breakpoint>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="size in sizes"
                    :key="size.id"
                >
                    <edit-size-breakpoint
                        :size-id="size.id"
                    ></edit-size-breakpoint>
                    <fixed-price
                        v-for="quantity in quantities"
                        :key="quantity.id"
                        :quantity-id="quantity.id"
                        :size-id="size.id"
                    ></fixed-price>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-between my-2">
            <div class="creation-actions">
                <add-quantity-breakpoint></add-quantity-breakpoint>
                <add-size-breakpoint></add-size-breakpoint>
            </div>
            <div class="price-actions" v-if="hasChanges">
                <button type="button" class="btn btn-link" @click.prevent="cancelChanges">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="saveChanges">Save Changes</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import FixedPricesModule from '../../store/modules/product/fixed-prices';
    import AddQuantityBreakpoint from "./fixed-prices/AddQuantityBreakpoint";
    import EditSizeBreakpoint from "./fixed-prices/EditSizeBreakpoint";

    export default {
        name: 'ProductFixedPrices',
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        components: {EditSizeBreakpoint, AddQuantityBreakpoint},
        computed: {
            ...mapGetters('product/fixedPrices',{
                quantities: 'getQuantities',
                sizes: 'getSizes',
                hasChanges: 'hasChanges'
            }),
            quoteCsvMedia() {
                let filtered = this.product.media.filter((mediaFile) => {
                    if (mediaFile.collection_name === 'quote-data-csv') {
                        return mediaFile;
                    }
                });

                return filtered[0];
            }
        },

        beforeCreate() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.product.fixedPrices)) {
                store.registerModule(['product', 'fixedPrices'], FixedPricesModule);
            }

            this.$store.dispatch('product/fixedPrices/loadData');
        },

        methods: {
            cancelChanges() {
                this.$store.commit('product/fixedPrices/cancelChanges');
            },
            saveChanges() {
                this.$store.dispatch('product/fixedPrices/saveChanges');
            },
            downloadMediaUrl(media) {
                return route('download-media.show', [media]);
            },
        }
    }
</script>
