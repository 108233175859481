var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("ValidationProvider", {
            attrs: { name: "street address", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Street Address")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        class: { "is-invalid": errors.length > 0 },
                        attrs: { type: "text" },
                        domProps: { value: _vm.address.address_1 },
                        on: {
                          input: function($event) {
                            return _vm.update("address_1", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors.length
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("ValidationProvider", {
            attrs: { name: "county", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("County")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        class: { "is-invalid": errors.length > 0 },
                        attrs: { type: "text" },
                        domProps: { value: _vm.address.county },
                        on: {
                          input: function($event) {
                            return _vm.update("county", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors.length
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { staticClass: "control-label" }, [
                _vm._v("VAT Number")
              ]),
              _vm._v(" "),
              _c("vat-number", {
                attrs: { value: _vm.address.vat_number },
                on: {
                  input: function(value) {
                    return _vm.update("vat_number", value)
                  }
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("ValidationProvider", {
            attrs: { name: "town", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Town/City")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        class: { "is-invalid": errors.length > 0 },
                        attrs: { type: "text" },
                        domProps: { value: _vm.address.town },
                        on: {
                          input: function($event) {
                            return _vm.update("town", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors.length
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("ValidationProvider", {
            attrs: { name: "postcode", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Postcode")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        staticClass: "form-control",
                        class: { "is-invalid": errors.length > 0 },
                        attrs: { type: "text" },
                        domProps: { value: _vm.address.postcode },
                        on: {
                          input: function($event) {
                            return _vm.update("postcode", $event.target.value)
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors.length
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "control-label" }, [
              _vm._v("PO Number")
            ]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: { type: "text" },
              domProps: { value: _vm.address.po_number },
              on: {
                input: function($event) {
                  return _vm.update("po_number", $event.target.value)
                }
              }
            })
          ])
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }