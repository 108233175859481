<template>
    <input
        type="text"
        class="form-control"
        v-model="vatNumber"
        placeholder="VAT Number"
        @blur="check"
    />
</template>

<script>
    export default {
        name: 'VatNumber',

        props: {
            value: {
                type: String
            }
        },

        computed: {
            vatNumber: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },

        methods: {
            check() {
                this.$store.dispatch('ordering/customer/checkVat');
            }
        }
    }
</script>
