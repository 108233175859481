<template>
    <div>
        <table class="table">
            <thead>
            <tr>
                <th class="border-0">Quantity</th>
                <th class="border-0">Actions</th>
            </tr>
            </thead>
            <tbody>
                <template v-if="quantitiesList.length">
                    <product-quote-quantities-item
                        v-for="quantity in quantitiesList"
                        :key="quantity.id"
                        :item="quantity"
                    >
                    </product-quote-quantities-item>
                </template>
                <tr v-else>
                    <td>No quote quantities attached.</td>
                    <td></td>
                </tr>
            </tbody>
        </table>

        <div class="col-12 text-right">
            <add-product-quote-quantity
                addButtonClass="btn btn-primary"
                addButtonText="Add Quantity">
            </add-product-quote-quantity>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import QuoteQuantitesModule from '../../../store/modules/product/quote-quantities';

    export default {
        name: "ProductQuoteQuantities",

        props: {
            product: {
                type: String,
                required: true,
            }
        },

        computed: {
            ...mapGetters('product/quoteQuantities',[
                'quantitiesList'
            ])
        },

        beforeCreate() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.product.quoteQuantities)) {
                store.registerModule(['product', 'quoteQuantities'], QuoteQuantitesModule);
            }

            this.$store.dispatch('product/quoteQuantities/loadOptions');
        }
    }
</script>
