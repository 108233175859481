<template>
    <div>
        <div>{{ attribute.name }}</div>
        <component
            :is="component"
            :attribute="attribute"
        ></component>
    </div>
</template>

<script>
    export default {
        props: {
            attribute: {
                type: Object,
                required: true
            }
        },

        computed: {
            component() {
                return (this.attribute.type === 'multi-select') ? 'MultiSelect' : 'SingleSelect';
            }
        }
    }
</script>
