var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm.sampleTypeList.length
            ? _vm._l(_vm.sampleTypeList, function(sampleType) {
                return _c("product-sample-type-item", {
                  key: sampleType.id,
                  attrs: { "sample-type": sampleType }
                })
              })
            : _c("tr", [
                _c("td", [_vm._v("No sample types attached.")]),
                _vm._v(" "),
                _c("td")
              ])
        ],
        2
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12 text-right" },
      [
        _c("add-sample-type-option", {
          attrs: {
            addButtonClass: "btn btn-primary",
            addButtonText: "Add Approval Option"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "border-0" }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { staticClass: "border-0" }, [_vm._v("Description")]),
        _vm._v(" "),
        _c("th", { staticClass: "border-0" }, [_vm._v("Price")]),
        _vm._v(" "),
        _c("th", { staticClass: "border-0" }, [_vm._v("Actions")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }