<template>
    <th>
        <a href="#" @click.prevent="show = true">{{ quantityBreakpoint.label }}</a>
        <modal :show="show" @close="closeModal">
            <quantity-breakpoint-form
                :breakpoint="quantityBreakpoint"
                @saved="closeModal"
                @deleted="closeModal"
            ></quantity-breakpoint-form>
        </modal>
    </th>
</template>

<script>
    export default {
        name: 'EditQuantityBreakpoint',

        props: {
            quantityId: {
                type: Number,
                required: true
            }
        },

        data() {
            return {
                show: false
            }
        },

        computed: {
            quantityBreakpoint() {
                return this.$store.getters['product/fixedPrices/fetchQuantity'](this.quantityId);
            }
        },

        methods: {
            closeModal() {
                this.show = false;
            }
        }
    }
</script>
