import Vue from 'vue';
import {normalize} from "normalizr";
import quoteQuantitySchema from "../../schema/quote-quantities";

const state = {
    items: {}
};

const getters = {
    quantitiesList: (state) => {
        return Object.values(state.items);
    }
};

const mutations = {
    setItems(state, items) {
        state.items = Object.assign({}, items);
    },
    saveItem(state, item) {
        Vue.set(state.items, item.id, item);
    },
    removeItem(state, itemId) {
        Vue.delete(state.items, itemId);
    }
};

const actions = {
    loadOptions({ commit, rootState }) {
        let productSlug = rootState.product.slug;

        return axios.get(route('api.products.quote-quantity.index', productSlug))
            .then(({ data }) => {
                let { entities: { quoteQuantities } } = normalize(data.data, [quoteQuantitySchema]);

                commit('setItems', quoteQuantities);
            })
    },
    create({ commit, rootState }, data) {
        let slug = rootState.product.slug;

        return axios.post(route('api.products.quote-quantity.store', [slug]), data)
            .then(({ data }) => {
                commit('saveItem', data.data);
            });
    },
    update({ commit, rootState }, { id, ...data }) {
        let slug = rootState.product.slug;

        return axios.put(route('api.products.quote-quantity.update', [slug, id]), data)
            .then(({ data }) => {
                commit('saveItem', data.data);
            });
    },
    deleteItem({ commit, rootState }, approvalId) {
        let slug = rootState.product.slug;

        return axios.delete(route('api.products.quote-quantity.destroy', [slug, approvalId]))
            .then(() => {
                commit('removeItem', approvalId);
            });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
