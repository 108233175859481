var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "list-unstyled" },
    _vm._l(_vm.questions, function(question, index) {
      return _c(
        "li",
        { key: question.id },
        [
          _c("ValidationProvider", {
            attrs: { name: question.body, rules: "required", slim: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(ref) {
                    var errors = ref.errors
                    return [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox mb-3" },
                        [
                          _c("input", {
                            staticClass: "custom-control-input",
                            class: { "is-invalid": errors.length > 0 },
                            attrs: {
                              type: "checkbox",
                              id: "question" + question.id
                            },
                            domProps: { value: _vm.questionValue(question.id) },
                            on: {
                              input: function($event) {
                                return _vm.toggleApproval(question.id)
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label",
                              attrs: { for: "question" + question.id }
                            },
                            [_vm._v(_vm._s(question.body))]
                          ),
                          _vm._v(" "),
                          errors.length
                            ? _c("div", { staticClass: "invalid-feedback" }, [
                                _vm._v("This field is required")
                              ])
                            : _vm._e()
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }