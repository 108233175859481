<template>
    <div class="permissions-form">
        <div class="col-12">
            <div class="bg-white p-5 mb-4">
                <h3 class="mb-3"><slot>Permissions</slot></h3>
                <PermissionSelectionList
                    class="row"
                    :permissions="permissions"
                    :defaultPermissions="role.default_permissions"
                    @input="(permissions) => selectedPermissions = permissions">
                </PermissionSelectionList>
            </div>
        </div>

        <div class="col-12 text-right">
            <a :href="backUrl" class="btn btn-danger mr-2" @click="cancel()">Cancel</a>
            <button type="submit" class="btn btn-success" @click="save()">Save</button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        role: {
            type: Object,
            required: true,
        },

        permissions: {
            type: Array,
            default() {
                return [];
            }
        },
    },

    data() {
        return {
            selectedPermissions: {},
        }
    },

    computed: {
        backUrl() {
            return route('roles.index', { role: this.role.id });
        }
    },

    methods: {
        /**
         * Cancel the changes.
         */
        cancel() {
            //
        },

        /**
         * Saves the latest changes.
         */
        save() {
            //
        },
    },
}
</script>
