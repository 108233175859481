<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">

            <h3 class="mb-3">Add New Delivery Option</h3>

            <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
                <div class="form-group">
                    <label>Name</label>
                    <input class="form-control" v-model="name" type="text" :class="{ 'is-invalid': errors.length > 0 }">
                    <span v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="form-group">
                <label>Description</label>
                <input class="form-control" v-model="description" type="text"/>
            </div>

            <div class="form-group">
                <label>Value</label>
                <div class="input-group">
                    <input type="number" v-model.number="value" class="form-control">
                    <select v-model="type" class="custom-select" id="inputGroupSelect02">
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage</option>
                    </select>
                </div>
            </div>

            <div class="form-group">
                <label>Base Delivery</label>
                <select v-model="base_delivery_id" class="form-control">
                    <option value="">- No Parent -</option>
                    <option
                        v-for="parentOption in deliveryOptions"
                        :key="parentOption.id"
                        :value="parentOption.id"
                        v-text="parentOption.name"
                    ></option>
                </select>
            </div>

            <button class="btn btn-primary" type="submit">Submit</button>
        </form>
    </ValidationObserver>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        name: 'DeliveryForm',

        components: {
            ValidationObserver,
            ValidationProvider
        },

        data() {
            return {
                name: '',
                description: '',
                value: '',
                type: 'percentage',
                base_delivery_id: ''
            }
        },

        computed: {
            ...mapGetters('product/delivery',[
                'deliveryOptions'
            ])
        },

        methods: {
            async onSubmit() {
                let dimension = await this.$store.dispatch('product/delivery/createOption',{
                    name: this.name,
                    description: this.description,
                    value: this.value,
                    type: this.type,
                    base_delivery_id: this.base_delivery_id
                });

                this.$emit('saved');
            }
        }
    }
</script>
