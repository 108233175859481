<template>
    <div class="price-field position-relative flex-1">
        <div class="position-absolute d-flex align-items-center inset-y-0 pl-2">
            <span v-text="symbol"></span>
        </div>
        <input type="number" class="form-control flex-1 pl-4" v-model.number="inputValue"/>
    </div>
</template>

<script>
    export default {
        name: 'PriceField',

        props: {
            value: {
                type: Number,
                required: true
            },
            symbol: {
                type: String,
                default: '£'
            }
        },

        computed: {
            inputValue: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        }
    }
</script>
