import Vue from 'vue';
import {normalize} from "normalizr";
import {find, has, isEmpty} from "lodash";
import fixedPriceSchema from '../../schema/fixed-prices';
import quantitiesSchema from "../../schema/product-quantities";
import sizeBreakpointSchema from "../../schema/size-breakpoints";

const state = {
    sizes: {},
    quantities: {},
    prices: {},
    changes: {}
};

const getters = {
    getQuantities: (state) => {
        return Object.values(state.quantities)
            .sort((a, b) => a.quantity < b.quantity ? -1 : 1);
    },
    getSizes: (state) => {
        return Object.values(state.sizes)
            .sort((a, b) => a.size < b.size ? -1 : 1);
    },
    fetchQuantity: (state) => quantityId => {
        return state.quantities[quantityId];
    },
    fetchSizeBreakpoint: (state) => sizeId => {
        return state.sizes[sizeId];
    },
    fetchPrice: (state) => (productQuantityId, sizeBreakpointId) => {
        return find(Object.values(state.prices), price => {
            return (price.product_quantity_id === productQuantityId && price.size_breakpoint_id === sizeBreakpointId);
        });
    },
    hasChanges: (state) => {
        return !isEmpty(state.changes);
    },
    getChange: (state) => priceId => {
        return (has(state.changes, priceId)) ? parseFloat(state.changes[priceId]) : 0;
    }
};

const mutations = {
    setProductQuantities(state, quantities) {
        state.quantities = Object.assign({}, quantities);
    },
    setSizeBreakpoints(state, breakpoints) {
        state.sizes = Object.assign({}, breakpoints);
    },
    setPrices(state, prices) {
        state.prices = Object.assign({}, state.prices, prices);
    },
    saveQuantity(state, quantity) {
        Vue.set(state.quantities, quantity.id, quantity);
    },
    deleteQuantity(state, quantityId) {
        Vue.delete(state.quantities, quantityId);
    },
    saveSizeBreakpoint(state, sizeBreakpoint) {
        Vue.set(state.sizes, sizeBreakpoint.id, sizeBreakpoint);
    },
    deleteSizeBreakpoint(state, breakpointId) {
        Vue.delete(state.sizes, breakpointId);
    },
    addChange(state, { id, price }) {
        Vue.set(state.changes, id, price);
    },
    cancelChanges(state) {
        state.changes = Object.assign({});
    }
};

const actions = {
    loadData({ commit, rootState }) {
        let productSlug = rootState.product.slug;

        return axios.get(route('products.size-prices.index', productSlug))
            .then(({ data }) => {
                let { entities: { prices, sizeBreakpoints, productQuantities } } = normalize(data, [fixedPriceSchema]);

                commit('setProductQuantities', productQuantities);
                commit('setSizeBreakpoints', sizeBreakpoints);
                commit('setPrices', prices);
            });
    },
    saveQuantity({ dispatch, rootState}, { id, ...data }) {
        dispatch((id) ? 'updateQuantity' : 'createQuantity', { id, ...data });
    },
    createQuantity({ commit, rootState }, { id, ...data }) {
        let productSlug = rootState.product.slug;

        return axios.post(route('products.quantities.store', [productSlug]), data)
            .then(({ data }) => {
                let { entities: { prices } } = normalize(data, quantitiesSchema);

                commit('setPrices', prices);
                commit('saveQuantity', data);
            });
    },
    updateQuantity({ commit, rootState }, { id, ...data }) {
        let productSlug = rootState.product.slug;

        return axios.put(route('products.quantities.update', [productSlug, id]), data)
            .then(({ data }) => {
                commit('saveQuantity', data);
            });
    },
    deleteQuantity({ commit, rootState }, id) {
        let productSlug = rootState.product.slug;

        return axios.delete(route('products.quantities.destroy', [productSlug, id]))
            .then(response => {
                commit('deleteQuantity', id);
            });
    },
    saveSizeBreakpoint({ dispatch, rootState }, { id, ...data }) {
        dispatch((id) ? 'updateSizeBreakpoint' : 'createSizeBreakpoint', { id, ...data });
    },
    createSizeBreakpoint({ commit, rootState }, { id, ...data }) {
        let productSlug = rootState.product.slug;

        return axios.post(route('products.size-breakpoints.store', [productSlug]), data)
            .then(({ data }) => {
                let { entities: { prices } } = normalize(data, sizeBreakpointSchema);

                commit('setPrices', prices);
                commit('saveSizeBreakpoint', data);
            });
    },
    updateSizeBreakpoint({ commit, rootState }, { id, ...data }) {
        let productSlug = rootState.product.slug;

        return axios.put(route('products.size-breakpoints.update', [productSlug, id]), data)
            .then(({ data }) => {
                commit('saveSizeBreakpoint', data);
            });
    },
    deleteSizeBreakpoint({ commit, rootState }, id) {
        let productSlug = rootState.product.slug;

        return axios.delete(route('products.size-breakpoints.destroy', [productSlug, id]))
            .then(response => {
                commit('deleteSizeBreakpoint', id);
            });
    },
    saveChanges({ commit, rootState }) {
        let productSlug = rootState.product.slug;

        return axios.post(route('products.size-prices.store', productSlug), {
                prices: state.changes
            }).then(({ data }) => {
                let { entities: { prices } } = normalize(data, [fixedPriceSchema]);

                commit('setPrices', prices);
                commit('cancelChanges');
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
