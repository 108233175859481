<template>
    <span>
        <div class="input-group mb-3">
            <input type="number" v-model.number="value" step="0.01" class="form-control" :class="{ 'text-danger': hasFixedChange }"/>
            <div class="input-group-append">
                <select v-model="type" class="custom-select" id="inputGroupSelect01">
                    <option value="fixed">£</option>
                    <option value="percentage">%</option>
                </select>
            </div>
        </div>
    </span>
</template>

<script>
    export default {
        name: 'OptionFixedPrice',

        props: {
            priceId: {
                type: Number,
                required: true
            }
        },

        computed: {
            price() {
                return this.$store.getters['attributeOption/getFixedPrice'](this.priceId);
            },
            fixedChange() {
                return this.$store.getters['attributeOption/getFixedChange'](this.priceId);
            },
            hasFixedChange() {
                return !! this.fixedChange;
            },
            value: {
                get() {
                    if (this.fixedChange) {
                        return this.fixedChange[`${this.fixedChange.type}_price`];
                    }

                    return this.price.percentage_price > 0 ?
                        this.price.percentage_price : this.price.fixed_price.money.amount / 100;
                },
                set(value) {
                    let field = `${this.type}_price`;

                    this.$store.commit('attributeOption/addFixedChange',{
                        id: this.price.id,
                        [field]: value,
                        type: this.type
                    });
                }
            },
            type: {
                get() {
                    if (this.fixedChange) {
                        return this.fixedChange.type;
                    }
                    return this.price.percentage_price > 0 ? 'percentage' : 'fixed';
                },
                set(type) {
                    let field = `${type}_price`;

                    this.$store.commit('attributeOption/addFixedChange',{
                        id: this.price.id,
                        [field]: this.value,
                        type: type
                    });
                }
            }
        },
    }
</script>
