<template>
    <tr>
        <td>
            <span v-if="editing">
                <input type="text" class="form-control" v-model="name">
            </span>
            <p v-else>{{ sampleType.name }}</p>
        </td>
        <td>
            <span v-if="editing">
                <textarea class="form-control" v-model="description"></textarea>
            </span>
            <p v-else>{{ sampleType.description }}</p>
        </td>
        <td>
            <span v-if="editing">
                <input type="number" class="form-control" v-model.number="price">
            </span>
            <p v-else>{{ sampleType.unitCost | toPounds }}</p>
        </td>
        <td>
            <span v-if="editing">
                <button type="button" class="btn btn-link" @click.prevent="cancel">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="save">Save</button>
            </span>
            <span v-else>
                <a href="#" @click.prevent="editing = !editing">Edit</a>
                <a href="#" @click.prevent="deleteSampleType">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "ProductSampleTypeItem",

        props: {
            sampleType: {
                type: Object,
                required: true,
            },
        },

        data() {
            return {
                editing: false,
                name: this.sampleType.name,
                description: this.sampleType.description,
                price: this.sampleType.unitCost / 100,
            };
        },

        computed: {
            modified() {
                return this.sampleType.name != this.name ||
                    this.sampleType.unitCost / 100 != this.price ||
                    this.sampleType.description != this.description;
            }
        },

        methods: {
            cancel() {
                this.name = this.sampleType.name;
                this.description = this.sampleType.description;
                this.price = this.sampleType.unitCost / 100;
                this.editing = false;
            },

            /**
             * Updates the sampleType
             */
            async save() {
                let response = await this.$store.dispatch('product/sampleTypes/updateSampleType', {
                    id: this.sampleType.id,
                    name: this.name,
                    description: this.description,
                    price: this.price
                });

                this.editing = false;
            },

            /**
             * Deletes the specified sampleType.
             */
            async deleteSampleType() {
                let response = await this.$store.dispatch('product/sampleTypes/deleteSampleType', this.sampleType.id);
            },

        },
    }
</script>
