<template>
    <span>
        <a href="#" :class="buttonClass" @click.prevent="show = true" v-text="buttonText"></a>
        <modal :show="show" @close="show = false" :width="width">
            <form :action="url" :method="methodName" class="d-flex flex-column align-items-center w-100" enctype="multipart/form-data">
                <input type="hidden" name="_token" :value="csrfToken">
                <input v-if="isRestfulMethod" type="hidden" name="_method" :value="method">
                <slot v-cloak>
                    <div class="text-center">Are your sure you want to delete this record</div>
                </slot>

                <div class="mt-3 d-flex justify-content-between">
                    <button class="btn mr-2" v-if="confirmButtonText" :class="confirmButtonClass" v-text="confirmButtonText"></button>
                    <a href="#" class="btn btn--grey" @click.prevent="show = false" v-text="cancelButtonText"></a>
                </div>
            </form>
        </modal>
    </span>
</template>

<script>
    export default {
        name: 'FormConfirmation',

        props: {
            buttonText: {
                type: String,
                default: 'Delete'
            },
            buttonClass: {
                type: String,
                default: 'btn'
            },
            cancelButtonText: {
                default: 'Cancel'
            },
            confirmButtonText: {
                default: 'Delete'
            },
            confirmButtonClass: {
                type: String,
                default: 'btn btn-primary'
            },
            method: {
                type: String,
                default: 'POST'
            },
            url: {
                type: String,
                required: true
            },
            message: {
                type: String,
                default: 'Are you sure you want to delete this record?'
            },
            width: {
                type: String,
                default: 'sm'
            }
        },

        data() {
            return {
                csrfToken: document.querySelector("meta[name='csrf-token']").getAttribute("content"),
                show: false
            }
        },

        computed: {
            isRestfulMethod() {
                return !_.includes(['POST', 'GET'], this.method);
            },
            methodName() {
                return this.isRestfulMethod ? 'POST' : this.method;
            }
        }
    }
</script>
