import {filter, intersection} from 'lodash';

export default class OptionFilters {
    constructor(options) {
        this.options = options;
    }

    filter(cartOptionIds) {
        return filter(
            filter(this.options, (option) => this.removeRestricted(cartOptionIds, option)),
            (option) => this.showChildren(cartOptionIds, option)
        );
    }

    removeRestricted(selectedOptionIds, option) {
        let restrictedIds = option.restricted_by;
        if (typeof restrictedIds !== 'undefined') {
            return intersection(selectedOptionIds, restrictedIds).length <= 0;
        }
        return true;
    }

    showChildren(selectedOptionIds, option) {
        if (!option.parent_id) return true;

        return selectedOptionIds.indexOf(option.parent_id) > -1;
    }
}
