<template>
    <table class="table table-striped">
        <thead>
            <tr>
                <th></th>
                <th
                    v-for="breakpoint in sizeBreakpoints"
                    :key="breakpoint.id"
                >
                    {{ breakpoint.label }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                v-for="option in options"
                :key="option.id"
            >
                <td>{{ option.label }}</td>
                <td
                    v-for="breakpoint in sizeBreakpoints"
                    :key="breakpoint.id"
                >
                    <attribute-option-price
                        :attribute-option="option.id"
                        :size-breakpoint="breakpoint.id"
                    ></attribute-option-price>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
    export default {
        name: 'AttributeOptionPrices',

        props: ['attribute'],

        computed: {
            sizeBreakpoints() {
                return this.$store.getters['products/attributeQuotePrices/sizeBreakpoints'](this.attribute);
            },
            options() {
                return this.$store.getters['products/attributeQuotePrices/attributeOptions'](this.attribute);
            }
        }
    }
</script>
