const state = {
    id: '',
    name: '',
    slug: '',
    type: ''
};

const getters = {

};

const mutations = {
    setProductData(state, product) {
        state.slug = product.slug;
    }
};

const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
