var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "permissions-form" }, [
    _c("div", { staticClass: "col-12" }, [
      _c(
        "div",
        { staticClass: "bg-white p-5 mb-4" },
        [
          _c(
            "h3",
            { staticClass: "mb-3" },
            [
              _vm._t("default", function() {
                return [_vm._v("Permissions")]
              })
            ],
            2
          ),
          _vm._v(" "),
          _c("PermissionSelectionList", {
            staticClass: "row",
            attrs: {
              permissions: _vm.permissions,
              defaultPermissions: _vm.role.default_permissions
            },
            on: {
              input: function(permissions) {
                return (_vm.selectedPermissions = permissions)
              }
            }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-12 text-right" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-danger mr-2",
          attrs: { href: _vm.backUrl },
          on: {
            click: function($event) {
              return _vm.cancel()
            }
          }
        },
        [_vm._v("Cancel")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          attrs: { type: "submit" },
          on: {
            click: function($event) {
              return _vm.save()
            }
          }
        },
        [_vm._v("Save")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }