var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "quote-attributes" },
    _vm._l(_vm.attributesList, function(attribute) {
      return _c(
        "div",
        { key: attribute.id, staticClass: "d-flex align-items-center mb-3" },
        [
          _c("label", { staticClass: "quote-label mb-0 pr-5" }, [
            _vm._v(_vm._s(attribute.name))
          ]),
          _vm._v(" "),
          _c("quote-attribute-options", {
            attrs: { "attribute-id": attribute.id }
          })
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }