import { schema } from "normalizr";

const sizePriceSchema = new schema.Entity('sizePrices');

const optionSchema = new schema.Entity('options',{
    size_prices: [sizePriceSchema]
});

const sizeBreakpointsSchema = new schema.Entity('sizeBreakpoints');

const attributeSchema = new schema.Entity('attributes');

const attributeListSchema = new schema.Array(attributeSchema);
attributeSchema.define({
    options: [optionSchema],
    children: attributeListSchema,
    size_breakpoints: [sizeBreakpointsSchema]
});

export default attributeSchema;
