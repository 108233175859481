<template>
    <div>
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th
                        v-for="size in sizes"
                        :key="size.id"
                        v-text="size.label"
                    ></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Fixed Price</td>
                    <td
                        v-for="size in sizes"
                        :key="size.id"
                    >
                        <option-fixed-price
                            :price-id="size.option_price"
                        ></option-fixed-price>
                    </td>
                </tr>
                <tr>
                    <td>Quote Price</td>
                    <td
                        v-for="size in sizes"
                        :key="size.id"
                    >
                        <option-quote-price
                            :price-id="size.quote_price"
                        ></option-quote-price>
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="d-flex justify-content-end" v-if="hasChanges">
            <a class="btn btn-link mr-3" href="#" @click.prevent="cancelChanges">Cancel</a>
            <a class="btn btn-primary" href="#" @click.prevent="saveChanges">Save</a>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AttributeOptionModule from '../../../store/modules/product/attribute-options';
    import OptionFixedPrice from "./OptionFixedPrice";

    export default {
        name: 'OptionFixedPrices',
        components: {OptionFixedPrice},
        props: {
            optionId: {
                type: Number,
                required: true
            }
        },

        computed: {
            ...mapGetters('attributeOption',[
                'sizes',
                'hasChanges'
            ])
        },

        created() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.attributeOption)) {
                store.registerModule('attributeOption', AttributeOptionModule);
            }

            this.$store.dispatch('attributeOption/loadPrices', this.optionId);
        },

        methods: {
            cancelChanges() {
                this.$store.commit('attributeOption/cancelChanges');
            },
            saveChanges() {
                this.$store.dispatch('attributeOption/saveChanges');
            }
        }
    }
</script>
