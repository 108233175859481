import Vue from 'vue';
import {normalize} from 'normalizr';
import attributeSchema from '../../schema/attributes';

const state = {
    attributes: {},
    attributeOptions: {}
};

const getters = {
    attributeList(state) {
        return Object.values(state.attributes)
                .sort((a, b) => parseFloat(a.order) < parseFloat(b.order) ? -1 : 1);
    },
    optionsList: (state) => attributeId => {
        return Object.values(state.attributeOptions)
            .filter(option => option.attribute_id === attributeId);
    },
    getAttribute: (state) => attributeId => {
        return state.attributes[attributeId];
    }
};

const mutations = {
    setAttributes(state, attributes) {
        state.attributes = Object.assign({}, attributes);
    },
    setAttributeOptions(state, options) {
        state.attributeOptions = Object.assign({}, options);
    }
};

const actions = {
    loadAttributes({ commit, rootState }) {
        let slug = rootState.product.slug;

        return axios.get(route('products.attributes.index', slug))
                .then(({ data }) => {
                    let { entities: { attributes } } = normalize(data, [attributeSchema]);

                    commit('setAttributes', attributes);
                });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};