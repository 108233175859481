var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "option-restrictions" }, [
    _c("table", { staticClass: "table" }, [
      _c(
        "tbody",
        _vm._l(_vm.restrictedAttributes, function(attribute, index) {
          return _c("restricted-option", {
            key: index,
            attrs: { index: index, attribute: attribute }
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.hasAttributes
        ? _c("tfoot", [
            _c("tr", [
              _c("td", { attrs: { colspan: "3" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.addAttribute }
                  },
                  [
                    _c("i", { staticClass: "fas fas-plus" }),
                    _vm._v(" Add New\n                    ")
                  ]
                )
              ])
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.restrictedAttributes.length
      ? _c("div", { staticClass: "clearfix" }, [
          _c(
            "button",
            {
              staticClass: "float-right btn btn-primary",
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.saveRestrictions.apply(null, arguments)
                }
              }
            },
            [_vm._v("Save")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }