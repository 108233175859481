var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-success",
          attrs: { type: "button", disabled: _vm.disabled },
          on: { click: _vm.submit }
        },
        [_vm._v("Select Quote & Proceed")]
      ),
      _vm._v(" "),
      _vm.latestQuote
        ? _c(
            "modal",
            {
              attrs: { show: _vm.show, width: "md" },
              on: {
                close: function($event) {
                  _vm.show = false
                }
              }
            },
            [
              _c("h3", { staticClass: "mb-4" }, [
                _vm._v("Are you sure you want to continue?")
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4" }, [
                _c("div", { staticClass: "row mb-2" }, [
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", [_vm._v("Current Quote")]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "£" + _vm._s(_vm._f("toPounds")(_vm.latestQuote.total))
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", [_vm._v("New Quote")]),
                    _vm._v(" "),
                    _c("div", [_vm._v("£" + _vm._s(_vm.formattedNewTotal))])
                  ])
                ]),
                _vm._v(" "),
                _vm.moreExpensive
                  ? _c("div", [
                      _vm._v(
                        "\n                The new quote is more expensive than the existing quote. If you are happy to proceed then select continue, otherwise\n                you may need to cancel this order.\n            "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-between" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.show = false
                      }
                    }
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.$emit("submitted")
                      }
                    }
                  },
                  [_vm._v("Continue")]
                )
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }