<template>
    <ul class="list-unstyled">
        <li
            v-for="(question, index) in questions"
            :key="question.id"
        >
            <ValidationProvider :name="question.body" v-slot="{ errors }" rules="required" slim>
                <div class="custom-control custom-checkbox mb-3">
                    <input type="checkbox" class="custom-control-input" :id="`question${question.id}`"
                           :class="{ 'is-invalid': errors.length > 0 }"
                           :value="questionValue(question.id)"
                           @input="toggleApproval(question.id)"
                    >
                    <label class="custom-control-label" :for="`question${question.id}`">{{ question.body }}</label>
                    <div class="invalid-feedback" v-if="errors.length">This field is required</div>
                </div>
            </ValidationProvider>
        </li>
    </ul>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            value: {
                type: Array
            },
            questions: {
                type: Array
            }
        },

        methods: {
            hasApproval(questionId) {
                return this.value.indexOf(questionId) > -1;
            },
            questionValue(questionId) {
                return this.hasApproval(questionId) ? questionId : '';
            },
            toggleApproval(questionId) {
                let index = this.value.indexOf(questionId);
                if (index > -1) {
                    return this.$emit('input', this.value.filter(itemId => itemId !== questionId));
                }
                this.$emit('input', [...this.value, questionId]);
            }
        }
    }
</script>
