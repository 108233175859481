<template>
    <ValidationObserver ref="form" slim>
        <div class="supplier-quote-form">
            <h3>Edit Quote</h3>

            <div class="alert alert-danger" v-if="formError" v-text="formError"></div>
            <div class="alert alert-danger" v-if="validationErrors.length > 0">
                <li v-for="(validError, index) in validationErrors"
                    :key="index"
                    v-text="validError"
                ></li>
            </div>

            <div class="row">
                <div class="col-sm-6">
                    <div>
                        <div class="text-darker text-uppercase">Quote reference no.</div>
                        <div class="mb-4">
                            <input
                                type="text"
                                class="form-control"
                                name="supplier_reference"
                                v-model="supplier_reference"
                            />
                        </div>
                    </div>
                    <div class="">
                        <div class="text-darker text-uppercase">Sample Lead Days</div>
                        <div class="mb-4">
                            <div class="row">
                                <div class="col-sm-6">
                                    <span>Min</span>
                                    <div class="d-flex align-items-center">
                                        <input type="number" class="form-control mr-2" v-model.number="sample.min">
                                        <span>Days</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <span>Max</span>
                                    <div class="d-flex align-items-center">
                                        <input type="number" class="form-control mr-2" v-model.number="sample.max">
                                        <span>Days</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div class="text-darker text-uppercase">Production Lead Days</div>
                        <div class="mb-4">
                            <div class="row">
                                <div class="col-sm-6">
                                    <span>Min</span>
                                    <div class="d-flex align-items-center">
                                        <input type="number" class="form-control mr-2" v-model.number="production.min">
                                        <span>Days</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <span>Max</span>
                                    <div class="d-flex align-items-center">
                                        <input type="number" class="form-control mr-2" v-model.number="production.max">
                                        <span>Days</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <p>PRICES</p>

                    <div v-if="hasQuoteQuantities">
                        <div
                            v-for="quoteQuantity in quoteQuantities"
                            :key="quoteQuantity.id"
                            class="mb-2"
                        >
                            <ValidationProvider :name="`Price for ${quoteQuantity.quantity}`" v-slot="{ errors, validate }" rules="required" tag="div">
                                <div class="row justify-content-between align-items-center">
                                    <div class="text-uppercase col-5">{{ quoteQuantity.quantity }}</div>
                                    <div class="input-group col-7">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">{{ currencySymbol }}</span>
                                        </div>
                                        <input
                                            type="number"
                                            step="0.01"
                                            class="form-control"
                                            :value="prices[quoteQuantity.quantity]"
                                            @input="setPrice(quoteQuantity.quantity, $event.target.value)"
                                            @change="validate"
                                        >
                                    </div>
                                </div>
                                <div class="invalid-feedback d-block">{{ errors[0] }}</div>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div v-else>
                        <ValidationProvider name="Quantity" v-slot="{ errors, validate }" rules="required" class="mb-2" tag="div">
                            <div class="row justify-content-between align-items-center">
                                <div class="text-uppercase col-5">{{ quantity }}</div>
                                <div class="input-group col-7">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{ currencySymbol }}</span>
                                    </div>
                                    <input
                                        type="number"
                                        step="0.01"
                                        class="form-control"
                                        :value="prices[quantity]"
                                        @input="setPrice(quantity, $event.target.value)"
                                        @change="validate"
                                    >
                                </div>
                            </div>
                            <div class="invalid-feedback d-block">{{ errors[0] }}</div>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="mb-2">
                        <div class="text-darker text-uppercase">Notes</div>
                        <div class="mb-4">
                            <textarea
                                v-model="note"
                                class="form-control"
                                cols="30"
                                rows="3"
                            ></textarea>
                        </div>
                    </div>
                    <div>
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" v-model="submitQuote" id="submitQuote">
                            <label class="form-check-label" for="submitQuote">
                                Submit as final quote
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-3">
                <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-light" @click="cancel">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="save">Save</button>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>

<script>
    import { mapState, mapGetters } from 'vuex';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import { find } from 'lodash';
    import ValidationError from "../../../../errors/ValidationError";

    export default {
        props: {
            quote: {
                type: Object,
                default() {
                    return {

                    }
                }
            }
        },

        components: {
            ValidationObserver,
            ValidationProvider
        },

        data() {
            return {
                supplier_reference: this.quote.supplier_reference,
                sample: {
                    min: this.quote.lead_days_sample.min,
                    max: this.quote.lead_days_sample.max,
                },
                production: {
                    min: this.quote.lead_days_production.min,
                    max: this.quote.lead_days_production.max,
                },
                note: this.quote.note,
                currency: '',
                prices: {},
                submitQuote: false,
                validationErrors: [],
                formError: '',
            }
        },

        computed: {
            ...mapState('orders',[
                'quantity',
            ]),
            ...mapGetters('orders/quoting',[
                'currencies'
            ]),
            ...mapGetters('orders/quoting/supplierQuotes',[
                'hasQuoteQuantities',
                'quoteQuantities'
            ]),
            currencySymbol() {
                let currency = find(this.currencies, currency => currency.id === this.currency);
                return (currency) ? currency.symbol : '';
            }
        },

        mounted() {
           this.prices = this.$store.getters['orders/quoting/supplierQuotes/quotePrices'](this.quote.id);
           this.setInitialCurrency();
        },

        methods: {
            setInitialCurrency() {
                if (this.quote.currency_id) {
                    return this.currency = this.quote.currency_id;
                }

                let currency = find(this.currencies, currency => currency.is_default === 1);
                if (currency) {
                    return this.currency = currency.id;
                }
            },
            setPrice(quantity, price) {
                this.prices = { ...this.prices, [quantity]: price };
            },
            cancel() {
                this.$emit('cancel');
            },
            async save() {
                this.formError = '';
                let valid = await this.$refs.form.validate();
                if (!valid) return;

                try {
                    let result = await this.$store.dispatch('orders/quoting/supplierQuotes/updateQuote', {
                        id: this.quote.id,
                        supplier_reference: this.supplier_reference,
                        sample: this.sample,
                        production: this.production,
                        prices: this.prices,
                        currency_id: this.currency,
                        note: this.note,
                        submitQuote: this.submitQuote
                    });

                    this.$emit('saved');
                } catch (e) {
                    if (e instanceof ValidationError) {
                        return this.validationErrors = e.messages();
                    }
                    this.formError = e.message;
                }
            }
        }
    }
</script>
