var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "th",
    [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              _vm.show = true
            }
          }
        },
        [_vm._v(_vm._s(_vm.quantityBreakpoint.label))]
      ),
      _vm._v(" "),
      _c(
        "modal",
        { attrs: { show: _vm.show }, on: { close: _vm.closeModal } },
        [
          _c("quantity-breakpoint-form", {
            attrs: { breakpoint: _vm.quantityBreakpoint },
            on: { saved: _vm.closeModal, deleted: _vm.closeModal }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }