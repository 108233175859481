var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c("td", [
      _vm.editing
        ? _c("span", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.quantity,
                  expression: "quantity",
                  modifiers: { number: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "number" },
              domProps: { value: _vm.quantity },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.quantity = _vm._n($event.target.value)
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        : _c("p", [_vm._v(_vm._s(_vm.item.quantity))])
    ]),
    _vm._v(" "),
    _c("td", [
      _vm.editing
        ? _c("span", [
            _c(
              "button",
              {
                staticClass: "btn btn-link",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.cancel.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.save.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Save")]
            )
          ])
        : _c("span", [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.editing = !_vm.editing
                  }
                }
              },
              [_vm._v("Edit")]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.deleteItem.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Delete")]
            )
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }