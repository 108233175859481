<template>
    <div id="payment-form">
        <div ref="card" class="field empty"></div>
        <div v-if="errors">{{ errors }}</div>
    </div>
</template>

<script>
    let stripe = Stripe(NetDigitizing.stripeKey),
        elements = stripe.elements(),
        card = undefined;

    var elementStyles = {
        base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
                color: '#CFD7DF',
            },
            ':-webkit-autofill': {
                color: '#e39f48',
            },
        },
        invalid: {
            color: '#E25950',

            '::placeholder': {
                color: '#FFCCA5',
            },
        },
    };

    var elementClasses = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
    };

    export default {
        name: 'PaymentForm',

        data() {
            return {
                card: {},
                errors: ''
            }
        },

        mounted() {
            this.card = elements.create('card', {
                style: elementStyles,
                classes: elementClasses,
            });
            this.card.mount(this.$refs.card);

            this.card.on('change', (event) => {
                this.$emit('change', event);
                this.errors = event.error ? event.error.message : '';
            });
        },

        methods: {
            processPayment(clientSecret) {
                return stripe.confirmCardPayment(clientSecret, {
                    payment_method: {
                        card: this.card,
                        billing_details: {
                            name: 'TEST NAME'
                        }
                    }
                });
            }
        }
    }
</script>
