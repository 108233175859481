import {flatten, indexOf} from 'lodash';
import CartCleanup from "../../../support/CartCleanup";
import CartManager from "../../../support/CartManager";

export const initialOrderState = () => {
    return {
        customer: {},
        product: 0,
        quantity: 0,
        attributes: {},
        billingAddress: {},
        shippingAddress: {},
        deliveryId: '',
        approvalId: '',
        poNumber: '',
        hasFixedPricing: false,
        cardElement: {},
        notes: ''
    }
};

const state = initialOrderState();

const getters = {
    getQuantity: state => {
        return state.quantity;
    },
    getAttributeValue: state => attributeId => {
        if (typeof state.attributes[attributeId] === 'undefined') return;
        return state.attributes[attributeId];
    },
    getOptionIds: state => {
        return Object.values(state.attributes);
    },
    getSelectedOptionIds: state => {
        return flatten(Object.values(state.attributes));
    }
}

const mutations = {
    setQuantity(state, quantity) {
        state.quantity = quantity;
    },
    setHasFixedPricing(state, fixedPricing) {
        state.hasFixedPricing = fixedPricing;
    },
    selectAttributeOption(state, { attributeId, optionId }) {
        Vue.set(state.attributes, attributeId, optionId);
    },
    removeAttributeOption(state, attributeId) {
        Vue.delete(state.attributes, attributeId);
    },
    setAttributeOptions(state, { attributeId, options }) {
        Vue.set(state.attributes, attributeId, options);
    },
    setSelectedOptions(state, options) {
        state.attributes = Object.assign({}, options);
    },
    setNotes(state, notes) {
        state.notes = notes;
    },
    setDelivery(state, deliveryId) {
        state.deliveryId = deliveryId;
    },
    resetData(state) {
        Object.assign(state, initialOrderState());
    }
}

const actions = {
    setQuantity({ state, commit, getters, rootGetters, dispatch }, quantity) {
        commit('setQuantity', quantity);

        let product = rootGetters['ordering/selectedProduct'];
        commit('setHasFixedPricing', quantity <= product.quote_quantity_breakpoint);
    },
    selectAttributeOption({ state, commit, getters, dispatch, rootGetters }, { attributeId, optionId }) {
        let selectedOptionIds = getters.getSelectedOptionIds;
        let options = rootGetters['ordering/product/getOptions'](selectedOptionIds);
        let option = rootGetters['ordering/product/getOption'](optionId);

        let cartManager = new CartManager(options);
        if (option) {
            cartManager.single(option);
        } else {
            cartManager.removeAttribute(attributeId);
        }

        commit('setSelectedOptions', cartManager.exportAttributes());
    },
    selectMultipleAttributeOptions({ state, commit, getters, rootGetters }, { attributeId, optionId }) {
        let selectedOptionIds = getters.getSelectedOptionIds;
        let options = rootGetters['ordering/product/getOptions'](selectedOptionIds);
        let option = rootGetters['ordering/product/getOption'](optionId);

        let cartManager = new CartManager(options);
        if (option) {
            cartManager.multiple(option);
        } else {
            cartManager.removeAttribute(attributeId);
        }

        commit('setSelectedOptions', cartManager.exportAttributes());
    },
    resetData({ commit }) {
        commit('resetData');
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
