<template>
    <ValidationProvider
        :name="attribute.name"
        v-slot="{ errors }"
        :rules="attribute.required ? 'required' : ''"
    >
        <select
            class="form-control"
            v-model="value"
            :class="{ 'is-invalid': errors.length > 0 }"
        >
            <option value="">- Select Option -</option>
            <option
                v-for="option in options"
                :key="option.id"
                v-text="option.label"
                :value="option.id"
            ></option>
        </select>
        <div class="invalid-feedback">{{ errors[0] }}</div>
    </ValidationProvider>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            attribute: {
                type: Object,
                required: true
            }
        },

        computed: {
            options() {
                return this.$store.getters['ordering/product/getAttributeOptions'](this.attribute.id);
            },
            value: {
                get() {
                    return this.$store.getters['ordering/order/getAttributeValue'](this.attribute.id);
                },
                set(option) {
                    this.$store.dispatch('ordering/order/selectAttributeOption', {
                        attributeId: this.attribute.id,
                        optionId: option
                    });
                }
            }
        }
    }
</script>
