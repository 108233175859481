<template>
    <span>
        <div class="input-group mb-3">
            <input type="number" v-model.number="value" step="0.01" class="form-control" :class="{ 'text-danger': hasChange }"/>
        </div>
    </span>
</template>

<script>
    export default {
        name: 'OptionQuotePrice',

        props: {
            priceId: {
                type: Number,
                required: true
            }
        },

        computed: {
            price() {
                return this.$store.getters['attributeOption/getQuotePrice'](this.priceId);
            },
            change() {
                return this.$store.getters['attributeOption/getQuoteChange'](this.priceId);
            },
            hasChange() {
                return !! this.change;
            },
            value: {
                get() {
                    let price = (this.change) ? this.change.price : this.price.price;
                    return price / 100;
                },
                set(value) {
                    this.$store.commit('attributeOption/addQuoteChange',{
                        id: this.price.id,
                        price: value * 100,
                    });
                }
            }
        },
    }
</script>
