<template>
    <div class="stitch-matrix bg-white p-4">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th></th>
                    <th
                        v-for="quantity in quantities"
                        :key="quantity.id"
                    >{{ quantity.quantity }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="count in counts"
                    :key="count.id"
                >
                    <td>{{ count.value }} </td>
                    <td
                        v-for="price in prices(count.id)"
                        :key="price.id"
                    >
                        <stitch-price
                            :priceId="price.id"
                        ></stitch-price>
                    </td>
                </tr>
            </tbody>
        </table>

        <add-row label="Add Stitch Count"></add-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import StitchPrice from './StitchPrice';

    export default {
        name: 'StitchMatrix',

        components: {
            StitchPrice
        },

        props: ['product'],

        created() {
            this.$store.dispatch('products/stitches/loadPrices', this.product);
        },

        data() {
            return {
                showModal: false
            }
        },

        computed: {
            ...mapGetters('products/stitches', {
                counts: 'getCounts',
                quantities: 'getQuantities',
            })
        },

        methods: {
            prices(rowId) {
                return this.$store.getters['products/stitches/getRowPrices'](rowId);
            },
            addRow() {
                this.showModal = true;
            }
        }
    }
</script>
