export default class VatChecker {
    constructor(secure = false) {
        this.accessKey = NetDigitizing.vatlayerKey;
        this.baseUrl = ((secure) ? 'https://' : 'http://') + 'apilayer.net/api/validate';
    }

    async check(vatNumber) {
        let { data } = await axios.get(this.baseUrl, {
            params: {
                access_key: this.accessKey,
                vat_number: vatNumber
            },
            transformRequest: (data, headers) => {
                delete headers.common['X-CSRF-TOKEN'];
                delete headers.common['X-Requested-With'];
            }
        });

        return data;
    }
}
