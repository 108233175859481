var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("button", {
        staticClass: "btn",
        class: _vm.addButtonClass,
        domProps: { textContent: _vm._s(_vm.addButtonText) },
        on: {
          click: function($event) {
            _vm.show = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { show: _vm.show },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        [
          _c("product-quote-quantity-form", {
            on: {
              close: function($event) {
                _vm.show = false
              },
              saved: function($event) {
                _vm.show = false
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }