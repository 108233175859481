<template>
    <div class="row">
        <div class="col-6">
            <ValidationProvider name="Courier" v-slot="{ errors, validate }" rules="required">
                <div class="text-darker text-uppercase">Courier Name</div>
                <div class="mb-4">
                    <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                        name="courier_name"
                        :value="value.courier_name"
                        @change="validate"
                        @input="update('courier_name', $event.target.value)"
                    >
                    <div v-if="errors.length" class="invalid-feedback d-block">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </div>
        <div class="col-6">
            <ValidationProvider name="Tracking Number" v-slot="{ errors, validate }" rules="required">
                <div class="text-darker text-uppercase">Courier Tracking Number</div>
                <div class="mb-4">
                    <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                        name="reference"
                        :value="value.reference"
                        @change="validate"
                        @input="update('reference', $event.target.value)"
                    >
                    <div v-if="errors.length" class="invalid-feedback d-block">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </div>
        <div class="col-6">
            <ValidationProvider name="Tracking Url" v-slot="{ errors, validate }" rules="required">
                <div class="text-darker text-uppercase">Courier Tracking Url</div>
                <div class="mb-4">
                    <input
                        type="text"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                        name="url"
                        :value="value.url"
                        @change="validate"
                        @input="update('url', $event.target.value)"
                    >
                    <div v-if="errors.length" class="invalid-feedback d-block">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </div>
        <div class="col-6">
            <ValidationProvider name="Delivery Date" v-slot="{ errors, validate }" rules="required">
                <div class="text-darker text-uppercase">Delivery Date</div>
                <div class="mb-4">
                    <date-time-picker
                        id="delivery_date"
                        name="delivery_date"
                        :value="value.delivery_date"
                        @date-selected="(value) => {
                            validate();
                            update('delivery_date', value)
                        }"
                    >
                    </date-time-picker>
                    <div v-if="errors.length" class="invalid-feedback d-block">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>
        </div>
    </div>
</template>

<script>
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        props: {
            value: {
                type: Object,
                default() {
                    return {
                        courier_name: '',
                        reference: '',
                        url: '',
                        delivery_date: ''
                    }
                }
            }
        },

        methods: {
            update(key, value) {
                this.$emit('input', { ...this.value, [key]: value });
            }
        }
    }
</script>
