<template>
    <ValidationObserver v-slot="{ handleSubmit }" slim>
        <form @submit.prevent="handleSubmit(onSubmit)">

            <div class="alert alert-danger" v-if="errorMsg">{{ errorMsg }}</div>

            <ValidationProvider name="status" v-slot="{ errors }" rules="required">
                <div class="form-group">
                    <label class="control-label">New Status</label>
                    <select
                        v-model="status"
                        class="form-control"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    >
                        <option value="">- Select Status -</option>
                        <option
                            v-for="statusOption in statuses"
                            :key="statusOption"
                            :value="statusOption"
                            v-text="statusOption"
                        ></option>
                    </select>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>

            <ValidationProvider name="reason" v-slot="{ errors }" rules="required">
                <div class="form-group">
                    <label class="control-label">Reason</label>
                    <textarea
                        v-model="reason"
                        class="form-control"
                        cols="30"
                        rows="4"
                        :class="{ 'is-invalid': errors.length > 0 }"
                    ></textarea>
                    <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                </div>
            </ValidationProvider>

            <div>
                <button type="button" @click.prevent="cancel" class="btn btn-default">Cancel</button>
                <button type="submit" class="btn btn-primary" :disabled="submitting">Submit</button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
    components: {
        ValidationObserver,
        ValidationProvider
    },

    props: {
        sample: {
            type: Object,
            required: true
        },
        order: {
            type: Object,
            required: true
        },
        statuses: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            status: '',
            reason: '',
            submitting: false,
            errorMsg: ''
        }
    },

    methods: {
        cancel() {
            this.$emit('cancel');
        },
        async onSubmit() {
            try {
                this.submitting = true;
                this.errorMsg = '';

                let result = await axios.post(route('api.reject-digital-by-qc', this.sample.id), {
                    status: this.status,
                    reason: this.reason
                });

                this.submitting = false;
                window.location.reload();
            } catch (e) {
                this.errorMsg = e.message;
                this.submitting = false;
            }
        }
    }
}
</script>
