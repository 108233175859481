<template>
    <tr>
        <td>
            <span v-if="editing">
                <input type="text" v-model="name" class="form-control"/>
            </span>
            <span v-else>{{ option.name }}</span>
        </td>
        <td>
            <span v-if="editing">
                <input type="text" v-model="description" class="form-control"/>
            </span>
            <span v-else>{{ option.description }}</span>
        </td>
        <td>
            <span v-if="editing">
                <span class="input-group">
                    <input type="number" v-model.number="value" class="form-control">
                    <select v-model="type" class="custom-select" id="inputGroupSelect02">
                        <option value="fixed">Fixed</option>
                        <option value="percentage">Percentage</option>
                    </select>
                </span>

                <select v-model="base_delivery_id" class="form-control">
                    <option value="">- No Parent -</option>
                    <option
                        v-for="parentOption in parentOptions"
                        :key="parentOption.id"
                        :value="parentOption.id"
                        v-text="parentOption.name"
                    ></option>
                </select>
            </span>
            <span v-else>{{ priceDisplay }}</span>
        </td>
        <td>
            <span v-if="editing">
                <select v-model.number="active" class="form-control">
                    <option value="0">No</option>
                    <option value="1">Yes</option>
                </select>
            </span>
            <span v-else>{{ active ? 'Yes' : 'No' }}</span>
        </td>
        <td>
            <span v-if="editing">
                <button type="button" class="btn btn-link" @click.prevent="cancel">Cancel</button>
                <button type="button" class="btn btn-primary" @click.prevent="save">Save</button>
            </span>
            <span v-else>
                <a href="#" @click.prevent="editing = !editing">Edit</a>
                <a href="#" @click.prevent="deleteItem">Delete</a>
            </span>
        </td>
    </tr>
</template>

<script>
    export default {
        name: 'DeliveryItem',

        props: {
            option: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                name: this.option.name,
                description: this.option.description,
                active: this.option.active,
                value: '',
                type: '',
                base_delivery_id: (this.option.base_delivery_id) ? this.option.base_delivery_id : '',
                editing: false
            }
        },

        mounted() {
            this.value = this.$store.getters['product/delivery/optionValue'](this.option.id);
            this.type = this.$store.getters['product/delivery/optionType'](this.option.id);
        },

        computed: {
            priceDisplay() {
                return this.$store.getters['product/delivery/priceDisplay'](this.option.id);
            },
            parentOptions() {
                return this.$store.getters['product/delivery/getParentOptions'](this.option.id);
            }
        },

        methods: {
            cancel() {
                this.name = this.option.name;
                this.description = this.option.description;
                this.value = this.$store.getters['product/delivery/optionValue'](this.option.id);
                this.type = this.$store.getters['product/delivery/optionType'](this.option.id);
                this.base_delivery_id = this.option.base_delivery_id;
                this.editing = false;
                this.active = this.option.active;
            },
            deleteItem() {
                this.$store.dispatch('product/delivery/deleteOption', this.option.id);
            },
            async save() {
                let delivery = await this.$store.dispatch('product/delivery/updateOption', {
                    id: this.option.id,
                    name: this.name,
                    description: this.description,
                    value: this.value,
                    type: this.type,
                    base_delivery_id: this.base_delivery_id,
                    active: parseInt(this.active)
                });

                this.editing = false;
            }
        }
    }
</script>
