import Vue from 'vue';
import currency from 'currency.js';
import moment from 'moment';

Vue.filter('toPounds', (value) => {
    if (typeof value === 'object') {
        let amount = value.amount ? value.amount : value.money.amount;
        return currency(parseInt(amount))
            .divide(100)
            .format();
    }

    return currency(parseInt(value))
        .divide(100)
        .format();
});

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY hh:mm')
    }
});
