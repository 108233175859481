<template>
    <div class="dimensions-select">
        <ValidationObserver slim>
            <div class="row">
                <div class="col-sm-6">
                    <ValidationProvider name="unit" rules="required" v-slot="{ errors }">
                        <div class="form-group">
                            <label class="control-label">Select Unit</label>
                            <select v-model="unit" class="form-control">
                                <option value="inch">Inches</option>
                                <option value="cm">Centimetres</option>
                                <option value="mm">Millimetres</option>
                            </select>
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-sm-3">
                    <ValidationProvider
                        name="width"
                        v-slot="{ errors }"
                        :rules="widthRules"
                        slim
                    >
                        <div class="form-group">
                            <label class="control-label">Width</label>
                            <input type="number" v-model.number="width" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                </div>
                <div class="col-sm-3">
                    <ValidationProvider
                        name="height"
                        v-slot="{ errors }"
                        :rules="heightRules"
                        slim
                    >
                        <div class="form-group">
                            <label class="control-label">Height</label>
                            <input type="number" v-model.number="height" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        name: 'DimensionsSelect',

        components: {
            ValidationObserver,
            ValidationProvider
        },

        computed: {
            selectedProduct() {
                return this.$store.getters['ordering/selectedProduct'];
            },
            unit: {
                get() {
                    return this.$store.state.ordering.order.dimensions.unit;
                },
                set(value) {
                    this.$store.dispatch('ordering/order/setDimensions', {
                        unit: value || ''
                    });
                }
            },
            width: {
                get() {
                    return this.$store.state.ordering.order.dimensions.width;
                },
                set(value) {
                    this.$store.dispatch('ordering/order/setDimensions', {
                        width: value || ''
                    });
                }
            },
            height: {
                get() {
                    return this.$store.state.ordering.order.dimensions.height;
                },
                set(value) {
                    this.$store.dispatch('ordering/order/setDimensions', {
                        height: value || ''
                    });
                }
            },
            widthRules() {
                if (!this.selectedProduct) return;
                let minSize = parseFloat(this.selectedProduct.size_min);
                let maxSize = parseFloat(this.selectedProduct.size_max);

                return {
                    required: true,
                    width: {
                        height: '@height',
                        unit: '@unit',
                        minSize: minSize,
                        maxSize: maxSize
                    }
                };
            },
            heightRules() {
                if (!this.selectedProduct) return;
                let minSize = parseFloat(this.selectedProduct.size_min);
                let maxSize = parseFloat(this.selectedProduct.size_max);

                return {
                    required: true,
                    height: {
                        width: '@width',
                        unit: '@unit',
                        minSize: minSize,
                        maxSize: maxSize
                    }
                };
            }
        },

        watch: {
            unit(value) {
                this.$store.dispatch('ordering/order/resetDimensions');
            }
        }
    }
</script>
