var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tabs" }, [
    _c(
      "ul",
      { staticClass: "nav nav-tabs" },
      _vm._l(_vm.tabs, function(tab) {
        return _c("li", { staticClass: "nav-item" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              class: { active: tab.isActive },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.selectTab(tab)
                }
              }
            },
            [_vm._v(_vm._s(tab.name))]
          )
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tabs-details" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }