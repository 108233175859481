var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c("div", { staticClass: "input-group mb-3" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.value,
            expression: "value",
            modifiers: { number: true }
          }
        ],
        staticClass: "form-control",
        class: { "text-danger": _vm.hasChange },
        attrs: { type: "number", step: "0.01" },
        domProps: { value: _vm.value },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.value = _vm._n($event.target.value)
          },
          blur: function($event) {
            return _vm.$forceUpdate()
          }
        }
      })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }