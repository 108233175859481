<template>
    <span>
        <button type="button" class="btn btn-primary" @click="showForm" v-text="label"></button>
        <modal :show="showModal" @close="showModal = false">
            <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(save)">

                    <ValidationProvider name="value" rules="required" v-slot="{ errors }">
                        <div class="form-group" :class="{ 'has-error': errors.length > 0 }">
                            <label class="control-label">Value</label>
                            <input v-model="value" type="text" class="form-control">
                            <span class="help-block">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>

                    <div class="form-group">
                        <button type="submit" class="btn btn-primary">Save</button>
                    </div>
                </form>
            </ValidationObserver>
        </modal>
    </span>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        name: 'AddRow',

        components: {
            ValidationObserver,
            ValidationProvider
        },

        props: {
            label: {
                type: String,
                default: 'Add Row'
            }
        },

        data() {
            return {
                showModal: false,
                value: ''
            }
        },

        methods: {
            showForm() {
                this.showModal = true;
            },
            save() {
                this.$store.dispatch('products/stitches/createRow', {
                    value: this.value
                }).then(response => {
                    window.location.reload();
                });
            }
        }
    }
</script>
