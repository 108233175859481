<template>
    <div class="row mb-5">
        <div class="col-sm-8">
            <ValidationObserver ref="form">
                <div class="bg-white p-4">

                    <div class="alert alert-danger" v-if="validationErrors.length > 0">
                        <div v-for="(error, index) in validationErrors"
                            :key="index"
                            v-text="error"
                        ></div>
                    </div>

                    <div class="order-form-subtitle">Customer</div>

                    <div class="mb-4">
                        <ValidationProvider name="customer" v-slot="{ errors }" :rules="notEmpty">
                            <customer-select
                                :viewDetails="viewDetails"
                                v-model="customer"
                                :class="{ 'is-invalid': errors.length > 0 }"
                            ></customer-select>
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div v-if="viewDetails">
                        <div class="mb-4">
                            <h3>Billing Details</h3>
                            <billing-details
                                v-model="billingDetails"
                            ></billing-details>
                        </div>

                        <div class="mb-4">
                            <h3>Shipping Details</h3>
                            <div class="form-check mb-3">
                                <input
                                    class="form-check-input"
                                    v-model="sameAsBilling"
                                    type="checkbox"
                                    :value="true"
                                    id="sameAsBilling"
                                >
                                <label class="form-check-label" for="sameAsBilling">
                                    Same As Billing Address
                                </label>
                            </div>
                            <shipping-details
                                v-if="!sameAsBilling"
                                v-model="shippingDetails"
                            ></shipping-details>
                        </div>
                    </div>

                    <div class="order-form-subtitle">Product</div>
                    <select v-model="selectedProduct" class="form-control">
                        <option value="">- Select Product -</option>
                        <option
                            v-for="product in products"
                            :key="product.id"
                            :value="product.id"
                            v-text="product.name"
                        ></option>
                    </select>

                    <component
                        class="mt-4"
                        v-if="optionsForm"
                        :is="optionsForm"
                    ></component>

                    <div class="mt-4">
                        <input
                            :disabled="submitting"
                            type="button"
                            value="Submit"
                            :class="{ 'cursor-wait' : submitting, 'cursor-pointer' : !submitting }"
                            class="rounded p-2 px-4 text-white bg-black disabled:opacity-50"
                            @click="onSubmit"
                        >
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="col-sm-4">
            <div class="sticky-top">
                <div class="bg-white p-3">
                    <order-summary
                        :options-loaded="optionsLoaded"
                    ></order-summary>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';
    import OrderingModule from '../../store/modules/ordering';
    import CustomerSelect from "../CustomerSelect";
    import BillingDetails from './create/BillingDetails';
    import ShippingDetails from "./create/ShippingDetails";
    import ValidationError from "../../errors/ValidationError";

    export default {
        components: {
            ValidationObserver,
            ValidationProvider,
            CustomerSelect,
            BillingDetails,
            ShippingDetails
        },

        props: {
            products: {
                type: Array
            },
            vatRate: {
                type: Number
            },
            viewDetails: {
                type: Number
            }
        },

        data() {
            return {
                optionsLoaded: false,
                submitting: false,
                validationErrors: []
            }
        },

        created() {
            const store = this.$store;

            if (!(store && store.state && store.state.ordering)) {
                store.registerModule('ordering', OrderingModule);
            }

            this.$store.dispatch('ordering/loadProducts', this.products);
            this.$store.dispatch('ordering/setVatRate', this.vatRate);
        },

        computed: {
            customer: {
                get() {
                    return this.$store.state.ordering.customer.details;
                },
                set(value) {
                    this.$store.dispatch('ordering/customer/selectCustomer', value);
                }
            },
            selectedProduct: {
                get() {
                    return this.$store.state.ordering.selectedProduct;
                },
                set(value) {
                    this.$store.dispatch('ordering/selectProduct', value)
                        .then(response => {
                            this.optionsLoaded = true;
                        });
                }
            },
            billingDetails: {
                get() {
                    return this.$store.state.ordering.customer.billingAddress;
                },
                set(value) {
                    this.$store.commit('ordering/customer/setBillingAddress', value);
                }
            },
            sameAsBilling: {
                get() {
                    return this.$store.state.ordering.customer.sameAsBilling;
                },
                set(value) {
                    this.$store.commit('ordering/customer/setSameAsBilling', value);
                }
            },
            shippingDetails: {
                get() {
                    // TODO: Same as Billing
                    return this.$store.state.ordering.customer.shippingAddress;
                },
                set(value) {
                    this.$store.commit('ordering/customer/setShippingAddress', value);
                }
            },
            optionsForm() {
                let productCategory = this.$store.getters['ordering/productCategory'];
                return productCategory ? `${productCategory}-options-form` : '';
            },
            dimensions() {
                let { width, height, unit } = this.$store.getters['ordering/order/getDimensionValues'];
                if (width && height) {
                    return `${width}${unit} x ${height}${unit}`;
                }
                return '';
            },
            notEmpty () {
                return {
                    required: true,
                    not_empty: {
                        details: this.customer.details,
                    }
                };
            },
        },

        methods: {
            scrollToFirstError() {
                let firstError = document.querySelector('.is-invalid');
                firstError.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                });
            },
            async onSubmit() {
                this.submitting = true;
                this.validationErrors = [];
                let result = await this.$refs.form.validate();
                if (!result) {
                    this.submitting = false;
                    return this.scrollToFirstError();
                }

                try {
                    let { data: { reference } } = await this.$store.dispatch('ordering/order/createOrder');

                    window.location.replace(route('orders.show', reference));
                } catch (e) {
                    this.submitting = false;
                    if (e instanceof ValidationError) {
                        this.validationErrors = e.messages();
                    } else {
                        this.validationErrors = [e.message];
                    }

                    this.$nextTick(() => {
                        document.querySelector('.alert-danger').scrollIntoView({
                            behavior: 'smooth',
                            block: 'center'
                        });
                    });
                }
            }
        }
    }
</script>
