<template>
    <canvas></canvas>
</template>

<script>
    export default {
        name: 'ImagePreview',

        props: {
            file: null,
            width: {
                type: Number,
                default: 200
            },
            height: {
                type: Number,
                default: 100
            }
        },

        watch: {
            file(file) {
                if (file && file.name) {
                    return this.setPreview(file);
                }
                this.clearPreview();
            }
        },

        methods: {
            setPreview(file) {
                this.reader = new FileReader();
                this.reader.onload = (event) => {
                    let img = new Image();
                    img.onload = () => {
                        this.$el.width = this.width;
                        this.$el.height = this.height;
                        this.drawImageProp(this.$el.getContext('2d'), img);
                    }
                    img.src = event.target.result;
                }

                this.reader.readAsDataURL(file);
            },
            clearPreview() {
                let context = this.$el.getContext('2d');
                context.clearRect(0, 0, context.canvas.width, context.canvas.height);
            },
            drawImageProp(ctx, img, x, y, w, h, offsetX, offsetY) {
                if (arguments.length === 2) {
                    x = y = 0;
                    w = ctx.canvas.width;
                    h = ctx.canvas.height;
                }
                // default offset is center
                offsetX = typeof offsetX === "number" ? offsetX : 0.5;
                offsetY = typeof offsetY === "number" ? offsetY : 0.5;
                // keep bounds [0.0, 1.0]
                if (offsetX < 0) offsetX = 0;
                if (offsetY < 0) offsetY = 0;
                if (offsetX > 1) offsetX = 1;
                if (offsetY > 1) offsetY = 1;
                var iw = img.width,
                    ih = img.height,
                    r = Math.min(w / iw, h / ih),
                    nw = iw * r,   // new prop. width
                    nh = ih * r,   // new prop. height
                    cx, cy, cw, ch, ar = 1;
                // decide which gap to fill
                if (nw < w) ar = w / nw;
                if (Math.abs(ar - 1) < 1e-14 && nh < h) ar = h / nh;  // updated
                nw *= ar;
                nh *= ar;
                // calc source rectangle
                cw = iw / (nw / w);
                ch = ih / (nh / h);
                cx = (iw - cw) * offsetX;
                cy = (ih - ch) * offsetY;
                // make sure source rectangle is valid
                if (cx < 0) cx = 0;
                if (cy < 0) cy = 0;
                if (cw > iw) cw = iw;
                if (ch > ih) ch = ih;
                // fill image in dest. rectangle
                ctx.drawImage(img, cx, cy, cw, ch,  x, y, w, h);
            }
        }
    }
</script>
