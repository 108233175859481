var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          return [
            _c(
              "form",
              {
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.onSubmit)
                  }
                }
              },
              [
                _c("h3", {
                  staticClass: "mb-3",
                  domProps: { textContent: _vm._s(_vm.modalTitle) }
                }),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: { name: "Label", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Label")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.label,
                                    expression: "label"
                                  }
                                ],
                                staticClass: "form-control",
                                class: { "is-invalid": errors.length > 0 },
                                attrs: { type: "text" },
                                domProps: { value: _vm.label },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.label = $event.target.value
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(errors[0]))
                              ])
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("ValidationProvider", {
                  attrs: { name: "Quantity", rules: "required" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function(ref) {
                          var errors = ref.errors
                          return [
                            _c("div", { staticClass: "form-group" }, [
                              _c("label", [_vm._v("Quantity")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.quantity,
                                    expression: "quantity",
                                    modifiers: { number: true }
                                  }
                                ],
                                staticClass: "form-control",
                                class: { "is-invalid": errors.length > 0 },
                                attrs: { type: "number" },
                                domProps: { value: _vm.quantity },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.quantity = _vm._n($event.target.value)
                                  },
                                  blur: function($event) {
                                    return _vm.$forceUpdate()
                                  }
                                }
                              }),
                              _vm._v(" "),
                              _c("span", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(errors[0]))
                              ])
                            ])
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: { type: "submit" }
                    },
                    [_vm._v("Save")]
                  ),
                  _vm._v(" "),
                  _vm.editing
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-danger",
                          attrs: { type: "button" },
                          on: { click: _vm.deleteQuantity }
                        },
                        [_vm._v("Delete")]
                      )
                    : _vm._e()
                ])
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }