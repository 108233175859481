<template>
    <div>
        <div>
            <div class="order-form-subtitle">Customise</div>
            <dimension-select></dimension-select>
            <div class="row">
                <div
                    v-for="attribute in attributes(1)"
                    :key="attribute.id"
                    class="col-sm-6 mb-3"
                    v-if="hasOptions(attribute)"
                >
                    <attribute-input :attribute="attribute"></attribute-input>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <ValidationProvider
                        name="quantity"
                        v-slot="{ errors }"
                        rules="required"
                    >
                        <div class="form-group">
                            <label class="control-label">Quantity</label>
                            <input type="number" v-model.number="quantity" class="form-control" :class="{ 'is-invalid': errors.length > 0 }"/>
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </div>
        <div>
            <div class="order-form-subtitle">Special Options</div>
            <div class="row">
                <div
                    v-for="attribute in attributes(2)"
                    :key="attribute.id"
                    class="col-sm-6 mb-3"
                    v-if="hasOptions(attribute)"
                >
                    <attribute-input :attribute="attribute"></attribute-input>
                </div>
            </div>
        </div>
        <div>
            <div class="order-form-subtitle">Artwork</div>
            <div class="flex space-x-4">
                <div class="w-1/2">
                    <div class="mb-3">
                        <ValidationProvider
                            name="design name"
                            v-slot="{ errors }"
                            rules="required"
                        >
                            <label class="control-label">Design Name</label>
                            <input class="form-control" type="text" v-model="designName" :class="{ 'is-invalid': errors.length > 0 }">
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>

                    <div class="mb-3">
                        <ValidationProvider
                            name="artwork"
                            v-slot="{ errors }"
                            rules="required|not_empty"
                        >
                            <label class="control-label">Artwork</label>
                            <media-library-attachment
                                v-model="artwork"
                                name="artwork"
                                @change="processArtwork"
                            >
                            </media-library-attachment>
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>

                <div class="w-1/2">
                    <div class="mb-3">
                        <ValidationProvider
                            name="supporting_files"
                            v-slot="{ errors }"
                        >
                            <label class="control-label">Supporting Files</label>
                            <media-library-collection
                                v-model="supportingFiles"
                                name="supporting_files"
                                @change="processSupportingFiles"
                            >
                            </media-library-collection>
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12 mb-3">
                    <div class="form-group">
                        <label class="control-label">Notes</label>
                        <textarea v-model="notes" class="form-control" rows="3"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <div class="order-form-subtitle">Turnaround</div>
            <div class="row">
                <div class="col-sm-6 mb-3">
                    <ValidationProvider name="delivery" v-slot="{ errors }" rules="required">
                        <div class="form-group">
                            <label class="control-label">Delivery</label>
                            <select
                                v-model="deliveryId"
                                class="form-control"
                                :class="{ 'is-invalid': errors.length > 0 }"
                            >
                                <option value="">- Select Delivery -</option>
                                <option
                                    v-for="option in deliveryOptions"
                                    :key="option.id"
                                    :value="option.id"
                                    v-text="option.name"
                                ></option>
                            </select>
                            <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                        </div>
                    </ValidationProvider>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationProvider
        },

        computed: {
            ...mapState('ordering/product',[
                'deliveryOptions'
            ]),
            quantity: {
                get() {
                    return this.$store.state.ordering.order.quantity;
                },
                set(value) {
                    this.$store.dispatch('ordering/order/setQuantity', value);
                }
            },
            designName: {
                get() {
                    return this.$store.state.ordering.order.designName;
                },
                set(value) {
                    this.$store.commit('ordering/order/setDesignName', value);
                }
            },
            artwork: {
                get() {
                    return this.$store.state.ordering.order.artwork;
                },
                set(value) {
                    this.$store.commit('ordering/order/setArtwork', Object.assign({}, value));
                }
            },
            supportingFiles: {
                get() {
                    return this.$store.state.ordering.order.supportingFiles;
                },
                set(value) {
                    this.$store.commit('ordering/order/setSupportingFiles', Object.assign({}, value));
                }
            },
            notes: {
                get() {
                    return this.$store.state.ordering.order.notes;
                },
                set(value) {
                    this.$store.commit('ordering/order/setNotes', value);
                }
            },
            deliveryId: {
                get() {
                    return this.$store.state.ordering.order.deliveryId;
                },
                set(value) {
                    this.$store.commit('ordering/order/setDelivery', value);
                }
            }
        },

        methods: {
            processArtwork(artwork) {
                this.artwork = artwork;
            },
            processSupportingFiles(supportingFiles) {
                this.supportingFiles = supportingFiles;
            },
            attributes(step) {
                return this.$store.getters['ordering/product/getAttributes'](step);
            },
            hasOptions(attribute) {
                let options = this.$store.getters['ordering/product/getAttributeOptions'](attribute.id);
                return options.length > 0;
            }
        }
    }
</script>
