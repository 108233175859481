<template>
    <div class="customer-notes">

        <div
            class="customer-notes-list"
            ref="notesContainer"
        >
            <div
                v-for="note in notes"
                :key="note.id"
                class="customer-note mb-3 pb-3"
            >
                <div class="note mb-2 text-darker">{{ note.body }}</div>
                <div class="d-flex justify-content-between">
                    <div class="font-weight-bold">{{ note.created_by }}</div>
                    <div>{{ note.created_at }}</div>
                </div>
            </div>
        </div>

        <div class="customer-notes-form mt-5">
            <div class="alert alert-danger" v-if="validationErrors.length > 0">
                <div
                    v-for="(error, index) in validationErrors"
                    :key="index"
                    v-text="error"
                ></div>
            </div>

            <div>Add Note</div>
            <textarea
                v-model="body"
                class="form-control mb-2"
                rows="5"></textarea>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary"
                    @click.prevent="submit"
                    :disabled="submitting"
                >Add Note</button>
            </div>
        </div>
    </div>
</template>

<script>
import ValidationError from "../../errors/ValidationError";

export default {
    props: {
        customer: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            notes: [],
            body: '',
            submitting: false,
            validationErrors: [],
        }
    },

    created() {
        this.loadNotes();
    },

    methods: {
        async loadNotes() {
            let { data } = await axios.get(route('api.customers.notes.index', this.customer));

            this.notes = data.data;
        },
        async submit() {
            this.submitting = true;
            this.validationErrors = [];

            try {
                let { data } = await axios.post(route('api.customers.notes.store', this.customer), {
                    description: this.body
                });

                this.notes.push(data.data);

                this.submitting = false;
                this.body = '';

                this.$nextTick(() => {
                    this.$refs.notesContainer.scrollTop = this.$refs.notesContainer.scrollHeight;
                })
            } catch (e) {
                if (e instanceof ValidationError) {
                    this.validationErrors = e.messages();
                }
                this.submitting = false;
            }
        }
    }
}
</script>
