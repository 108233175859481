var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Original Width")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "form-control-plaintext" }, [
            _vm._v(_vm._s(_vm.originalWidth) + " " + _vm._s(_vm.originalUnit))
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v("Original Height")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "form-control-plaintext" }, [
            _vm._v(_vm._s(_vm.originalHeight) + " " + _vm._s(_vm.originalUnit))
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-12 col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: { name: "Unit", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Unit")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.unit,
                              expression: "unit"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.unit = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "mm" } }, [
                            _vm._v("mm")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "cm" } }, [
                            _vm._v("cm")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "inch" } }, [
                            _vm._v("inch")
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      errors.length > 0
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: { name: "Width", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Width")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-group",
                          class: { "is-invalid": errors.length > 0 }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.width,
                                expression: "width",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number" },
                            domProps: { value: _vm.width },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.width = _vm._n($event.target.value)
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v(_vm._s(_vm.unit))
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      errors.length > 0
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-6 col-md-4" },
        [
          _c("ValidationProvider", {
            attrs: { name: "Height", rules: "required" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Height")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input-group",
                          class: { "is-invalid": errors.length > 0 }
                        },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.height,
                                expression: "height",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            attrs: { type: "number" },
                            domProps: { value: _vm.height },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.height = _vm._n($event.target.value)
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-group-append" }, [
                            _c("span", { staticClass: "input-group-text" }, [
                              _vm._v(_vm._s(_vm.unit))
                            ])
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      errors.length > 0
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-sm-6" },
        [
          _c("ValidationProvider", {
            attrs: { name: "number of colours", rules: _vm.colourRules },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var errors = ref.errors
                  return [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Number of Colours")
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.numColours,
                            expression: "numColours",
                            modifiers: { number: true }
                          }
                        ],
                        staticClass: "form-control",
                        class: { "is-invalid": errors.length > 0 },
                        attrs: { type: "number" },
                        domProps: { value: _vm.numColours },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.numColours = _vm._n($event.target.value)
                          },
                          blur: function($event) {
                            return _vm.$forceUpdate()
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors.length
                        ? _c("span", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ])
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", { staticClass: "control-label" }, [_vm._v("Note")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.notes,
                expression: "notes"
              }
            ],
            staticClass: "form-control",
            attrs: { cols: "30", rows: "3" },
            domProps: { value: _vm.notes },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.notes = $event.target.value
              }
            }
          })
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }