import parentOrder, { initialOrderState } from '../order';
import FormDataBuilder from "../../../../support/FormDataBuilder";

const initialBadgeOrderState = () => {
    return Object.assign({}, initialOrderState(), {
        artwork: null,
        designName: '',
        sizePrices: {},
    })
};

const state = Object.assign({}, parentOrder.state, initialBadgeOrderState());

const getters = Object.assign({}, parentOrder.getters, {
    getDimensionValues: (state) => {
        return state.dimensions;
    }
});

const mutations = Object.assign({}, parentOrder.mutations, {
    setSizePrices(state, prices) {
        state.sizePrices = prices;
    },
    setArtwork(state, artworkFile) {
        state.artwork = artworkFile;
    },
    setSupportingFiles(state, supportingFiles) {
        state.supportingFiles = supportingFiles;
    },
    setDesignName(state, designName) {
        state.designName = designName;
    },
    resetData(state) {
        Object.assign(state, initialBadgeOrderState());
    }
});

const actions = Object.assign({}, parentOrder.actions, {
    async loadBasePrice({ state, commit, getters, rootGetters }) {
        // let product = rootGetters.selectedProduct;
        //
        // // Need both dimensions to look for base price
        // if (!(state.dimensions.width && state.dimensions.height)) {
        //     return;
        // }
        //
        // let { data: { size, prices } } = await Api.fetchBasePrice(product.slug, {
        //     params: {
        //         width: state.dimensions.width,
        //         height: state.dimensions.height,
        //         unit: state.dimensions.unit
        //     }
        // });
        //
        // let { entities: { sizePrices } } = normalize(prices, [sizePriceSchema]);
        //
        // commit('setSize', size);
        // commit('setSizePrices', sizePrices);

        // FormSession.updateOrder(state);
    },

    createOrder({ state, commit, getters, rootGetters }) {
        let formData = new FormDataBuilder;

        formData
            .add('product', rootGetters['ordering/selectedProduct'].id)
            .add('customer', rootGetters['ordering/customer/customerId'])
            .add('quantity', state.quantity)
            .add('billing', rootGetters['ordering/customer/billingAddress'])
            .add('design_name', state.designName)
            .add('delivery_id', state.deliveryId)
            .add('notes', state.notes)
            .add('po_number', state.poNumber)
            .artwork(state.artwork)
            .supportingFiles(state.supportingFiles)
            .attributes(state.attributes);

        if (!rootGetters['ordering/customer/sameAsBilling']) {
            formData.add('different_delivery_address', true)
                .add('delivery', rootGetters['ordering/customer/shippingAddress']);
        }

        if (!rootGetters['customer/isVatable']) {
            formData.add(`without_vat`, true);
        }

        return axios.post(route('orders.store'), formData.formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    }
});

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
