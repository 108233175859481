<template>
    <span>
        <a href="#" class="btn btn-primary" @click.prevent="show = true">Add Quantity Breakpoint</a>
        <modal :show="show" @close="closeModal">
            <quantity-breakpoint-form
                @saved="closeModal"
            ></quantity-breakpoint-form>
        </modal>
    </span>
</template>

<script>
    export default {
        name: 'AddQuantityBreakpoint',

        data() {
            return {
                show: false
            }
        },

        methods: {
            closeModal() {
                this.show = false;
            }
        }
    }
</script>
