import VatChecker from "../../../support/VatChecker";

let vatChecker = new VatChecker();

const state = {
    details: {},
    sameAsBilling: false,
    billingAddress: {
        address_1: '',
        town: '',
        county: '',
        postcode: '',
        vat_number: '',
        po_number: ''
    },
    shippingAddress: {
        address_1: '',
        town: '',
        county: '',
        postcode: ''
    },
    vatable: true
}

const getters = {
    customerId: (state) => {
        return state.details.id;
    },
    sameAsBilling: (state) => {
        return state.sameAsBilling;
    },
    billingAddress: (state) => {
        return state.billingAddress;
    },
    shippingAddress: (state) => {
        return state.shippingAddress;
    },
    isVatable: (state) => {
        return state.vatable;
    }
}

const mutations = {
    setDetails(state, details) {
        state.details = Object.assign({}, details);
    },
    setBillingAddress(state, address) {
        state.billingAddress = Object.assign({}, address);
    },
    setShippingAddress(state, address) {
        state.shippingAddress = Object.assign({}, address);
    },
    setSameAsBilling(state, sameAsBilling) {
        state.sameAsBilling = sameAsBilling;
    },
    setVatable(state, vatable) {
        state.vatable = vatable;
    }
}

const actions = {
    async selectCustomer({ commit }, customer) {
        commit('setDetails', customer);

        let {data: {data: customerData}} = await axios.get(route('api.customers.show', customer.id));

        commit('setBillingAddress', customerData.billing_address);
        if (customerData.delivery_address) {
            commit('setSameAsBilling', false);
            commit('setShippingAddress', customerData.delivery_address);
        } else {
            commit('setSameAsBilling', true);
        }
    },
    async checkVat({ state, commit }) {
        let vatNumber = state.billingAddress.vat_number;

        let valid = await vatChecker.vatable(vatNumber);

        commit('setVatable', valid);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
