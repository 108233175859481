<template>
    <div class="product-attributes">
        <h3 class="mb-3">Attributes</h3>  

        <table class="table">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Required</th>
                    <th>Step</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="attribute in attributeList"
                    :key="attribute.id"
                >
                    <td>
                        <div>{{ attribute.name }}</div>
                        <div><small>{{ attribute.description }}</small></div>
                    </td>
                    <td>{{ attribute.required }}</td>
                    <td>{{ attribute.step }}</td>
                    <td>
                        <a :href="editUrl(attribute.id)">Edit</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AttributesModule from '../../../store/modules/product/attributes';

    export default {
        name: 'ProductAttributes',

        props: {
            product: {
                type: String,
                required: true
            }
        },

        computed: {
            ...mapGetters('product/attributes',[
                'attributeList'
            ])
        },

        methods: {
            editUrl(attributeId) {
                return route('products.attributes.edit', [this.$store.state.product.slug, attributeId]);
            }
        },

        beforeCreate() {
            const store = this.$store;

            // register a new module only if doesn't exist
            if (!(store && store.state && store.state.product.attributes)) {
                store.registerModule(['product', 'attributes'], AttributesModule);
            }

            this.$store.dispatch('product/attributes/loadAttributes');
        }
    }
</script>