import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import products from './modules/products';
import orders from './modules/orders';

export default new Vuex.Store({
    modules: {
        products,
        orders
    }
});
