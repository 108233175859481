var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "table-sort-header", on: { click: _vm.updateSortBy } },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c(
        "svg",
        {
          attrs: {
            width: "7",
            height: "9",
            viewBox: "0 0 7 9",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }
        },
        [
          _c("path", {
            attrs: {
              "fill-rule": "evenodd",
              "clip-rule": "evenodd",
              d:
                "M6.9282 3.75L3.4641 0L0 3.75H6.9282ZM6.9282 5.25L3.4641 9L0 5.25H6.9282Z",
              fill: "#8A8C8F"
            }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }