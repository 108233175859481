export default class LengthConvertor {
    static convert(size, unit) {
        if (!size) return 0;

        switch(unit) {
            case 'inch': return size / 0.039370;
            case 'cm': return size * 10;
            case 'mm': return size;
        }
        return 0;
    }
}
