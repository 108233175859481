var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.options, function(option) {
      return _c("div", { key: option.id, staticClass: "form-check" }, [
        _c("input", {
          staticClass: "form-check-input",
          attrs: { type: "checkbox", id: "" + _vm.attribute.name + option.id },
          domProps: { value: option.id },
          on: {
            input: function($event) {
              return _vm.selectAttribute(option.id)
            }
          }
        }),
        _vm._v(" "),
        _c("label", {
          staticClass: "form-check-label",
          attrs: { for: "" + _vm.attribute.name + option.id },
          domProps: { textContent: _vm._s(option.label) }
        })
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }