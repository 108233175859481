var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trim-prices bg-white p-4" }, [
    _c(
      "ul",
      _vm._l(_vm.items, function(trim) {
        return _c("li", { key: trim.id }, [
          _c("label", { staticClass: "control-label" }, [
            _vm._v(_vm._s(trim.label))
          ]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "text" },
            domProps: { value: _vm.formattedPrice(trim.price) }
          })
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }