var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-base-prices" }, [
    _c("div", { staticClass: "flex flex-row items-center mb-2" }, [
      _c("h3", { staticClass: "flex-grow" }, [_vm._v("Fixed Prices")]),
      _vm._v(" "),
      this.quoteCsvMedia
        ? _c("div", { staticClass: "flex flex-column items-center" }, [
            _c(
              "a",
              {
                staticClass: "btn btn-success p-2 m-2",
                attrs: { href: _vm.downloadMediaUrl(this.quoteCsvMedia) }
              },
              [
                _vm._v(
                  "\n                Download Quotes Data CSV\n            "
                )
              ]
            ),
            _vm._v(" "),
            _c("span", [
              _vm._v(
                "\n                Generated at " +
                  _vm._s(_vm._f("formatDate")(this.quoteCsvMedia.created_at)) +
                  "\n            "
              )
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "table" }, [
      _c("thead", [
        _c(
          "tr",
          [
            _c("th"),
            _vm._v(" "),
            _vm._l(_vm.quantities, function(quantity) {
              return _c("edit-quantity-breakpoint", {
                key: quantity.id,
                attrs: { "quantity-id": quantity.id }
              })
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.sizes, function(size) {
          return _c(
            "tr",
            { key: size.id },
            [
              _c("edit-size-breakpoint", { attrs: { "size-id": size.id } }),
              _vm._v(" "),
              _vm._l(_vm.quantities, function(quantity) {
                return _c("fixed-price", {
                  key: quantity.id,
                  attrs: { "quantity-id": quantity.id, "size-id": size.id }
                })
              })
            ],
            2
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex justify-content-between my-2" }, [
      _c(
        "div",
        { staticClass: "creation-actions" },
        [_c("add-quantity-breakpoint"), _vm._v(" "), _c("add-size-breakpoint")],
        1
      ),
      _vm._v(" "),
      _vm.hasChanges
        ? _c("div", { staticClass: "price-actions" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-link",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.cancelChanges.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Cancel")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    return _vm.saveChanges.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Save Changes")]
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }