import {schema} from "normalizr";

const quotePrice = new schema.Entity('quotePrice');

const attributeOption = new schema.Entity('attributeOptions',{
    quote_price: quotePrice
});

const attributeSchema = new schema.Entity('attributes',{
    options: [attributeOption]
});

export default attributeSchema;
