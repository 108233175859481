<template>
    <ValidationObserver v-slot="{ handleSubmit }" slim>
        <form @submit.prevent="handleSubmit(onSubmit)">
            <div class="reject-artwork">
                <h3 class="mb-3">Reject Artwork</h3>

                <div v-if="error" class="alert alert-danger">{{ error }}</div>

                <ValidationProvider name="reason" v-slot="{ errors }" rule="required">
                    <div class="form-group">
                        <label class="control-label">Reason</label>
                        <textarea
                            v-model="reason"
                            class="form-control"
                            cols="30"
                            rows="4"
                            :class="{ 'is-invalid': errors.length > 0 }"
                        ></textarea>
                        <div v-if="errors.length" class="invalid-feedback">{{ errors[0] }}</div>
                    </div>
                </ValidationProvider>

                <div>
                    <button type="submit" class="btn btn-primary" :disabled="submitting">Submit</button>
                    <button type="button" @click="cancel" class="btn btn-default" :disabled="submitting">Cancel</button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationObserver,
            ValidationProvider
        },

        props: {
            order: {
                type: Object,
                required: true
            }
        },

        data() {
            return {
                reason: '',
                error: '',
                submitting: false
            }
        },

        methods: {
            async onSubmit() {
                try {
                    this.submitting = true;
                    this.error = '';

                    let result = await axios.post(route('api.orders.reject-artwork.store', this.order.reference), {
                        reason: this.reason
                    });

                    this.submitting = false;
                    window.location.reload();
                } catch (e) {
                    this.submitting = false;
                    this.error = 'We could not complete this request';
                }
            },
            cancel() {
                this.$emit('cancel');
            }
        }
    }
</script>
