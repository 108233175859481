<template>
    <multiselect
        v-model="customer"
        label="name"
        track-by="id"
        placeholder="Type to search"
        :custom-label="nameWithReference"
        :options="options"
        :multiple="false"
        :searchable="true"
        :loading="isLoading"
        @search-change="searchCustomer"
    ></multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect';

    export default {
        components: {
            Multiselect
        },

        props: {
            value: {
                type: Object
            },
            viewDetails: {
                type: Number
            }
        },

        data() {
            return {
                options: [],
                isLoading: false
            }
        },

        computed: {
            customer: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                }
            }
        },

        methods: {
            async searchCustomer(term) {
                this.isLoading = false;
                let { data: { data: options } } = await axios.get(route('api.customers.index'), {
                    params: {
                        term: term
                    }
                });

                this.options = options;
            },
            nameWithReference(option) {
                let label = `${option.name} (${option.reference})`;

                if (!this.viewDetails) {
                    label = `${option.reference}`
                }

                // check for empty option
                if (Object.keys(option).length === 0 && option.constructor === Object) {
                    label = '';
                }

                return label;
            },
            clearAll () {
                this.customer = {}
            }
        }
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
