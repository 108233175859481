var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dimensions-select" },
    [
      _c("ValidationObserver", { attrs: { slim: "" } }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-sm-6" },
            [
              _c("ValidationProvider", {
                attrs: { name: "unit", rules: "required" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Select Unit")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.unit,
                                  expression: "unit"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.unit = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                }
                              }
                            },
                            [
                              _c("option", { attrs: { value: "inch" } }, [
                                _vm._v("Inches")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "cm" } }, [
                                _vm._v("Centimetres")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "mm" } }, [
                                _vm._v("Millimetres")
                              ])
                            ]
                          )
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("ValidationProvider", {
                attrs: { name: "width", rules: _vm.widthRules, slim: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Width")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.width,
                                expression: "width",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": errors.length > 0 },
                            attrs: { type: "number" },
                            domProps: { value: _vm.width },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.width = _vm._n($event.target.value)
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          errors.length
                            ? _c("span", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(errors[0]))
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col-sm-3" },
            [
              _c("ValidationProvider", {
                attrs: { name: "height", rules: _vm.heightRules, slim: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var errors = ref.errors
                      return [
                        _c("div", { staticClass: "form-group" }, [
                          _c("label", { staticClass: "control-label" }, [
                            _vm._v("Height")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number",
                                value: _vm.height,
                                expression: "height",
                                modifiers: { number: true }
                              }
                            ],
                            staticClass: "form-control",
                            class: { "is-invalid": errors.length > 0 },
                            attrs: { type: "number" },
                            domProps: { value: _vm.height },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.height = _vm._n($event.target.value)
                              },
                              blur: function($event) {
                                return _vm.$forceUpdate()
                              }
                            }
                          }),
                          _vm._v(" "),
                          errors.length
                            ? _c("span", { staticClass: "invalid-feedback" }, [
                                _vm._v(_vm._s(errors[0]))
                              ])
                            : _vm._e()
                        ])
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }