<template>
    <div>
        <div
            class="form-check"
            v-for="option in options"
            :key="option.id"
        >
            <input
                class="form-check-input"
                type="checkbox"
                :value="option.id"
                :id="`${attribute.name}${option.id}`"
                @input="selectAttribute(option.id)"
            >
            <label class="form-check-label" :for="`${attribute.name}${option.id}`" v-text="option.label"></label>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            attribute: {
                type: Object,
                required: true
            }
        },

        computed: {
            options() {
                return this.$store.getters['ordering/product/getAttributeOptions'](this.attribute.id);
            },
        },

        methods: {
            selectAttribute(optionId) {
                this.$store.dispatch('ordering/order/selectMultipleAttributeOptions', {
                    attributeId: this.attribute.id,
                    optionId: optionId
                });
            }
        }
    }
</script>
