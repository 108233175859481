import {extend, filter, indexOf, sortBy} from "lodash";
import {normalize} from "normalizr";
import quantitySchema from "../../schema/ordering/quantities";
import attributeSchema from "../../schema/ordering/attributes";
import deliverySchema from "../../schema/ordering/deliveries";
import noAttributeParent from "../../../support/NoAttributeParent";
import OptionFilters from "../../../support/OptionFilters";

const state = {
    quoteOnly: false,
    attributes: {},
    attributeSizeBreakpoints: {},
    options: {},
    optionSizePrices: {},
    restrictedBy: {},
    deliveryOptions: {},
    approvalOptions: {},
    quantityBreakpoints: {},
    attributesLoading: false
}

const getters = {
    getAttributes: state => step => {
        return sortBy(filter(state.attributes, attribute => {
            return noAttributeParent(state.attributes, attribute.id) && attribute.step === step;
        }), attribute => {
            return parseFloat(attribute.order);
        });
    },
    getAttribute: state => attributeId => {
        if (!attributeId) return;
        return state.attributes[attributeId];
    },
    getAttributeOptions: (state, getters, rootState, rootGetters) => attributeId => {
        if (!attributeId) return;

        let attribute = state.attributes[attributeId];

        let selectedOptionIds = rootGetters['ordering/order/getSelectedOptionIds'];

        // Filter by restricted
        let options = attribute.options.map(optionId => state.options[optionId]);

        return (new OptionFilters(options)).filter(selectedOptionIds);
    },
    getOption: state => optionId => {
        if (!optionId) return;
        return state.options[optionId];
    },
    getOptions: state => optionIds => {
        if (!optionIds) return [];
        return filter(state.options, option => {
            return indexOf(optionIds, option.id) > -1;
        });
    },
    getDeliveryOption: (state, getters) => deliveryId => {
        if (!deliveryId) return;
        return state.deliveryOptions[deliveryId];
    }
}

const mutations = {
    setProductAttributes(state, attributes) {
        state.attributes = Object.assign({}, attributes);
    },
    setAttributeSizeBreakpoints(state, sizeBreakpoints) {
        state.attributeSizeBreakpoints = Object.assign({}, sizeBreakpoints);
    },
    setAttributeOptions(state, options) {
        state.options = Object.assign({}, options);
    },
    setOptionSizePrices(state, optionSizePrices) {
        state.optionSizePrices = Object.assign({}, optionSizePrices);
    },
    setRestrictedBy(state, restrictedBy) {
        state.restrictedBy = Object.assign({}, restrictedBy);
    },
    setAttributesLoading(state, loading) {
        state.attributesLoading = loading;
    },
    setDeliveryOptions(state, deliveryOptions) {
        state.deliveryOptions = Object.assign({}, deliveryOptions);
    },
    setApprovalOptions(state, approvalOptions) {
        state.approvalOptions = Object.assign({}, approvalOptions);
    },
    setQuantityBreakpoints(state, quantities) {
        state.quantityBreakpoints = Object.assign({}, quantities);
    },
    setQuoteOnly(state) {
        state.quoteOnly = true;
    }
}

const actions = {
    async loadData({commit, dispatch}, slug) {
        commit('setAttributesLoading', true)

        let responses = await Promise.all([
            dispatch('fetchQuantityBreakpoints', slug),
            dispatch('fetchAttributes', slug),
            dispatch('fetchDeliveryOptions', slug),
        ]);

        commit('setAttributesLoading', false);

        return true;
    },
    async fetchQuantityBreakpoints({ commit }, slug) {
        let { data: { data: breakpoints } } = await axios.get(route('api.products.quantities.index', { product: slug }))

        let { entities: { quantities }} = normalize(breakpoints, [quantitySchema])

        commit('setQuantityBreakpoints', quantities)
    },
    async fetchAttributes({ commit }, slug) {
        let { data: { data: attributeData } } = await axios.get(route('api.products.attributes.index', { product: slug }))

        let { entities: { attributes, options, restrictedBy, sizeBreakpoints, sizePrices }} = normalize(attributeData, [attributeSchema]);

        commit('setProductAttributes', attributes);
        commit('setAttributeOptions', options);
        commit('setAttributeSizeBreakpoints', sizeBreakpoints);
        commit('setRestrictedBy', restrictedBy);
        commit('setOptionSizePrices', sizePrices);
    },
    async fetchDeliveryOptions({ commit }, slug) {
        let { data: { data: deliveryOptionsData } } = await axios.get(route('api.products.deliveries.index', { product: slug }))

        let { entities: { deliveryOptions } } = normalize(deliveryOptionsData, [deliverySchema]);

        commit('setDeliveryOptions', deliveryOptions);
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
