var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("a", {
        staticClass: "btn btn-danger",
        attrs: { href: "#" },
        domProps: { textContent: _vm._s(_vm.buttonText) },
        on: {
          click: function($event) {
            $event.preventDefault()
            _vm.show = true
          }
        }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { show: _vm.show, width: "md" },
          on: {
            close: function($event) {
              _vm.show = false
            }
          }
        },
        [
          _vm.errorMsg
            ? _c("div", {
                staticClass: "alert alert-danger",
                domProps: { textContent: _vm._s(_vm.errorMsg) }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("h3", [_vm._v("Cancel Order")]),
          _vm._v(" "),
          _c("p", [_vm._v("Are you sure you want to cancel this order?")]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { staticClass: "control-label" }, [_vm._v("Reason")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.message,
                  expression: "message"
                }
              ],
              staticClass: "form-control",
              attrs: { rows: "5" },
              domProps: { value: _vm.message },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.message = $event.target.value
                }
              }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-3 d-flex justify-content-between" }, [
            _c("button", {
              staticClass: "btn btn-danger mr-2",
              attrs: { disabled: _vm.loading },
              domProps: { textContent: _vm._s(_vm.confirmButtonText) },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--grey",
                attrs: { href: "#" },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.show = false
                  }
                }
              },
              [_vm._v("Cancel")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }