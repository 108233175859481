<template>
    <div class="summary-item">
        <div class="summary-item-label">{{ attribute.name }}</div>
        <div class="summary-item-options">
            <div class="summary-item-value"
                 v-for="option in selectedOptions"
                 :key="option.id"
            >
                <div class="summary-value-label">{{ option.label }}</div>
<!--                <div class="attribute-option-price" v-if="displayPrice">{{ optionCost(option.id) }}</div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import CurrencyFormatter from "../../../support/CurrencyFormatter";

    export default {
        name: 'SelectedOptionsSummary',

        props: {
            attributeId: {
                required: true
            },
            options: {
                required: true
            }
        },

        computed: {
            ...mapState('product',[
                'quoteOnly'
            ]),
            ...mapState('order', [
                'hasFixedPricing'
            ]),
            displayPrice() {
                return (!this.quoteOnly && this.hasFixedPricing);
            },
            attribute() {
                return this.$store.getters['ordering/product/getAttribute'](this.attributeId);
            },
            selectedOptions() {
                let options = Array.isArray(this.options) ? this.options : [this.options];

                return options.map((optionId) => this.$store.getters['ordering/product/getOption'](optionId));
            }
        },

        methods: {
            optionCost(option) {
                let cost = this.$store.getters['ordering/product/getOptionCost'](option);
                return new CurrencyFormatter(cost).format();
            }
        }
    }
</script>
