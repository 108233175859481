<template>
    <tr>
        <td>
            <select v-model="selectedAttribute" class="form-control">
                <option
                    v-for="attribute in attributes"
                    :key="attribute.id"
                    :value="attribute.id"
                    v-text="attribute.name"
                ></option>
            </select>
        </td>
        <td>
            <div class="form-check" v-for="option in attributeOptions">
                <input class="form-check-input" v-model="selectedOptions" type="checkbox" :value="option.id" :id="`attributeOption${option.id}`">
                <label class="form-check-label" :for="`attributeOption${option.id}`">{{ option.label }}</label>
            </div>
        </td>
        <td>
            <a href="#" @click.prevent="removeAttribute" class="btn btn-danger">Remove</a>
        </td>
    </tr>
</template>

<script>
    export default {
        props: {
            attribute: {
                type: Number
            },
            index: {
                type: Number
            }
        },

        computed: {
            selectedAttribute: {
                get() {
                    return this.$store.getters['attributeOptions/restrictedAttributeAtIndex'](this.index);
                },
                set(value) {
                    this.$store.dispatch('attributeOptions/setRestrictedAttribute', {
                        index: this.index,
                        attribute: value
                    });
                }
            },
            selectedOptions: {
                get() {
                    return this.$store.getters['attributeOptions/restrictedOptions'](this.selectedAttribute);
                },
                set(value) {
                    this.$store.commit('attributeOptions/setRestrictedOptions', {
                        attribute: this.selectedAttribute,
                        options: value
                    })
                }
            },
            attributes() {
                return this.$store.getters['attributeOptions/attributes'](this.selectedAttribute);
            },
            attributeOptions() {
                return this.$store.getters['attributeOptions/attributeOptions'](this.attribute);
            }
        },

        methods: {
            removeAttribute() {
                this.$store.dispatch('attributeOptions/removeAttribute', {
                    index: this.index,
                    attribute: this.selectedAttribute
                });
            }
        }
    }
</script>
