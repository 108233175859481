<template>
    <div>
        <div>Created On: {{ quote.created_at }}</div>
        <div>Margin: {{ quote.margin }}</div>
        <div>Total: £{{ quote.total | toPounds }}</div>
    </div>
</template>

<script>
export default {
    props: {
        quote: {
            type: Object,
            required: true
        }
    }
}
</script>
