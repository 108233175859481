var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "price-field position-relative flex-1" }, [
    _c(
      "div",
      {
        staticClass:
          "position-absolute d-flex align-items-center inset-y-0 pl-2"
      },
      [_c("span", { domProps: { textContent: _vm._s(_vm.symbol) } })]
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        {
          name: "model",
          rawName: "v-model.number",
          value: _vm.inputValue,
          expression: "inputValue",
          modifiers: { number: true }
        }
      ],
      staticClass: "form-control flex-1 pl-4",
      attrs: { type: "number" },
      domProps: { value: _vm.inputValue },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.inputValue = _vm._n($event.target.value)
        },
        blur: function($event) {
          return _vm.$forceUpdate()
        }
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }