import Vue from 'vue';
import { normalize, schema } from 'normalizr';
import { find } from 'lodash';

const price = new schema.Entity('prices');

const sizeBreakpoint = new schema.Entity('size_breakpoints');
const attributeOption = new schema.Entity('attribute_options',{
    quote_prices: [price]
});

const attributeSchema = new schema.Entity('attributes',{
    options: [attributeOption],
    size_breakpoints: [sizeBreakpoint]
});

const state = {
    productSlug: '',
    attributes: {},
    attributeOptions: {},
    sizeBreakpoints: {},
    prices: {},
    dirty: {}
};

const getters = {
    attributeList: (state) => {
        return Object.values(state.attributes);
    },
    attributeOptions: (state) => (attributeId) => {
        return state.attributes[attributeId].options.map(optionId => state.attributeOptions[optionId]);
    },
    sizeBreakpoints: (state) => (attributeId) => {
        return state.attributes[attributeId].size_breakpoints.map(breakpointId => state.sizeBreakpoints[breakpointId]);
    },
    fetchPrice: (state) => (attributeOptionId, sizeBreakpointId) => {
        return find(Object.values(state.prices), price => {
            return (price.attribute_option_id === attributeOptionId && price.attribute_size_breakpoint_id === sizeBreakpointId);
        });
    }
};

const mutations = {
    setProductSlug(state, productSlug) {
        state.productSlug = productSlug;
    },
    setAttributes(state, attributes) {
        state.attributes = Object.assign({}, attributes);
    },
    setAttributeOptions(state, options) {
        state.attributeOptions = Object.assign({}, options);
    },
    setSizeBreakpoints(state, breakpoints) {
        state.sizeBreakpoints = Object.assign({}, breakpoints);
    },
    setPrices(state, prices) {
        state.prices = Object.assign({}, prices);
    },
    updatePrice(state, { attribute_option_id, size_breakpoint_id, price }) {
        let currentAttributePrices = state.dirty[attribute_option_id] || {};
        let currentPrices = { ...currentAttributePrices };
        Vue.set(currentPrices, size_breakpoint_id, price);
        Vue.set(state.dirty, attribute_option_id, currentPrices);
    },
};

const actions = {
    async loadPrices({ commit }, productSlug) {
        commit('setProductSlug', productSlug);

        let { data } = await axios.get(`/products/${productSlug}/attribute-quote-prices`);

        let { entities: { attributes, attribute_options, size_breakpoints, prices } } = normalize(data, [attributeSchema]);

        commit('setAttributes', attributes);
        commit('setAttributeOptions', attribute_options);
        commit('setSizeBreakpoints', size_breakpoints);
        commit('setPrices', prices);
    },
    save({ state, commit }) {
        return axios.post(`/products/${state.productSlug}/attribute-quote-prices`,{
            prices: state.dirty
        });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
