<template>
    <div>
        <div>
            <h3>Create Embroidery Badge Quote</h3>
        </div>
        <div>
            <ValidationObserver ref="form" v-slot="{ invalid }">
                <div class="row">
                    <div class="col-sm-3">
                        <ValidationProvider name="stitch count" v-slot="{ errors }" rules="required">
                            <div class="form-group">
                                <label class="control-label">Stitches</label>
                                <select v-model="stitchCount" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                                    <option
                                        v-for="stitchCount in stitchCountsList"
                                        :key="stitchCount.id"
                                        :value="stitchCount.id"
                                        v-text="stitchCount.value"
                                    ></option>
                                </select>
                                <span class="invalid-feedback" v-if="errors.length > 0">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                        <ValidationProvider name="trim count" v-slot="{ errors }" rules="required">
                            <div class="form-group">
                                <label class="control-label">Trims</label>
                                <input type="number" v-model.number="trimCount" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                                <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                        <ValidationProvider name="number of colours" v-slot="{ errors }" rules="required">
                            <div class="form-group">
                                <label class="control-label">Number of Colours</label>
                                <select v-model="numColoursId" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                                    <option
                                        v-for="colour in colourPriceList"
                                        :key="colour.id"
                                        :value="colour.id"
                                        v-text="colour.label"
                                    ></option>
                                </select>
                                <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                    <div class="col-sm-3">
                        <ValidationProvider name="margin" v-slot="{ errors }" rules="required">
                            <div class="form-group">
                                <label class="control-label">Margin</label>
                                <margin-field v-model="margin" :class="{ 'is-invalid': errors.length > 0 }"></margin-field>
                                <span class="invalid-feedback" v-if="errors.length">{{ errors[0] }}</span>
                            </div>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </div>
        <div class="my-3">
            <div class="quote-prices">
                <div class="row">
                    <div class="col-sm-6">
                        <div v-if="showItemised">
                            <div class="d-flex align-items-center mb-3">
                                <label class="quote-label mb-0 pr-5">Stitches</label>
                                <input type="number" class="form-control flex-1" :value="stitchPrice" readonly/>
                            </div>
                            <div class="d-flex align-items-center mb-3">
                                <label class="quote-label mb-0 pr-5">Trim Price</label>
                                <input type="number" class="form-control flex-1" :value="trimPrice" readonly/>
                            </div>

                            <quote-attributes></quote-attributes>

                            <div class="d-flex align-items-center mb-3">
                                <label class="quote-label mb-0 pr-5">Number of Colours</label>
                                <input type="number" class="form-control flex-1" :value="colourPrice" readonly/>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="d-flex align-items-center mb-3">
                            <label class="quote-label mb-0 pr-5">Setup</label>
                            <price-field v-model="setupCost"></price-field>
                        </div>
                        <div class="d-flex align-items-center mb-3">
                            <label class="quote-label mb-0 pr-5">Total</label>
                            <price-field v-model="total"></price-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <button class="btn btn-link" type="button" @click.prevent="cancel">Cancel</button>
            <button class="btn btn-primary" type="button" @click.prevent="saveQuote">Save</button>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        components: {
            ValidationObserver,
            ValidationProvider
        },

        data() {
            return {
                showItemised: false
            }
        },

        computed: {
            ...mapGetters('orders/quoting/embroideryBadge',[
                'stitchCountsList',
                'colourPriceList'
            ]),
            stitchCount: {
                get() {
                    return this.$store.state.orders.quoting.embroideryBadge.quote.stitch_count_breakpoint_id;
                },
                set(value) {
                    this.$store.dispatch('orders/quoting/embroideryBadge/setStitchCount', value);
                }
            },
            trimCount: {
                get() {
                    return this.$store.state.orders.quoting.embroideryBadge.quote.trim_count;
                },
                set(value) {
                    let quote = this.$store.state.orders.quoting.embroideryBadge.quote;
                    this.$store.commit('orders/quoting/embroideryBadge/setQuote', { ...quote, trim_count: value } );
                }
            },
            numColoursId: {
                get() {
                    return this.$store.state.orders.quoting.embroideryBadge.quote.num_colours_id;
                },
                set(value) {
                    let quote = this.$store.state.orders.quoting.embroideryBadge.quote;
                    this.$store.commit('orders/quoting/embroideryBadge/setQuote', { ...quote, num_colours_id: value } );
                }
            },
            margin: {
                get() {
                    return this.$store.state.orders.quoting.embroideryBadge.quote.margin;
                },
                set(value) {
                    let quote = this.$store.state.orders.quoting.embroideryBadge.quote;
                    this.$store.commit('orders/quoting/embroideryBadge/setQuote', { ...quote, margin: value } );
                }
            },
            setupCost: {
                get() {
                    return this.$store.state.orders.quoting.embroideryBadge.quote.setup_cost / 100;
                },
                set(value) {
                    let quote = this.$store.state.orders.quoting.embroideryBadge.quote;
                    this.$store.commit('orders/quoting/embroideryBadge/setQuote', { ...quote, setup_cost: (value * 100) } );
                }
            },
            stitchPrice() {
                return (this.$store.state.orders.quoting.embroideryBadge.stitchPrice / 100).toFixed(2);
            },
            trimPrice() {
                return (this.$store.getters['orders/quoting/embroideryBadge/trimPrice'] / 100).toFixed(2);
            },
            colourPrice() {
                return (this.$store.getters['orders/quoting/embroideryBadge/colourPrice'] / 100).toFixed(2);
            },
            total() {
                return (this.$store.getters['orders/quoting/embroideryBadge/total'] / 100);
            }
        },

        methods: {
            cancel() {
                this.$emit('cancel');
            },
            saveQuote() {
                this.$store.dispatch('orders/quoting/embroideryBadge/saveQuote').then(() => {
                    this.$emit('saved');
                });
            }
        }
    }
</script>
