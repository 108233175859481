import Vue from 'vue';
import {normalize} from "normalizr";
import {isEmpty} from 'lodash';
import currency from 'currency.js';
import attributeSizeBreakpointSchema from "../../schema/attribute-size-prices";

const state = {
    optionId: 0,
    sizeBreakpoints: {},
    fixedPrices: {},
    quotePrices: {},
    fixedChanges: {},
    quoteChanges: {}
};

const getters = {
    sizes: (state) => {
        return Object.values(state.sizeBreakpoints);
    },
    getFixedPrice: (state) => (priceId) => {
        return state.fixedPrices[priceId];
    },
    getFixedChange: (state) => (priceId) => {
        return state.fixedChanges[priceId];
    },
    getQuotePrice: (state) => (priceId) => {
        return state.quotePrices[priceId];
    },
    getQuoteChange: (state) => (priceId) => {
        return state.quoteChanges[priceId];
    },
    hasChanges: (state) => {
        return !isEmpty(state.fixedChanges) || !isEmpty(state.quoteChanges);
    }
};

const mutations = {
    setOptionId(state, optionId) {
        state.optionId = optionId;
    },
    setSizeBreakpoints(state, sizeBreakpoints) {
        state.sizeBreakpoints = Object.assign({}, sizeBreakpoints);
    },
    setFixedPrices(state, fixedPrices) {
        state.fixedPrices = Object.assign({}, fixedPrices);
    },
    setQuotePrices(state, quotePrices) {
        state.quotePrices = Object.assign({}, quotePrices);
    },
    addFixedChange(state, { id, ...data }) {
        Vue.set(state.fixedChanges, id, { id, ...data });
    },
    addQuoteChange(state, { id, ...data }) {
        Vue.set(state.quoteChanges, id, { id, ...data });
    },
    cancelChanges(state) {
        state.fixedChanges = Object.assign({});
        state.quoteChanges = Object.assign({});
    }
};

const actions = {
    loadPrices({ commit }, optionId) {
        commit('setOptionId', optionId);

        return axios.get(route('attribute-options.prices.index', optionId))
            .then(({ data }) => {
                let { entities: { sizeBreakpoints, fixedPrice, quotePrice }} = normalize(data.data, [attributeSizeBreakpointSchema]);

                commit('setSizeBreakpoints', sizeBreakpoints);
                commit('setFixedPrices', fixedPrice);
                commit('setQuotePrices', quotePrice);
            })
    },
    saveChanges({ dispatch, commit }) {
        return Promise.all([
            dispatch('saveFixedPrices'),
            dispatch('saveQuotePrices')
        ])
    },
    saveFixedPrices({ commit, state }) {
        let prices = Object.values(state.fixedChanges).map(({ type, ...data}) => {
           let price = { percentage_price: 0, fixed_price: 0, ...data };
           price.fixed_price = currency(price.fixed_price).intValue;
           return price;
        });

    },
    saveQuotePrices({ commit }) {
        let prices = Object.values(state.quoteChanges).map(({ id, price }) => {
            return { ...state.quotePrices[id], price };
        });

        console.log(prices);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
