var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.files, function(label, name) {
      return _c("ValidationProvider", {
        key: name,
        attrs: { name: label, rules: "required" },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var errors = ref.errors
                return [
                  _c(
                    "div",
                    { staticClass: "mb-3" },
                    [
                      _c("label", [_vm._v(_vm._s(label))]),
                      _vm._v(" "),
                      _c("file-upload", {
                        class: { "is-invalid": errors.length > 0 },
                        attrs: { value: _vm.currentFile(name), type: "image" },
                        on: {
                          input: function(value) {
                            return _vm.selectFile(value, name)
                          }
                        }
                      }),
                      _vm._v(" "),
                      errors
                        ? _c("div", { staticClass: "invalid-feedback" }, [
                            _vm._v(_vm._s(errors[0]))
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ]
              }
            }
          ],
          null,
          true
        )
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }