<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit)">

            <h3 class="mb-3" v-text="modalTitle"></h3>

            <ValidationProvider name="Label" v-slot="{ errors }" rules="required">
                <div class="form-group">
                    <label>Label</label>
                    <input type="text" v-model="label" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <ValidationProvider name="Quantity" v-slot="{ errors }" rules="required">
                <div class="form-group">
                    <label>Quantity</label>
                    <input type="number" v-model.number="quantity" class="form-control" :class="{ 'is-invalid': errors.length > 0 }">
                    <span class="invalid-feedback">{{ errors[0] }}</span>
                </div>
            </ValidationProvider>

            <div class="d-flex justify-content-between">
                <button class="btn btn-primary" type="submit">Save</button>
                <button v-if="editing" class="btn btn-danger" type="button" @click="deleteQuantity">Delete</button>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate';

    export default {
        name: 'QuantityBreakpointForm',

        components: {
            ValidationObserver,
            ValidationProvider
        },

        props: {
            breakpoint: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        data() {
            return {
                label: this.breakpoint.label,
                quantity: this.breakpoint.quantity
            }
        },

        computed: {
            editing() {
                return !! this.breakpoint.id;
            },
            modalTitle() {
                return this.editing ? 'Edit Quantity Breakpoint' : 'Add Quantity Breakpoint';
            }
        },

        methods: {
            async onSubmit() {
                let data = await this.$store.dispatch('product/fixedPrices/saveQuantity', {
                    id: this.breakpoint.id,
                    label: this.label,
                    quantity: this.quantity
                });

                this.$emit('saved');
            },
            async deleteQuantity() {
                let response = await this.$store.dispatch('product/fixedPrices/deleteQuantity', this.breakpoint.id);

                this.$emit('deleted');
            }
        }
    }
</script>
