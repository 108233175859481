var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h3", [_vm._v("Order Summary")]),
      _vm._v(" "),
      _c("div", { staticClass: "summary-product-name" }, [
        _vm._v(_vm._s(_vm.productName))
      ]),
      _vm._v(" "),
      _vm.optionsLoaded
        ? [
            _c(
              "div",
              { staticClass: "summary-section" },
              [
                _c("div", { staticClass: "summary-section-title" }, [
                  _vm._v("Customise Options")
                ]),
                _vm._v(" "),
                !!_vm.dimensions
                  ? _c("div", { staticClass: "summary-item" }, [
                      _c("div", { staticClass: "summary-item-label" }, [
                        _vm._v("Size")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "summary-item-options" }, [
                        _c("div", { staticClass: "summary-value-label" }, [
                          _vm._v(_vm._s(_vm.dimensions))
                        ])
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.customiseOptions, function(options, attributeId) {
                  return [
                    _c("selected-options-summary", {
                      key: attributeId,
                      attrs: { "attribute-id": attributeId, options: options }
                    })
                  ]
                }),
                _vm._v(" "),
                _c("div", { staticClass: "summary-item" }, [
                  _c("div", { staticClass: "summary-item-label" }, [
                    _vm._v("Quantity")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "summary-item-options" }, [
                    _c("div", { staticClass: "summary-value-label" }, [
                      _vm._v(_vm._s(_vm.quantity))
                    ])
                  ])
                ])
              ],
              2
            ),
            _vm._v(" "),
            _vm.hasSpecialOptions
              ? _c(
                  "div",
                  { staticClass: "summary-section" },
                  [
                    _c("div", { staticClass: "summary-section-title" }, [
                      _vm._v("Special Options")
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.specialOptions, function(options, attributeId) {
                      return [
                        _c("selected-options-summary", {
                          key: attributeId,
                          attrs: {
                            "attribute-id": attributeId,
                            options: options
                          }
                        })
                      ]
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasSpecialOptions
              ? _c("div", { staticClass: "summary-section" }, [
                  _c("div", { staticClass: "summary-section-title" }, [
                    _vm._v("Artwork")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "summary-item" }, [
                    _c("div", { staticClass: "summary-item-label" }, [
                      _vm._v("Design Name")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary-item-options" }, [
                      _c("div", { staticClass: "summary-value-label" }, [
                        _vm._v(_vm._s(_vm.designName))
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "summary-artwork" },
                    [
                      _c("div", { staticClass: "summary-item-label" }, [
                        _vm._v("Arwork")
                      ]),
                      _vm._v(" "),
                      _c("image-preview", { attrs: { file: _vm.artwork } })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.deliveryId
              ? _c("div", { staticClass: "summary-section" }, [
                  _c("div", { staticClass: "summary-section-title" }, [
                    _vm._v("Turnaround")
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "summary-item" }, [
                    _c("div", { staticClass: "summary-item-label" }, [
                      _vm._v("Delivery")
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "summary-item-options" }, [
                      _c("div", { staticClass: "summary-value-label" }, [
                        _vm._v(_vm._s(_vm.deliveryName))
                      ])
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.vatable
              ? _c("div", { staticClass: "summary-item" }, [
                  _c("div", { staticClass: "summary-item-label" }, [
                    _vm._v("Vat " + _vm._s(_vm.vatRate) + "%")
                  ]),
                  _vm._v(" "),
                  _vm._m(0)
                ])
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "summary-item-options" }, [
      _c("div", { staticClass: "summary-value-label" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }