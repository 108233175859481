<template>
    <div>
        <button
            type="button"
            @click="submit"
            class="btn btn-success"
            :disabled="disabled"
        >Select Quote & Proceed</button>
        <modal v-if="latestQuote" :show="show" width="md" @close="show = false">
            <h3 class="mb-4">Are you sure you want to continue?</h3>
            <div class="mb-4">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <div>Current Quote</div>
                        <div>£{{ latestQuote.total | toPounds }}</div>
                    </div>
                    <div class="col-sm-6">
                        <div>New Quote</div>
                        <div>£{{ formattedNewTotal }}</div>
                    </div>
                </div>
                <div v-if="moreExpensive">
                    The new quote is more expensive than the existing quote. If you are happy to proceed then select continue, otherwise
                    you may need to cancel this order.
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <button type="button" @click.prevent="show = false" class="btn btn-primary">Cancel</button>
                <button type="button" @click.prevent="$emit('submitted')" class="btn btn-success">Continue</button>
            </div>
        </modal>
    </div>
</template>

<script>
export default {
    props: {
        latestQuote: false,
        disabled: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            show: false
        }
    },

    computed: {
        newQuoteTotal() {
            return this.$store.getters['orders/quoting/customerTotal'];
        },
        formattedNewTotal() {
            return this.newQuoteTotal.format();
        },
        moreExpensive() {
            return this.newQuoteTotal.intValue > this.latestQuote.total;
        }
    },

    methods: {
        submit() {
            if (this.latestQuote) {
                return this.show = true;
            }

            this.$emit('submitted');
        }
    }
}
</script>
