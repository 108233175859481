import {find, indexOf} from "lodash";

/**
 * Find attributes with no parent
 *
 * @param attributes
 * @param attributeId
 * @returns {boolean}
 */
export default function noAttributeParent(attributes, attributeId) {
    let parent = find(attributes, attribute => {
        return (typeof attribute.children !== 'undefined' && indexOf(attribute.children, attributeId) > -1);
    });
    return typeof parent === 'undefined';
}
