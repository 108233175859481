import VatLookup from "./VatLookup";

export default class VatChecker {
    constructor(secure = false) {
        this.checks = {};
    }

    async vatable(vatNumber) {
        let isValid = false;
        let country = '';

        if (!vatNumber) return true;

        if (vatNumber in this.checks) {
            isValid = this.checks[vatNumber].valid;
            country = this.checks[vatNumber].country_code;
        } else {
            let lookup = new VatLookup();
            let { valid, country_code } = await lookup.check(vatNumber);

            isValid = valid;
            country = country_code;
            this.checks[vatNumber] = {
                valid,
                country_code
            }
        }

        if (isValid && country !== 'GB') {
            return false;
        }
        return true;
    }
}
