<template>
    <div class="permissions-selection-list checkbox-selection-list">
        <template v-for="permission in permissions">
            <div class="column" :key="permission.id">
                <checkbox-field :name="`permissions[]`" :value="permission.id" :is-selected="isSelected(permission)" @input="(selected) => updateSelected(permission, selected)">
                    {{ permission.name }}
                </checkbox-field>
            </div>
        </template>
        <slot></slot>
    </div>
</template>

<script>
export default {
    props: ['permissions', 'defaultPermissions'],

    data() {
        return {
            selectedPermissions: this.defaultPermissions,
        };
    },

    methods: {
        /**
         * Adds a specified permission to the selection.
         */
        add(permission) {
            if(!this.isSelected(permission)) {
                this.selectedPermissions.push(permission);
            }
        },

        /**
         * Removes the specified permission from the selection.
         */
        remove(permission) {
            let index = _.findIndex(this.selectedPermissions, (selected) => {
                return selected.id === permission.id;
            });

            if(index !== -1) {
                this.selectedPermissions.splice(index, 1);
            }
        },

        updateSelected(permission, selected) {
            if (selected) {
                this.add(permission)
            } else {
                this.remove(permission)
            }
        },

        /**
         * Determine if a permission is selected.
         */
        isSelected(permission) {
            if (this.permissions.length) {
                let index = _.findIndex(this.selectedPermissions, (selected) => {
                    return selected.id === permission.id;
                });

                return index !== -1;
            }

            return false;
        }
    },

    watch: {
        selectedPermissions: {
            immediate: true,
            handler(val) {
                this.$emit('input', val)
            }

        }
    }
}
</script>
