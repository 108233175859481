var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c("button", {
        staticClass: "btn btn-primary",
        attrs: { type: "button" },
        domProps: { textContent: _vm._s(_vm.label) },
        on: { click: _vm.showForm }
      }),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { show: _vm.showModal },
          on: {
            close: function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("ValidationObserver", {
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var handleSubmit = ref.handleSubmit
                  return [
                    _c(
                      "form",
                      {
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.save)
                          }
                        }
                      },
                      [
                        _c("ValidationProvider", {
                          attrs: { name: "value", rules: "required" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function(ref) {
                                  var errors = ref.errors
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "form-group",
                                        class: {
                                          "has-error": errors.length > 0
                                        }
                                      },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "control-label" },
                                          [_vm._v("Value")]
                                        ),
                                        _vm._v(" "),
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.value,
                                              expression: "value"
                                            }
                                          ],
                                          staticClass: "form-control",
                                          attrs: { type: "text" },
                                          domProps: { value: _vm.value },
                                          on: {
                                            input: function($event) {
                                              if ($event.target.composing) {
                                                return
                                              }
                                              _vm.value = $event.target.value
                                            }
                                          }
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "help-block" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-group" }, [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-primary",
                              attrs: { type: "submit" }
                            },
                            [_vm._v("Save")]
                          )
                        ])
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }